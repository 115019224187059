import { FC } from 'react';
import { Box, Container, Divider, Skeleton, Typography } from '@mui/material';
import { ITraveller } from '../../../type';
import { useTheme } from '../../../config/theme/useTheme';

interface TravellerDetailsProps {
  traveller: ITraveller[];
  isLoading: boolean;
}
const TravellerDetails: FC<TravellerDetailsProps> = ({ traveller, isLoading }) => {
  const theme = useTheme();
  return (
    <>
      <Container sx={theme.components.pages.bookingStatus.travellerDetails.container}>
        <Typography variant="body1">Traveller</Typography>
        {isLoading ? (
          <>
            <Skeleton variant="text" sx={theme.components.pages.bookingStatus.travellerDetails.skeleton.item} />
            <Skeleton variant="text" sx={theme.components.pages.bookingStatus.travellerDetails.skeleton.item} />
            <Skeleton variant="text" sx={theme.components.pages.bookingStatus.travellerDetails.skeleton.item} />
          </>
        ) : (
          traveller?.map((t: ITraveller) => (
            <Box key={t.id} sx={theme.components.pages.bookingStatus.travellerDetails.subContainer}>
              <Typography key={t.id} variant="body2" py="8px">
                {t?.fullName}, {t?.age}
              </Typography>
              {t?.studentId && (
                <Typography variant="body3" color="#757575">
                  Student ID: {t?.studentId}
                </Typography>
              )}
            </Box>
          ))
        )}
      </Container>
      <Divider sx={theme.components.pages.bookingStatus.travellerDetails.divider} />
    </>
  );
};

export default TravellerDetails;
