import { Box, SxProps } from "@mui/material";

const FlexItems = ({ 
  children, 
  sx, 
  onClick, 
  id 
}: { 
  children: React.ReactNode, 
  sx?: SxProps, 
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void,
  id?: string
}) => {
  return (
    <Box display="flex" alignItems="center" sx={sx} onClick={onClick} id={id}>
      {children}
    </Box>
  )
    
};

export default FlexItems;
