import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Box, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { Provider } from 'react-redux';
import { store, persistor } from './store';
import AppRoutes from './routes';
import { theme } from './config';
import './assets/scss/style.scss';
import { PersistGate } from 'redux-persist/integration/react';
import { OASnackbar } from './components/basic/OASnackbar';
import { cleanupWindowFunctions } from './windowFunctions';
import { OALoading } from './components';
import NetworkDetector from './NetworkDetector';
import VisibilityObserver from './VisibilityObserver';
import ErrorBoundary from './components/ErrorBoundary';
import { ThemeProvider } from './config/theme/ThemeProvider';

// Hooks
import { useInitialization } from './hooks/useInitialization';
import { useContextMenu } from './hooks/useContextMenu';
import { useVConsole } from './hooks/useVConsole';

interface InitializationWrapperProps {
  children: React.ReactNode;
}

const InitializationWrapper: React.FC<InitializationWrapperProps> = ({ children }) => {
  const isInitialized = useInitialization();

  if (!isInitialized) {
    return (
      <Box sx={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <OALoading />
      </Box>
    );
  }

  return <>{children}</>;
};

const App: React.FC = () => {
  // Initialize vConsole for debugging (only in development)
  useVConsole(process.env.NODE_ENV === 'development');
  
  // Handle context menu (right-click) prevention
  useContextMenu();

  // Cleanup window functions on unmount
  useEffect(() => {
    return () => {
      cleanupWindowFunctions();
    };
  }, []);

  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider initialTheme="niyo">
              <Router>
                <Box sx={{ 
                  minHeight: '100vh',
                  WebkitOverflowScrolling: 'touch',
                  '& *': {
                    msOverflowStyle: 'none',
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                      display: 'none'
                    }
                  }
                }}>
                  <NetworkDetector>
                    <VisibilityObserver>
                      <InitializationWrapper>
                        <Suspense fallback={<OALoading />}>
                          <AppRoutes />
                          <OASnackbar />
                        </Suspense>
                      </InitializationWrapper>
                    </VisibilityObserver>
                  </NetworkDetector>
                </Box>
              </Router>
            </ThemeProvider>
          </MuiThemeProvider>
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
