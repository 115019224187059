import { Box, Card, CircularProgress, Typography } from '@mui/material';
import { OAButton, OAImage } from '../../components';
import { formatToINR } from '../../utils';
import FlexItemsBetween from '../../components/FlexItemsBetween';
import { useTheme } from '../../config/theme/useTheme';
import FlexItems from '../../components/FlexItems';

const CfarSection = ({
  cfarRefundAmount,
  cfarInsuranceData,
  isCfarAdded,
  handleUpdateAddOnServices,
  isLoadingAddOn,
  handleTandCClick,
  disabled,
}: any) => {
  const theme = useTheme();
  return (
    <Box sx={theme.components.pages.itinerary.cfar.container}>
      <Box sx={theme.components.pages.itinerary.cfar.assetContainer}>
        <OAImage
          src={theme.assets.images.icons.wave.path}
          folder={theme.assets.images.icons.wave.folder}
          alt="wave"
          width="100%"
        />
      </Box>{' '}
      <Box sx={theme.components.pages.itinerary.cfar.subContainer}>
        <FlexItemsBetween>
          <Box sx={theme.components.pages.itinerary.cfar.contentSubContainer}>
            <Typography color="#13387E" fontWeight={400}>
              No Questions Asked:{' '}
              <Box component="span" fontWeight={600}>
                Upto {formatToINR(cfarRefundAmount)}
              </Box>{' '}
              back instantly on cancellation
            </Typography>
          </Box>
          <Box>
            <OAImage
              src={theme.assets.images.icons.tataAigInsuranceSecondary.path}
              alt="insur"
              sx={{ width: '100px' }}
            />
          </Box>
        </FlexItemsBetween>
        <Box mt="10px">
          <Box position="relative">
            <Card sx={theme.components.pages.itinerary.cfar.cardContainer}>
              <Typography variant="h2">
                {formatToINR(
                  cfarInsuranceData?.totalAmount ??
                    cfarInsuranceData?.additionalInfo?.perTravellerInsurancePricing
                )}
                <Typography variant="body3" fontWeight={600}>
                  {' '}
                  / traveller
                </Typography>
              </Typography>
              <OAButton
                onClick={handleUpdateAddOnServices}
                size="small"
                disabled={isLoadingAddOn || disabled}
                sx={{
                  color: isCfarAdded?.totalAmount ? '#14191F' : '#fff',
                  backgroundColor: isLoadingAddOn
                    ? '#E0E0E0'
                    : isCfarAdded?.totalAmount
                      ? '#FFE5E2'
                      : '#0A1017',
                  '&&:hover': {
                    backgroundColor: isLoadingAddOn
                      ? '#E0E0E0'
                      : isCfarAdded?.totalAmount
                        ? '#FFE5E2'
                        : '#0A1017',
                  },
                  '&&:focus': {
                    backgroundColor: isLoadingAddOn
                      ? '#E0E0E0'
                      : isCfarAdded?.totalAmount
                        ? '#FFE5E2'
                        : '#0A1017',
                  },
                  display: 'flex',
                  width: 'fit-content',
                  minWidth: '75px',
                }}
              >
                {
                  <FlexItems>
                    {isLoadingAddOn ? (
                      <CircularProgress size={20} sx={{ color: '#14191F' }} />
                    ) : (
                      <>
                        {!isCfarAdded?.totalAmount && !disabled && (
                          <OAImage
                            src={theme.assets.images.icons.addLight.path}
                            folder={theme.assets.images.icons.addLight.folder}
                            alt="add"
                          />
                        )}
                        <Typography
                          variant="body3"
                          sx={{
                            color: isCfarAdded?.totalAmount ? '#14191F' : '#fff',
                            ml: !disabled && !isCfarAdded?.totalAmount ? '5px' : '0',
                          }}
                        >
                          {disabled ? 'Unavailable' : isCfarAdded?.totalAmount ? 'Remove' : 'Add'}
                        </Typography>
                      </>
                    )}
                  </FlexItems>
                }
              </OAButton>
            </Card>
            <Box mt="10px">
              <Typography variant="body3" color="text.secondary" mt="10px">
                Only travellers between 6 months and 70 years are eligible for insurance.{' '}
                <Typography
                  variant="body3"
                  color="green"
                  onClick={handleTandCClick}
                  sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                >
                  T&C
                </Typography>{' '}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={theme.components.pages.itinerary.cfar.bottomAsset}>
        <OAImage
          src={theme.assets.images.icons.bottomWave.path}
          folder={theme.assets.images.icons.bottomWave.folder}
          alt="wave"
          width="100%"
        />
      </Box>{' '}
    </Box>
  );
};

export default CfarSection;
