import { Box, Typography } from '@mui/material';
import FlexItems from '../../components/FlexItems';
import { useTheme } from '../../config/theme/useTheme';
const iso3311a2 = require('iso-3166-1-alpha-2');

const AirportSuggestions = ({ index, handleSuggestionClick, suggestion }: any) => {
  const theme = useTheme();
  return (
    <FlexItems
      key={index}
      sx={theme.components.airportSuggestions.styles.container}
      onClick={() => handleSuggestionClick(suggestion)}
    >
      <Typography
        variant="body3"
        sx={theme.components.airportSuggestions.styles.airportIata}
      >
        {suggestion?.iata}
      </Typography>
      <Box>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {suggestion?.city}, {iso3311a2?.getCountry(suggestion?.country)}
        </Typography>
        <Typography
          variant="body3"
          sx={theme.components.airportSuggestions.styles.airportName}
        >
          {suggestion?.name}
        </Typography>
      </Box>
    </FlexItems>
  );
};

export default AirportSuggestions;
