import { Container, Typography } from '@mui/material';
import { OAButton } from '../../components';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from '../../store';
import { getUserInputState } from '../../store/slices/userInputSlice';
import { setError } from '../../store/slices/flightOrderSlice';
import { usePostHog } from 'posthog-js/react';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { useTheme } from '../../config/theme/useTheme';

const SomethingWentWrong = () => {
  const theme = useTheme();
  const posthog = usePostHog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { travelDetail } = useSelector(getUserInputState);
  const { travellerCount = {}, tripEnd, cabinType, fareGroup }: any = travelDetail;
  const { adult = 0, child = 0, infant = 0 } = travellerCount;

  const tripType = tripEnd?.date ? 'R' : 'O';
  const traveller = `adults=${adult}&childs=${child}&infants=${infant}`;

  const fromTo =
    `${travelDetail?.from?.iata}` +
    `&fromCity=${travelDetail?.from?.city}` +
    `&to=${travelDetail?.to?.iata}` +
    `&toCity=${travelDetail?.to?.city}` +
    `&fromDate=${travelDetail?.tripStart?.date}` +
    `${travelDetail?.tripEnd?.date ? `&toDate=${travelDetail?.tripEnd?.date}` : ''}`;

  const handleNavigate = () => {
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Alert',
        ctaAction: 'SomethingWrong',
        screenDuration: '1',
        UserAction: {
          userAction: 'FlightUnavailable',
        },
      },
      posthog
    );
    dispatch(setError(null));
    let redirectUrl = `/results?tripType=${tripType}&cabin=${cabinType}&${traveller}&from=${fromTo}`;
    if (fareGroup) {
      redirectUrl += `&fareGroup=${fareGroup}`;
    }
    navigate(redirectUrl);
  };

  return (
    <Container sx={theme.components.somethingWentWrong.container}>
      <>
        <Typography variant="h6" mb="10px">
          {theme.strings.somethingWentWrong.text}
        </Typography>
        <Typography>{theme.strings.somethingWentWrong.subText}</Typography>
        <OAButton
          variant="contained"
          color="secondary"
          fullWidth
          sx={{ mt: '24px' }}
          onClick={handleNavigate}
        >
          {theme.strings.somethingWentWrong.ctaText}
        </OAButton>
      </>
    </Container>
  );
};

export default SomethingWentWrong;
