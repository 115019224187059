import { createApi } from '@reduxjs/toolkit/query/react';
import { TRAVELLERS, TRAVELLERS_IN_FLIGHT_ORDER } from '../store/apiUrls';
import { baseApi } from './baseApi';

export const travellersApi = createApi({
  reducerPath: 'travellersApi',
  baseQuery: baseApi,
  endpoints: (builder) => ({
    getTravellers: builder.query({
      query: () => ({
        url: TRAVELLERS,
        method: 'GET',
      }),
    }),
    createTraveller: builder.mutation({
      query: (request) => ({
        url: TRAVELLERS,
        method: 'POST',
        body: request,
      }),
    }),
    updateTraveller: builder.mutation({
      query: (request) => ({
        url: `${TRAVELLERS}/${request.id}`,
        method: 'PUT',
        body: request,
      }),
    }),
    updateTravellersInFlightOrder: builder.mutation({
      query: ({ orderId, ...rest }) => ({
        url: `${TRAVELLERS_IN_FLIGHT_ORDER}/${orderId}`,
        method: 'PUT',
        body: rest,
      }),
    }),
  }),
});

export const {
  useUpdateTravellersInFlightOrderMutation,
  useCreateTravellerMutation,
  useGetTravellersQuery,
  useUpdateTravellerMutation,
} = travellersApi;
