import { FC } from 'react';
import { Box, Card, Container, Skeleton, Typography } from '@mui/material';
import { ISegment } from '../../../type';
import PnrDisplay from '../../../components/OAFlightSummary/PnrDisplay';
import OAFlightRoute from '../../../components/OAFlightRoute';
import { OAFlightSummary } from '../../../components';
import { useNearbyAirportMessage } from '../../../hooks/useNearbyAirportMessage';
import { useTheme } from '../../../config/theme/useTheme';
import FlexItemsBetween from '../../../components/FlexItemsBetween';

interface TripDetailsSegmentsProps {
  order: any;
  isLoading: boolean;
}

const TripDetailsSegments: FC<TripDetailsSegmentsProps> = ({ order, isLoading }) => {
  const theme = useTheme();
  const nearbyAirportMessage = useNearbyAirportMessage(order);

  const onWardFlights = order?.flights?.find((flight: any) => flight?.departureType === 'ONWARD');
  const returnFlights = order?.flights?.find((flight: any) => flight?.departureType === 'RETURN');

  const onwardPnrNumber = onWardFlights?.pnrNumber;
  const returnPnrNumber = returnFlights?.pnrNumber;

  const onwardFromAndTo =
    onWardFlights?.segments?.length > 0
      ? {
          from: onWardFlights?.segments?.[0]?.departure?.airport?.city,
          to: onWardFlights?.segments?.[onWardFlights?.segments?.length - 1]?.arrival?.airport
            ?.city,
        }
      : null;

  const returnFromAndTo =
    returnFlights?.segments?.length > 0
      ? {
          from: returnFlights?.segments?.[0]?.departure?.airport?.city,
          to: returnFlights?.segments?.[returnFlights?.segments?.length - 1]?.arrival?.airport
            ?.city,
        }
      : null;

  return (
    <Box sx={theme.components.pages.bookingStatus.tripDetailsSegments.container}>
      <Container sx={theme.components.pages.bookingStatus.tripDetailsSegments.subContainer}>
        <FlexItemsBetween
        sx={theme.components.pages.bookingStatus.tripDetailsSegments.refundStatusBar.container}
        >
          <OAFlightRoute
            from={onwardFromAndTo?.from}
            to={onwardFromAndTo?.to}
            icon="flight-take-off.svg"
          />
          <Box>
            {order?.flights?.[0]?.minirule ? (
              <Typography
                variant="body3"
                sx={{ ...theme.components.pages.bookingStatus.tripDetailsSegments.refundStatusBar.text,
                  ...(order?.flights?.[0]?.minirule?.refundable && theme.components.pages.bookingStatus.tripDetailsSegments.refundStatusBar.refundText)
                 }}
              >
                {order?.flights?.[0]?.minirule?.refundable ? 'Part-Refundable' : 'Non-refundable'}
              </Typography>
            ) : (
              <Skeleton width="100px" />
            )}
          </Box>
        </FlexItemsBetween>
        {isLoading ? (
          <Skeleton variant="rectangular" sx={theme.components.pages.bookingStatus.tripDetailsSegments.pnr.skeleton} />
        ) : onwardPnrNumber ? (
          <PnrDisplay pnrDetails={onwardPnrNumber} />
        ) : order?.bookingStatus === 'BOOKING_IN_PROGRESS' ? (
          <Card>
            <Typography
              component="p"
              variant="body3"
              sx={{ color: 'text.secondary', py: '12px', textAlign: 'center' }}
            >
              {theme.strings.bookingStatus.confirmedText.text}
            </Typography>
          </Card>
        ) : null}
      </Container>
      {onWardFlights?.segments?.map((segment: ISegment, index: number) => (
        <OAFlightSummary
          key={segment.segmentId}
          selectedFlight={order}
          layovers={onWardFlights?.layovers}
          data={segment}
          nearbyAirportNudge={
            nearbyAirportMessage?.onward?.find((nudge: any) => nudge?.index === index)?.message
          }
          isBookingStatusPage
          bookingStatus={order?.orderCurrentStatus}
        />
      ))}
      {returnFlights?.segments?.length > 0 && (
        <>
          <FlexItemsBetween
           sx={theme.components.pages.bookingStatus.tripDetailsSegments.returnSegment.container}
          >
            <OAFlightRoute
              from={returnFromAndTo?.from}
              to={returnFromAndTo?.to}
              icon="flight-land.svg"
            />
            <Box>
              {order?.flights?.[1]?.minirule ? (
                <Typography
                  variant="body3"
                  sx={{ ...theme.components.pages.bookingStatus.tripDetailsSegments.refundStatusBar.text,
                    ...(order?.flights?.[1]?.minirule?.refundable && theme.components.pages.bookingStatus.tripDetailsSegments.refundStatusBar.refundText)
                   }}
                >
                  {order?.flights?.[1]?.minirule?.refundable ? 'Part-Refundable' : 'Non-refundable'}
                </Typography>
              ) : (
                <Skeleton width="100px" />
              )}
            </Box>
          </FlexItemsBetween>
          <Container sx={theme.components.pages.bookingStatus.tripDetailsSegments.subContainer}>
            {isLoading ? (
              <Skeleton variant="rectangular" sx={theme.components.pages.bookingStatus.tripDetailsSegments.pnr.skeleton} />
            ) : returnPnrNumber ? (
              <PnrDisplay pnrDetails={returnPnrNumber} />
            ) : order?.bookingStatus === 'BOOKING_IN_PROGRESS' ? (
              <Card>
                <Typography
                  component="p"
                  variant="body3"
                  sx={{ color: 'text.secondary', py: '12px', textAlign: 'center' }}
                >
                  {theme.strings.bookingStatus.confirmedText.text}
                </Typography>
              </Card>
            ) : null}
          </Container>
        </>
      )}
      {returnFlights?.segments?.map((segment: ISegment, index: number) => (
        <OAFlightSummary
          key={segment.segmentId}
          selectedFlight={order}
          layovers={returnFlights?.layovers}
          data={segment}
          nearbyAirportNudge={
            nearbyAirportMessage?.return?.find((nudge: any) => nudge?.index === index)?.message
          }
          isBookingStatusPage
          bookingStatus={order?.orderCurrentStatus}
        />
      ))}
    </Box>
  );
};

export default TripDetailsSegments;
