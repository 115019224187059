import { FC, useCallback, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { IFlight } from '../../../type';
import { formatToINR, getBurnCoin, getDiscountPrice, getEarnCoin } from '../../../utils';
import { getUserInputState } from '../../../store/slices/userInputSlice';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../../store/slices/userInfoSlice';
import { maxBy, minBy } from 'lodash';
import { getFeatureFlag } from '../../../store/slices/featuresSlice';
import OACoinBannerSecondary from '../../../components/OACoinBannerSecondary';
import { OAImage } from '../../../components';
import ConvenienceBanner from '../../../components/OAConvenienceBanner';
import FlexItemsBetween from '../../../components/FlexItemsBetween';
import { useTheme } from '../../../config/theme/useTheme';
import FlexItems from '../../../components/FlexItems';

interface FlightDetailProps {
  data: IFlight;
  tripType: 'R' | 'O';
  isInternational?: boolean;
  airportReviewData: any;
  nearbyAirports: any;
}

const FlightDetail: FC<FlightDetailProps> = ({
  data,
  tripType,
  isInternational,
  airportReviewData,
  nearbyAirports,
}) => {
  const { isCoinsApplied, travelDetail } = useSelector(getUserInputState);
  const { coins } = useSelector(getUserInfo);
  const { burnCoinsFeat } = useSelector(getFeatureFlag);
  const theme = useTheme();
  const tc = travelDetail?.travellerCount;
  const totalTraveller = (tc?.adult ?? 0) + (tc?.child ?? 0) + (tc?.infant ?? 0);

  const onwardSegments = data?.segments.filter((segment) => segment.departureType === 'ONWARD');
  const returnSegments = data?.segments.filter((segment) => segment.departureType === 'RETURN');

  const minOnwardObj = minBy(onwardSegments, 'segmentId');
  const maxOnwardObj = maxBy(onwardSegments, 'segmentId');
  const onwardDepartureTime = minOnwardObj?.departure?.time;
  const onwardDeparturePlace = minOnwardObj?.departure?.airport?.iata;
  const onwardArrivalTime = maxOnwardObj?.arrival?.time;
  const onwardArrivalPlace = maxOnwardObj?.arrival?.airport?.iata;

  const minReturnObj = minBy(returnSegments, 'segmentId');
  const maxReturnObj = maxBy(returnSegments, 'segmentId');
  const returnDepartureTime = minReturnObj?.departure?.time;
  const returnDeparturePlace = minReturnObj?.departure?.airport?.iata;
  const returnArrivalTime = maxReturnObj?.arrival?.time;
  const returnArrivalPlace = maxReturnObj?.arrival?.airport?.iata;

  const minObj = minBy(data?.segments, 'segmentId');
  const maxObj = maxBy(data?.segments, 'segmentId');
  const departureTime = minObj?.departure?.time;
  const departurePlace = minObj?.departure?.airport?.iata;
  const arrivalTime = maxObj?.arrival?.time;
  const arrivalPlace = maxObj?.arrival?.airport?.iata;
  const discountPrice = getDiscountPrice(data?.price?.value, coins);
  const internationalReturn = tripType === 'R' && isInternational;
  const domesticReturn = tripType === 'R' && !isInternational;

  const savedMoney = data?.price?.value - discountPrice;
  const earnCoin = getEarnCoin(data?.price?.value ?? 0);
  const coinsToBeBurned = getBurnCoin(data?.price?.value, coins);
  const isDiscountedPrice = discountPrice < data?.price?.value;
  const perPaxSave = savedMoney / totalTraveller;

  const earnCoinLabel = (
    <Box display="flex" alignItems="center">
      {domesticReturn ? (
        <>
          <Typography variant="caption">Earning</Typography>
          <Typography mx="4px" fontWeight={600} variant="caption">
            {earnCoin}
          </Typography>
          <Typography variant="caption">coins</Typography>
        </>
      ) : (
        <>
          <Typography variant="caption">Earn</Typography>
          <Typography mx="4px" fontWeight={600} variant="caption">
            {earnCoin}
          </Typography>
          <Typography variant="caption">Niyo coins on this trip</Typography>
        </>
      )}
    </Box>
  );

  const renderAirportDistance = useCallback(
    (iataCode: string, searchedIata: string, isOrigin: boolean) => {
      if (iataCode === searchedIata) return null;

      const airports = isOrigin
        ? nearbyAirports?.origin?.airports
        : nearbyAirports?.destination?.airports;
      const airport = airports?.find((a: any) => a.iata === iataCode);

      if (airport) {
        return (
          <Typography
            variant="body4"
            color="#8C9097"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '100%',
            }}
          >
            {airport.distance.label} from {searchedIata}
          </Typography>
        );
      }
      return null;
    },
    [nearbyAirports]
  );

  return (
    <>
      <FlexItemsBetween
        sx={() => ({
          ...theme.components.pages.searchResults.flightsList.flightCard.flightDetails.container,
          px: domesticReturn ? '10px' : '15px',
          mb: domesticReturn ? '10px' : '15px',
        })}
      >
        <FlexItems
          sx={() => ({
            ...theme.components.pages.searchResults.flightsList.flightCard.flightDetails
              .legContainer,
            alignItems: isInternational ? 'center' : 'start',
            flexDirection: 'column',
            marginRight: domesticReturn ? '0' : '28px',
          })}
        >
          <FlexItems
            sx={
              theme.components.pages.searchResults.flightsList.flightCard.flightDetails.domestic
                .container
            }
          >
            <Stack
              sx={() => ({
                ...theme.components.pages.searchResults.flightsList.flightCard.flightDetails
                  .departureDetails,
                margin: domesticReturn ? '0 4px 0 0' : '0 10px 0 0',
              })}
            >
              <Typography variant={tripType === 'O' || isInternational ? 'body1' : 'body2'}>
                {isInternational ? onwardDepartureTime : departureTime}
              </Typography>
              <Typography
                variant="body4"
                borderRadius="2px"
                px="2px"
                bgcolor={
                  airportReviewData?.isDifferentOnwardDeparture && isInternational
                    ? '#FFEACE'
                    : 'transparent'
                }
              >
                {isInternational ? onwardDeparturePlace : departurePlace}
              </Typography>
            </Stack>
            <Stack
              sx={
                theme.components.pages.searchResults.flightsList.flightCard.flightDetails
                  .durationInfo.container
              }
            >
              <Typography variant="body4" sx={{ color: 'text.secondary' }}>
                {domesticReturn
                  ? (data?.returnDurationInfo?.display ?? data?.durationInfo?.display)
                  : data?.durationInfo?.display}
              </Typography>
              <OAImage
                src={theme.assets.images.icons.lineFlightCard.path}
                alt="line"
                sx={{ width: '68px', height: '6px' }}
              />
              <Typography
                variant="body4"
                sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
              >
                {data?.stops?.display}
              </Typography>
            </Stack>
            <Stack
              sx={() => ({
                ...theme.components.pages.searchResults.flightsList.flightCard.flightDetails
                  .arrivalDurationInfo.container,
                margin: domesticReturn ? '0 0 0 4px' : '0 0 0 10px',
              })}
            >
              <Typography
                display="flex"
                alignItems="center"
                variant={tripType === 'O' || isInternational ? 'body1' : 'body2'}
              >
                {isInternational ? onwardArrivalTime : arrivalTime}{' '}
                {data?.arrivalDayOffset > 0 && (
                  <Typography
                    variant="body3"
                    sx={{ ml: '4px', color: 'error.main', fontWeight: 500 }}
                  >
                    +{data?.arrivalDayOffset}
                  </Typography>
                )}
              </Typography>
              <Typography
                variant="body4"
                borderRadius="2px"
                px="2px"
                bgcolor={
                  airportReviewData?.isDifferentOnwardArrival && isInternational
                    ? '#FFEACE'
                    : 'transparent'
                }
              >
                {isInternational ? onwardArrivalPlace : arrivalPlace}
              </Typography>
            </Stack>
          </FlexItems>
          {airportReviewData && (
            <FlexItemsBetween
              sx={
                theme.components.pages.searchResults.flightsList.flightCard.flightDetails
                  .airportReview.container
              }
            >
              <Box flex={1}>
                {airportReviewData?.isDifferentOnwardDeparture &&
                  renderAirportDistance(
                    airportReviewData?.onwardDeparture,
                    airportReviewData?.searchedFrom,
                    true
                  )}
              </Box>
              <Box flex={1} textAlign="end">
                {airportReviewData?.isDifferentOnwardArrival &&
                  renderAirportDistance(
                    airportReviewData?.onwardArrival,
                    airportReviewData?.searchedTo,
                    false
                  )}
              </Box>
            </FlexItemsBetween>
          )}
          {internationalReturn && (
            <>
              <FlexItems
                sx={
                  theme.components.pages.searchResults.flightsList.flightCard.flightDetails
                    .internationalReturn.container
                }
              >
                <Stack
                  sx={() => ({
                    ...theme.components.pages.searchResults.flightsList.flightCard.flightDetails
                      .departureDetails,
                    margin: domesticReturn ? '0 4px 0 0' : '0 10px 0 0',
                  })}
                >
                  <Typography variant={tripType === 'R' && !isInternational ? 'body2' : 'body1'}>
                    {isInternational ? returnDepartureTime : departureTime}
                  </Typography>
                  {internationalReturn && (
                    <Typography
                      variant="body4"
                      borderRadius="2px"
                      px="2px"
                      bgcolor={
                        airportReviewData?.isDifferentReturnDeparture ? '#FFEACE' : 'transparent'
                      }
                    >
                      {isInternational ? returnDeparturePlace : departurePlace}
                    </Typography>
                  )}
                </Stack>
                {internationalReturn && (
                  <Stack
                    sx={
                      theme.components.pages.searchResults.flightsList.flightCard.flightDetails
                        .internationalReturn.subContainer
                    }
                  >
                    <Typography variant="body4" sx={{ color: 'text.secondary' }}>
                      {data?.returnDurationInfo?.display}
                    </Typography>
                    <OAImage
                      src={theme.assets.images.icons.lineFlightCard.path}
                      alt="line"
                      sx={{ width: '68px', height: '6px' }}
                    />
                    <Typography
                      variant="body4"
                      sx={{ color: 'text.secondary', textTransform: 'capitalize' }}
                    >
                      {data?.returnStops?.display ?? data?.stops?.display}
                    </Typography>
                  </Stack>
                )}
                <Stack
                  sx={() => ({
                    ...theme.components.pages.searchResults.flightsList.flightCard.flightDetails
                      .arrivalDurationInfo.container,
                    margin: domesticReturn ? '0 0 0 4px' : '0 0 0 10px',
                  })}
                >
                  <Typography
                    display="flex"
                    alignItems="center"
                    variant={domesticReturn ? 'body2' : 'body1'}
                  >
                    {isInternational ? returnArrivalTime : arrivalTime}{' '}
                    {data?.arrivalDayOffset > 0 && (
                      <Typography
                        variant="body3"
                        sx={{ ml: '4px', color: 'error.main', fontWeight: 500 }}
                      >
                        +{data?.arrivalDayOffset}
                      </Typography>
                    )}
                  </Typography>
                  {internationalReturn && (
                    <Typography
                      variant="body4"
                      borderRadius="2px"
                      px="2px"
                      bgcolor={
                        airportReviewData?.isDifferentReturnArrival ? '#FFEACE' : 'transparent'
                      }
                    >
                      {isInternational ? returnArrivalPlace : arrivalPlace}
                    </Typography>
                  )}
                </Stack>
              </FlexItems>
              {airportReviewData && (
                <FlexItemsBetween
                  sx={
                    theme.components.pages.searchResults.flightsList.flightCard.flightDetails
                      .airportReview.container
                  }
                >
                  <Box flex={1}>
                    {airportReviewData?.isDifferentReturnDeparture &&
                      renderAirportDistance(
                        airportReviewData?.returnDeparture,
                        airportReviewData?.searchedTo,
                        false
                      )}
                  </Box>
                  <Box flex={1} textAlign="end">
                    {airportReviewData?.isDifferentReturnArrival &&
                      renderAirportDistance(
                        airportReviewData?.returnArrival,
                        airportReviewData?.searchedFrom,
                        true
                      )}
                  </Box>
                </FlexItemsBetween>
              )}
            </>
          )}
        </FlexItems>
        {(isInternational || tripType === 'O') && (
          <Box>
            <FlexItems
              sx={
                theme.components.pages.searchResults.flightsList.flightCard.flightDetails
                  .priceSection.container
              }
            >
              {isCoinsApplied && isDiscountedPrice ? (
                <>
                  <Typography
                    variant="body4"
                    sx={{ color: 'text.secondary', textDecoration: 'line-through' }}
                  >
                    {data?.perAdultPricing?.display ?? data?.price?.display}
                  </Typography>
                  {/* {data?.perAdultPricing?.display && <Typography variant='body4'>per adult</Typography>} */}
                </>
              ) : (
                <Typography variant="body4" sx={{ textDecoration: 'line-through' }}>
                  {data?.perAdultPricing?.strikeOffPriceDisplay}
                </Typography>
              )}
              <Typography
                variant="body1"
                sx={{
                  color: isCoinsApplied
                    ? discountPrice > 0
                      ? 'secondary.main'
                      : 'success.main'
                    : 'secondary.main',
                  fontWeight: 600,
                }}
              >
                {isCoinsApplied
                  ? discountPrice > 0
                    ? formatToINR((data?.perAdultPricing?.value ?? 0) - perPaxSave)
                    : 'Free'
                  : (data?.perAdultPricing?.display ?? data?.price?.display)}
              </Typography>
              {data?.perAdultPricing?.display && (
                <Typography
                  variant="body4"
                  textAlign="end"
                  sx={
                    theme.components.pages.searchResults.flightsList.flightCard.flightDetails
                      .priceSection.textContainer
                  }
                >
                  per adult
                </Typography>
              )}
            </FlexItems>
            {/* {data?.seat && (
              <Typography
                variant={domesticReturn ? 'body4' : 'body3'}
                sx={{ color: 'error.main', fontWeight: 400 }}
              >
                {data?.seat}
              </Typography>
            )} */}
          </Box>
        )}
      </FlexItemsBetween>
      {((isCoinsApplied && coins > 0 && burnCoinsFeat) || earnCoin > 0) && !domesticReturn && (
        <OACoinBannerSecondary
          isEarnCoins={earnCoin > 0}
          isToggleOn={isCoinsApplied}
          title={
            isCoinsApplied
              ? discountPrice > 0
                ? coinsToBeBurned > 0 && (
                    <FlexItems
                      sx={
                        theme.components.pages.searchResults.flightsList.flightCard.flightDetails
                          .coinBannerSection.container
                      }
                    >
                      <OAImage
                        src={theme.assets.images.icons.coin.path}
                        alt="coins"
                        sx={{ mr: '5px', height: '18px', width: '18px' }}
                      />{' '}
                      Saving{' '}
                      {!domesticReturn && (
                        <FlexItems>
                          <Typography ml="4px" variant="caption" fontWeight={600}>
                            {formatToINR(savedMoney)}
                          </Typography>
                        </FlexItems>
                      )}
                      <Typography variant="caption" ml="3px">
                        by redeeming
                      </Typography>
                      <Typography ml="4px" variant="caption" fontWeight={600}>
                        {coinsToBeBurned}
                      </Typography>
                      <Typography variant="caption" ml="3px">
                        coins
                      </Typography>
                    </FlexItems>
                  )
                : 'Flying free using'
              : discountPrice > 0
                ? earnCoin > 0
                  ? earnCoinLabel
                  : null
                : coinsToBeBurned > 0 && (
                    <FlexItems>
                      <OAImage
                        src={theme.assets.images.icons.tick.path}
                        folder={theme.assets.images.icons.coin.folder}
                        alt="tick"
                        sx={{ mr: '4px' }}
                      />{' '}
                      Redeeming{' '}
                      <Typography ml="4px" variant="caption" fontWeight={600}>
                        {coinsToBeBurned}
                      </Typography>{' '}
                      <OAImage
                        src={theme.assets.images.icons.coin.path}
                        alt="coins"
                        sx={{ mx: '5px', height: '18px', width: '18px' }}
                      />{' '}
                      {!domesticReturn && (
                        <FlexItems>
                          <Typography variant="body2">to save</Typography>
                          <Typography ml="4px" variant="caption" fontWeight={600}>
                            {formatToINR(savedMoney)}
                          </Typography>
                        </FlexItems>
                      )}
                    </FlexItems>
                  )
          }
          color={
            isCoinsApplied ? (coinsToBeBurned > 0 ? 'primary' : '') : earnCoin > 0 ? 'yellow' : ''
          }
          sx={{
            borderTopRightRadius: '0px',
            borderTopLeftRadius: '0px',
            // borderBottomRightRadius: domesticReturn ? '0px' : '10px',
            // borderBottomLeftRadius: domesticReturn ? '0px' : '10px',
            p: domesticReturn ? '5px 10px' : '5px 15px',
          }}
        />
      )}
      {/* {data?.perAdultPricing?.savedConvenienceFee && data?.perAdultPricing?.savedConvenienceFee > 0 && !domesticReturn &&
        <ConvenienceBanner
          isCoinsApplied={isCoinsApplied}
          amount={data?.perAdultPricing?.savedConvenienceFee}
          text='/ person on convenience fees'
          icon={<OAImage src={isCoinsApplied ? "toggle-on-conv.svg" : "toggle-off-conv.svg"}
          folder="icons"
          alt="tick"
          sx={{ mr: '4px' }}
        />}
      />} */}
    </>
  );
};

export default FlightDetail;
