import { OATextField } from '../../components';
import { useTraveller } from '../../hooks/useTraveller';
import AddEditTraveller from './AddEditTraveller';

const Travellers = () => {
  const { travellerList, isFetching, isLoading, isError, refetch } = useTraveller();
  return (
    <>
      <AddEditTraveller />
    </>
  );
};

export default Travellers;
