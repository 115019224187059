import { Box, Container, Skeleton, Typography } from '@mui/material';
import { OAButton, OACheckbox, OAImage } from '../../components';
import { useDispatch, useSelector } from '../../store';
import { getUserInputState } from '../../store/slices/userInputSlice';
import { getFlightOrderState } from '../../store/slices/flightOrderSlice';
import { setMessage } from '../../store/slices/snackbarSlice';
import { useCallback, useState, useMemo } from 'react';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { useNavigate } from 'react-router-dom';
import { setSelectedTravellersIds, setTravellerToEdit } from '../../store/slices/travellerSlice';
import FlexItemsBetween from '../../components/FlexItemsBetween';
import { useTheme } from '../../config/theme/useTheme';
import FlexItems from '../../components/FlexItems';
import { useTraveller } from '../../hooks/useTraveller';
import { getCurrentTravellerSelectionCounts } from '../../utils/getCurrentTravellerSelectionCounts';

const List = () => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { travellerList, isLoading, selectedTravellersIds } = useTraveller();

  const { travelDetail } = useSelector(getUserInputState);

  const { flightData } = useSelector(getFlightOrderState);

  const [showAllTravellers, setShowAllTravellers] = useState<boolean>(false);

  const totalCount =
    flightData?.travellerCount?.adult +
      flightData?.travellerCount?.child +
      flightData?.travellerCount?.infant ||
    travelDetail?.travellerCount?.adult +
      travelDetail?.travellerCount?.child +
      travelDetail?.travellerCount?.infant;

  const handleToggleTravellerSelection = useCallback(
    (traveller: any) => {
      const fareGroup = flightData?.flightSearchInfo?.fareGroup;
      if (fareGroup === 'STUDENT' && ['CHILD', 'INFANT'].includes(traveller?.type)) {
        dispatch(setMessage('Student fares are available for adult passengers only.'));
        return;
      }

      const currentSelectionCounts = getCurrentTravellerSelectionCounts(
        selectedTravellersIds,
        travellerList
      );
      const allowedCounts: any = {
        ADULT: flightData?.travellerCount?.adult || travelDetail?.travellerCount?.adult,
        CHILD: flightData?.travellerCount?.child || travelDetail?.travellerCount?.child,
        INFANT: flightData?.travellerCount?.infant || travelDetail?.travellerCount?.infant,
      };

      if (flightData?.international && !traveller?.passport) {
        if (currentSelectionCounts[traveller?.type] < allowedCounts[traveller?.type]) {
          dispatch(setTravellerToEdit(traveller));
          navigate('/travellers');
          return;
        } else {
          dispatch(setMessage(`Cannot select more ${traveller.type.toLowerCase()}s than allowed.`));
        }
      } else {
        if (selectedTravellersIds?.includes(traveller?.id)) {
          dispatch(
            setSelectedTravellersIds(
              selectedTravellersIds?.filter((id: any) => id !== traveller?.id)
            )
          );
        } else {
          // checking if adding this traveler would exceed the allowed count for their type
          if (currentSelectionCounts[traveller?.type] < allowedCounts[traveller?.type]) {
            eventsTracker(
              {
                flowName: 'Flight',
                screenName: 'Confirm',
                ctaAction: 'Addtraveller',
                screenDuration: '1',
              },
              posthog
            );
            dispatch(setSelectedTravellersIds([...selectedTravellersIds, traveller?.id]));
          } else {
            dispatch(
              setMessage(`Cannot select more ${traveller.type.toLowerCase()}s than allowed.`)
            );
          }
        }
      }
    },
    [travelDetail, selectedTravellersIds, travellerList, flightData, dispatch]
  );

  const handleAddTraveller = useCallback(() => {
    if (
      totalCount !== selectedTravellersIds?.length &&
      totalCount > selectedTravellersIds?.length
    ) {
      dispatch(setTravellerToEdit(null));
      navigate('/travellers');
    } else {
      dispatch(setMessage('You have already selected all the travellers'));
    }
  }, [totalCount, selectedTravellersIds, dispatch]);

  const calculateAgeDisplay = useCallback((traveller: any) => {
    if (traveller?.age > 0) {
      return `${traveller?.age} yrs`;
    } else {
      const birthDate = new Date(traveller?.dateOfBirth);
      const today = new Date();
      let months = (today.getFullYear() - birthDate.getFullYear()) * 12;
      months -= birthDate.getMonth();
      months += today.getMonth();
      return `${months} mth${months !== 1 ? 's' : ''}`;
    }
  }, []);

  const displayedTravellers = useMemo(() => {
    return showAllTravellers ? travellerList : travellerList?.slice(0, 4);
  }, [travellerList, showAllTravellers]);

  return (
    <>
      <Container sx={{ mt: '16px' }}>
        <FlexItemsBetween>
          <Box>
            <FlexItems sx={theme.components.pages.itinerary.travellers.subContainer}>
              <Typography variant="body1" sx={{ mr: '6px' }} fontWeight={500}>
                Travellers
              </Typography>
            </FlexItems>
            <Box mb="12px">
              <Typography variant="body3" sx={{ color: 'grey.800' }}>
                ({travellerList?.length > 0 ? 'Select' : 'Add'}:{' '}
                {travelDetail?.travellerCount?.adult
                  ? `${travelDetail.travellerCount.adult} Adult${
                      travelDetail.travellerCount.adult > 1 ? 's' : ''
                    }`
                  : ''}
                {travelDetail?.travellerCount?.child
                  ? ` • ${travelDetail.travellerCount.child} Child${
                      travelDetail.travellerCount.child > 1 ? 'ren' : ''
                    }`
                  : ''}
                {travelDetail?.travellerCount?.infant
                  ? ` • ${travelDetail.travellerCount.infant} Infant${
                      travelDetail.travellerCount.infant > 1 ? 's' : ''
                    }`
                  : ''}
                )
              </Typography>
            </Box>
          </Box>
          <Box>
            <OAButton
              onClick={handleAddTraveller}
              size="small"
              variant="outlined"
              color="secondary"
              sx={theme.components.pages.itinerary.travellers.addCta}
            >
              <OAImage
                src={theme.assets.images.icons.addBlack.path}
                folder={theme.assets.images.icons.addBlack.folder}
                alt="add"
                sx={{ mr: '5px' }}
              />
              Add
            </OAButton>
          </Box>
        </FlexItemsBetween>
        {travellerList?.length > 0 ? (
          <Box sx={theme.components.pages.itinerary.travellers.listContainer}>
            {displayedTravellers.map((traveller: any, index: number) => (
              <Box key={traveller?.id} mb="16px">
                <OACheckbox
                  label={`${traveller?.firstName} ${traveller?.lastName}, ${calculateAgeDisplay(
                    traveller
                  )}`}
                  checked={selectedTravellersIds?.includes(traveller?.id)}
                  onChange={() => handleToggleTravellerSelection(traveller)}
                  onEdit={() => {
                    dispatch(setTravellerToEdit(traveller));
                    navigate('/travellers');
                  }}
                  labelStyle={{
                    fontSize: '14px',
                    lineHeight: '20px',
                  }}
                />
              </Box>
            ))}
            {travellerList?.length > 4 && (
              <FlexItemsBetween
                sx={theme.components.pages.itinerary.travellers.subListContainer}
                onClick={() => setShowAllTravellers(!showAllTravellers)}
              >
                <Typography variant="body3" fontWeight={600} color="#009E82">
                  {showAllTravellers ? 'Show less' : 'Show all travellers'}
                </Typography>
                <OAImage
                  src={
                    showAllTravellers
                      ? theme.assets.images.icons.dropUp.path
                      : theme.assets.images.icons.dropDown.path
                  }
                  folder={theme.assets.images.icons.dropUp.folder}
                  alt="arrow down"
                />
              </FlexItemsBetween>
            )}
          </Box>
        ) : (
          <Box mt="16px">
            {isLoading && (
              <>
                {' '}
                <Skeleton sx={{ mb: '16px' }} />
                <Skeleton sx={{ mb: '16px' }} />
                <Skeleton sx={{ mb: '16px' }} />
                <Skeleton />
              </>
            )}
          </Box>
        )}
      </Container>
    </>
  );
};

export default List;
