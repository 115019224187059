import { ThemeConfig } from '../types';

// Default theme configuration
export const defaultTheme: ThemeConfig = {
  strings: {
    flight: {
      header: {
        premium: {
          title: 'Flights',
          subtitle: 'Redeem Niyo coins with additional discount',
        },
        regular: {
          title: 'Book flights with',
          subtitle: '₹0 convenience fee',
          disclaimer: 'on UPI & Niyo Cards',
          coinsText: 'Redeem Niyo coins for additional discount',
        },
      },
      airportSearch: {
        airportSuggestions: {
          continueSearch: 'Continue your search',
          popularSearch: 'Popular Searches',
        },
      },
      studentFare: {
        title: 'Eligibility',
        text: 'Students above 12 years of age are eligible for special fares and/or additional baggage allowance on certain airline bookings.',
        subText:
          'Carrying a valid student ID card and student visa (where applicable) is mandatory.',
      },
    },
    searchResults: {
      coinSwitch: {
        withCoins: {
          title: 'Show price using',
          subtitle: 'coins',
        },
        withoutCoins: {
          title: 'Earn Niyo coins',
          subtitle: '& save upto 75% on next booking',
        },
      },
    },
    itinerary: {
      footer: {
        coinBanner: {
          discounted: {
            text: 'Money saved on this trip',
          },
          earn: {
            text: 'Niyo Coins to be earned on this trip',
          },
        },
        fareSummary: {
          convenienceFeeTitle: {
            container: {
              title: 'Convenience fee',
              subTitle: 'with Niyo Premium',
            },
          },
          coinBanner: {
            bookingSuccess: {
              title: 'Niyo coins earned on this trip',
            },
            bookingPending: {
              title: 'Niyo coins to be earned on this trip',
            },
          },
          coinsDiscount: {
            title: 'Niyo Global coins discount',
          },
        },
      },
    },
    myBookings: {
      coinsInfo: {
        title:
          'Your earned coins will be deposited in your wallet after your journey has been completed',
      },
      cancellationRequest: {
        title:
          "Your cancellation request has been sent, we're on top of it. Our support team will contact you shortly to assist you further via phone/email/whatsapp",
      },
    },
    bookingStatus: {
      pnr: {
        text: 'Please wait while we generate your PNR',
      },
      confirmedText: {
        text: 'Booking confirmed! PNR details are on the way',
      },
      cancel: {
        fallbackUi: {
          text: "If charges aren't visible, please contact the Niyo team for assistance. Cancellation/date change fees will always follow airline policy",
        },
      },
    },
    somethingWentWrong: {
      text: 'Something went wrong',
      subText: 'Oops! There was an error we couldn’t book your flight. Try again.',
      ctaText: 'Back to Flights',
    },
  },
  name: 'default',
  colors: {
    primary: '#0A1017',
    secondary: '#FFFFFF',
    text: {
      primary: '#000000',
      secondary: '#666666',
      light: '#FFFFFF',
    },
    background: {
      main: '#FFFFFF',
      secondary: '#F5F5F5',
      dark: '#0A1017',
    },
    status: {
      success: '#4CAF50',
      error: '#F44336',
      warning: '#FFC107',
      info: '#2196F3',
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    sizes: {
      h1: '30px',
      h2: '24px',
      h3: '20px',
      body1: '16px',
      body2: '14px',
      small: '12px',
    },
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
    },
    lineHeights: {
      h1: '40px',
      h2: '32px',
      h3: '28px',
      body1: '24px',
      body2: '20px',
      small: '16px',
    },
  },
  spacing: {
    unit: 8,
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
  },
  borderRadius: {
    small: '4px',
    medium: '8px',
    large: '16px',
    circle: '50%',
  },
  components: {
    pages: {
      flight: {
        container: {
          borderRadius: '20px 20px 0px 0px',
          position: 'relative',
          mt: '-35px',
          backgroundColor: 'white',
        },
        wrapper: {
          overflow: 'auto',
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        },
        coinsBanner: {
          wrapper: {
            position: 'absolute',
            bottom: '50px',
            left: '20px',
          },
          icon: {
            mx: '5px',
            height: '20px',
            width: '20px',
          },
        },
        specialOffers: {
          container: {
            my: '30px',
            px: '0px',
          },
        },
        vConsoleFooter: {
          container: {
            position: 'fixed',
            bottom: '0',
            left: '0',
            width: '100%',
            backgroundColor: 'grey.500',
            padding: '10px',
            textAlign: 'center',
            color: 'grey.800',
            mt: '24px',
          },
        },
        recentFlightSearch: {
          wrapper: {
            mb: '30px',
            withBanner: {
              mt: 0,
            },
            withoutBanner: {
              mt: '30px',
            },
          },
        },
        searchParameters: {
          searchAirport: {
            container: {
              mt: '20px',
              gap: '20px',
              position: 'relative',
            },
          },
        },
      },
      searchResults: {
        container: {
          zIndex: 100,
          position: 'sticky',
          top: 0,
          bgcolor: 'background.paper',
        },
        filterAndSort: {
          container: {
            py: '8px',
            overflow: 'hidden',
            px: '0px',
          },
          subContainer: {
            overflowX: 'auto',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          },
          filter: {
            stops: {
              container: {
                p: '10px',
                borderRadius: '8px',
                width: '100%',
                textAlign: 'center',
                border: '1px solid #E2E2E2',
                bgcolor: 'transparent',
                cursor: 'pointer',
                opacity: 1,
              },
              subContainer: {
                maxHeight: 'calc(100vh - 200px)',
                overflowY: 'auto',
                pt: 2,
              },
              containerRenderer: {
                pt: 2,
                minHeight: '100px',
              },
              withApplied: {
                border: '1px solid #009E82',
                bgcolor: '#D9FCF3',
              },
              disabled: {
                opacity: 0.5,
                cursor: 'not-allowed',
              },
              stopsContent: {
                text: {
                  color: '#009E82',
                  fontWeight: 600,
                },
                subText: {
                  color: '#000000',
                  fontWeight: 400,
                },
                disabled: {
                  text: {
                    color: '#8C9097',
                    fontWeight: 600,
                  },
                  subText: {
                    color: '#8C9097',
                    fontWeight: 400,
                  },
                },
              },
            },
            airports: {
              text: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
              subText: {
                whiteSpace: 'nowrap',
              },
            },
            airlines: {
              container: {
                width: '100%',
              },
              text: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              },
              subText: {
                whiteSpace: 'nowrap',
              },
            },
            filtersCtaContainer: {
              position: 'sticky',
              zIndex: 10,
              bottom: 0,
              background: 'white',
              py: '30px',
              borderTop: '1px solid #DEE2E1',
            },
            divider: {
              styles: {
                container: {
                  my: '30px',
                  borderStyle: 'dashed',
                },
              },
            },
          },
          nonStopCta: {
            default: {
              backgroundColor: 'white',
              borderColor: '#DEE2E1',
            },
            active: {
              backgroundColor: '#D9FCF3',
              borderColor: '#00C6A8',
            },
          },
        },
        loadingContainer: {
          container: {
            bgcolor: 'white',
            py: '8px',
            justifyContent: 'space-around',
          },
          loadingItem: {
            width: '100px',
            height: '30px',
            borderRadius: '4px',
          },
        },
        locationContainer: {
          container: {
            bgcolor: 'background.paper',
            my: '10px',
          },
        },
        flightsList: {
          container: {
            height: 'calc(100vh - 104px)',
            overflow: 'scroll',
            px: '0px',
            bgcolor: '#F4F6F5',
            pt: '16px',
          },
          flightCardContainer: {
            styles: {
              mx: '16px',
              mt: '16px',
              position: 'relative',
            },
          },
          similarFlights: {
            container: {
              justifyContent: 'flex-end',
            },
            subContainer: {
              backgroundColor: '#009E82',
              padding: '4px 8px',
              color: 'white',
              borderBottomLeftRadius: '8px',
              borderBottomRightRadius: '8px',
              borderTopRightRadius: '0px',
              borderTopLeftRadius: '0px',
            },
          },
          flightCard: {
            cardContainer: {
              p: '1px',
              borderRadius: '8px',
              border: '1px solid #DEE2E1',
              bgcolor: '#FDFDFD',
              position: 'relative',
              overflow: 'unset',
            },
            airlineDetails: {
              container: {
                mb: '15px',
                px: '15px',
                pt: '15px',
                alignItems: 'center',
              },
              airlineNameContainer: {
                mr: '10px',
                overflow: 'hidden',
              },
              flightAvatar: {
                marginRight: '-12px',
                position: 'revert',
                width: '20px',
                height: '20px',
              },
              domesticReturn: {
                flexDirection: 'column',
                alignItems: 'end',
              },
            },
            flightDetails: {
              container: {
                px: '15px',
                mb: '15px',
              },
              legContainer: {
                width: '100%',
                marginRight: '28px',
                flexDirection: 'column',
              },
              departureDetails: {
                direction: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-end',
                margin: '0 10px 0 0',
              },
              durationInfo: {
                container: {
                  width: '100%',
                  margin: '0px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  direction: 'column',
                },
              },
              arrivalDurationInfo: {
                container: {
                  margin: '0px 0px 0px 10px',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                  direction: 'column',
                },
              },
              airportReview: {
                container: {
                  width: '100%',
                  gap: '28px',
                },
              },
              domestic: {
                container: {
                  width: '100%',
                },
              },
              internationalReturn: {
                container: {
                  width: '100%',
                  marginTop: '15px',
                },
                subContainer: {
                  width: '100%',
                  direction: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '0px',
                },
              },
              priceSection: {
                container: {
                  flexDirection: 'column',
                  alignItems: 'end',
                },
                textContainer: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '100%',
                },
              },
              coinBannerSection: {
                container: {
                  fontWeight: 600,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                },
              },
            },
          },
        },
      },
      itinerary: {
        container: {
          position: 'sticky',
          top: 0,
          zIndex: 100,
          bgcolor: 'background.paper',
        },
        flightRouteContainer: {
          bgcolor: '#F4F6F5',
          pt: '13px',
          pb: '16px',
        },
        flightRouteSubContainer: {
          color: 'gray',
          bgcolor: '#F4F6F5',
          p: '10px 20px',
        },
        oaFlightRoute: {
          container: {
            color: 'text.secondary',
          },
        },
        refundText: {
          container: {
            p: '5px 10px',
            fontWeight: 600,
            borderRadius: '46px',
          },
          noRefund: {
            color: '#5A6068',
            bgcolor: '#DEE2E1',
          },
          partRefund: {
            bgcolor: '#DBF5ED',
            color: '#194244',
          },
        },
        travellers: {
          subContainer: {
            alignItems: 'end',
            mb: '4px',
          },
          addCta: {
            borderRadius: '8px',
            py: '5px',
          },
          listContainer: {
            bgcolor: '#F4F6F5',
            p: '10px',
            borderRadius: '10px',
          },
          subListContainer: {
            borderRadius: '10px',
            cursor: 'pointer',
            mt: '10px',
          },
        },
        cfar: {
          container: {
            my: '30px',
          },
          assetContainer: {
            mb: '-10px',
          },
          subContainer: {
            bgcolor: '#E6EEFF',
            p: '5px 20px',
          },
          contentSubContainer: {
            mr: '6px',
          },
          bottomAsset: {
            mt: '-2px',
          },
          cardContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '15px',
            p: '12px',
          },
        },
        contactDetails: {
          container: {
            my: '16px',
          },
        },
        smbWrapper: {
          container: {
            position: 'fixed',
            width: '100%',
            zIndex: 10,
            borderTop: '1px solid #DEE2E1',
          },
          containerWithSmbAndPremium: {
            bottom: '91px',
          },
          containerWithSmbAndRegular: {
            bottom: '75px',
          },
          containerWithSmb: {
            py: '15px',
            backdropFilter: 'blur(5px)',
            background: 'transparent',
            transition: 'all 0.6s cubic-bezier(0.25, 0.1, 0.25, 1)',
            transform: 'translateY(100%)',
            opacity: 0,
            position: 'fixed',
            bottom: 75,
            left: 0,
            right: 0,
            width: '100%',
            maxWidth: '100% !important',
            zIndex: 1000,
            boxShadow: '0px -4px 10px rgba(0, 0, 0, 0.1)',
            willChange: 'transform, opacity',
          },
          containerWithSmbEnabled: {
            transform: 'translateY(0) !important',
            opacity: 1,
          },
          containerWithPremiumUser: {
            bottom: '95px',
          },
          smbOptionContainer: {
            position: 'relative',
          },
          smbOptionCard: {
            container: {
              p: '8px',
            },
            title: {
              fontSize: '0.75rem',
              fontWeight: 600,
              lineHeight: '20px',
            },
            subTitle: {
              fontSize: '0.625rem',
              fontWeight: 400,
              lineHeight: '15px',
              color: '#5A6068',
            },
          },
          smbContent: {
            loaderContainer: {
              container: {
                width: '100%',
                height: 'calc(100vh - 220px)',
                overflow: 'scroll',
                bgcolor: '#F4F6F5',
                position: 'sticky',
                top: '187px',
              },
              subContainer: {
                width: '100%',
                px: '22px',
                pb: '10px',
              },
              loaderFlex: {
                border: '1px solid #E6EAE9',
                borderRadius: '20px',
                p: '2px',
              },
              skeleton: {
                width: '100%',
                height: '34px',
                borderRadius: '40px',
              },
              skeletonWithMargin: {
                mx: '3px',
              },
              itemsLoader: {
                container: {
                  px: '20px',
                  py: '10px',
                },
                primaryItemLoader: {
                  width: '50px',
                  height: '16px',
                  borderRadius: '50px',
                },
                secondaryItemLoader: {
                  width: '70px',
                  height: '21px',
                  borderRadius: '50px',
                  ml: '5px',
                },
                complimentaryItemLoader: {
                  width: '150px',
                  height: '50px',
                  borderRadius: '50px',
                },
              },
            },
            tabContainer: {
              bgcolor: '#F4F6F5',
            },
            tabsCta: {
              position: 'sticky',
              top: '90px',
              bgcolor: 'white',
              zIndex: 10,
              paddingBottom: '10px',
              px: '16px',
            },
            tabsChip: {
              container: {
                bgcolor: 'undefined',
                borderColor: 'undefined',
                mr: '8px',
                p: '5px 1px',
              },
              selectedContainer: {
                bgcolor: '#D9FCF3',
                borderColor: '#00C6A8',
              },
            },
            seatPanel: {
              container: {
                width: '100%',
                overflow: 'scroll',
                position: 'sticky',
                top: '187px',
                bgcolor: 'white',
                zIndex: 10,
              },
              subContainer: {
                bgcolor: 'white',
                p: '8px',
                pl: '16px',
              },
              containerItem: {
                height: '16px',
                width: '16px',
                borderRadius: '3px',
              },
            },
          },
        },
        footer: {
          container: {
            height: '95px',
            heightWithCoinBanner: '110px',
            heightWithCoinBannerAndPremium: '135px',
          },
          subContainer: {
            top: 'auto',
            bottom: 0,
            boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.06)',
            borderTop: '1px solid #DEE2E1',
          },
          subContainerWithBoxShadow: {
            boxShadow: '0px -4px 24px 0px rgba(0, 0, 0, 0.06)',
            borderTop: '',
          },
          convenienceFeeBanner: {
            container: {
              bgcolor: '#24AA9D',
              p: '2px 8px',
              borderRadius: '18px',
              mt: '2px',
              width: 'fit-content',
            },
            subText: {
              fontSize: '0.7em',
              verticalAlign: 'super',
              lineHeight: 0,
              mt: '-1px',
            },
          },
          secondaryCoinBanner: {
            coinIcon: {
              mx: '5px',
              height: '18px',
              width: '18px',
            },
          },
          flightAvatar: {
            container: {
              marginRight: '-8px',
              position: 'revert',
              height: '15px',
              width: '15px',
            },
            containerWithMargin: {
              marginLeft: '4px',
            },
          },
          priceBreakup: {
            container: {
              padding: 0,
              minWidth: 0,
              minHeight: 0,
            },
            text: {
              color: '#009E82',
              textDecoration: 'underline',
            },
          },
          fareSummary: {
            container: {
              mb: '24px',
            },
            flightBasedBreakup: {
              container: {
                bgcolor: '#F4F6F5',
                p: '10px 16px',
                mb: '15px',
              },
              listItem: {
                container: {
                  py: '0px',
                  px: '16px',
                },
              },
              sectionSeparator: {
                container: {
                  px: '16px',
                },
                divider: {
                  mb: '12px',
                },
              },
            },
            convenienceFeeTitle: {
              container: {
                display: 'flex',
                flexDirection: 'column',
              },
            },
          },
        },
      },
      myBookings: {
        container: {
          flexDirection: 'column',
          minHeight: '100vh',
        },
        header: {
          position: 'sticky',
          top: 0,
          bgcolor: 'white',
          zIndex: 1,
          width: '100%',
        },
        listingContainer: {
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        },
        errorContainer: {
          height: 'calc(100vh - 150px)',
          overflow: 'scroll',
          bgcolor: '#F4F6F5',
          py: '16px',
        },
        itemsWrapper: {
          flexGrow: 1,
          overflow: 'auto',
          width: '100%',
        },
        coinsInfo: {
          container: {
            display: 'flex',
            marginTop: '16px',
            marginBottom: '14px',
            bgcolor: '#F4F6F5',
            p: '10px',
          },
          coinIcon: {
            mx: '5px',
            height: '18px',
            width: '18px',
          },
        },
        emptyBookings: {
          container: {
            height: 'calc(100vh - 150px)',
            overflow: 'scroll',
            bgcolor: '#F4F6F5',
            py: '16px',
          },
        },
        premiumOffer: {
          container: {
            position: 'sticky',
            bottom: 0,
            backgroundColor: '#132371',
            zIndex: 1,
            p: '10px 20px',
          },
        },
      },
      bookingStatus: {
        container: {
          position: 'sticky',
          top: 0,
          bgcolor: 'white',
          zIndex: 1,
        },
        statusMsgAndBtn: {
          container: {
            py: '15px',
          },
          subContainer: {
            alignItems: 'start',
          },
          loaderContainer: {
            mt: '20px',
          },
          loader: {
            height: '50px',
            width: '100%',
            mt: '20px',
          },
          checkInLoader: {
            height: '50px',
            width: '100%',
            mt: '10px',
          },
          cancellationRequest: {
            container: {
              display: 'flex',
              marginTop: '20px',
              bgcolor: '#F6F6F6',
              p: '10px',
              border: 'none',
            },
          },
        },
        cfar: {
          topSection: {
            container: {
              width: '100%',
              mb: '-10px',
            },
          },
          mainSection: {
            container: {
              px: '20px',
              py: '10px',
              bgcolor: '#E6EEFF',
            },
            card: {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
          },
          bottomSection: {
            container: {
              width: '100%',
              bgcolor: '#F4F6F5',
            },
          },
        },
        tripDetailsSegments: {
          container: {
            bgcolor: '#F4F6F5',
            py: '15px',
          },
          subContainer: {
            mb: '10px',
          },
          refundStatusBar: {
            container: {
              color: 'gray',
              bgcolor: '#F4F6F5',
              mb: '10px',
            },
            text: {
              p: '5px 10px',
              fontWeight: '600',
              borderRadius: '48px',
              bgcolor: '#DEE2E1',
              color: '#5A6068',
            },
            refundText: {
              bgcolor: '#DBF5ED',
              color: '#194244',
            },
          },
          pnr: {
            skeleton: {
              height: '82px',
              width: '100%',
            },
            container: {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            },
            text: {
              color: '#fff',
              paddingLeft: '2px',
            },
          },
          returnSegment: {
            container: {
              color: 'gray',
              bgcolor: '#F4F6F5',
              p: '10px 20px',
              mt: '15px',
            },
          },
        },
        travellerDetails: {
          container: {
            pt: '20px',
          },
          subContainer: {
            py: '8px',
          },
          skeleton: {
            item: {
              fontSize: '16px',
              width: '100%',
              py: '8px',
            },
          },
          divider: {
            borderBottomWidth: '4px',
            borderColor: '#F4F6F5',
            my: '20px',
          },
        },
        contactSupport: {
          container: {
            mb: '20px',
          },
          customSection: {
            subContainer: {
              display: 'flex',
              alignItems: 'start',
            },
            contentWrapper: {
              display: 'flex',
              flexDirection: 'column',
              ml: '10px',
            },
            title: {
              mb: '4px',
            },
            description: {
              color: 'text.secondary',
              maxWidth: '85%',
            },
            icon: {
              color: 'text.secondary',
            },
          },
          divider: {
            borderBottomWidth: '4px',
            borderColor: '#F4F6F5',
            my: '20px',
          },
        },
        addOnsSummary: {
          header: {
            mt: '18px',
            display: 'flex',
            overflowX: 'scroll',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
          },
          chip: {
            p: '5px 1px',
            mr: '8px',
          },
          ancillaryContainer: {
            p: '0 15px',
            mt: '0px',
          },
          ancillaryContainerWithSortedSegments: {
            mt: '18px',
          },
        },
        paymentAndInvoice: {
          coinsDetailsContainer: {
            bgcolor: 'primary.light',
            p: '6px 10px',
            borderRadius: '4px',
            mt: '8px',
            mb: '15px',
          },
          coinIcon: {
            mx: '5px',
            height: '18px',
            width: '18px',
          },
          paymentDetails: {
            container: {
              p: '15px',
              mb: '12px',
            },
            transaction: {
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              bgcolor: 'yellow.light',
              borderRadius: '5px',
              pl: '10px',
              maxWidth: '100%',
              boxSizing: 'border-box',
            },
            transactionText: {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
            divider: {
              border: '0.5px solid #5A6068',
              ml: '15px',
            },
            viewCta: {
              container: {
                py: '8px',
              },
              icon: {
                color: 'secondary.main',
              },
            },
            discountOfferBanner: {
              container: {
                position: 'relative',
                my: '20px',
              },
              subContainer: {
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                p: '20px',
              },
            },
            offerBanner: {
              container: {
                px: '20px',
                mt: '20px',
              },
            },
          },
          divider: {
            borderBottomWidth: '4px',
            borderColor: '#F4F6F5',
            my: '20px',
          },
        },
        cancel: {
          cta: {
            mt: '20px',
            border: '1px solid #C01100',
          },
          viewText: {
            fontWeight: 500,
            color: '#009E82',
            textAlign: 'center',
            mt: '20px',
          },
          divider: {
            borderBottomWidth: '4px',
            borderColor: '#F4F6F5',
            my: '20px',
          },
          cancelReasons: {
            skeleton: {
              mb: 2,
            },
            container: {
              my: '20px',
            },
            reasonContainer: {
              width: '100%',
            },
            divider: {
              borderBottomWidth: '4px',
              borderColor: '#F4F6F5',
              my: '20px',
            },
            icon: {
              color: 'secondary.main',
              fontWeight: 300,
            },
          },
          miniruleContainer: {
            bgcolor: '#F4F6F5',
            p: '10px 16px',
            mb: '15px',
            color: 'gray',
          },
          cancellationChargesInfo: {
            container: {
              p: '16px',
              borderRadius: '8px',
              mb: '20px',
            },
            subContainer: {
              mb: '15px',
            },
            divider: {
              borderColor: '#DEE2E1',
              my: '15px',
            },
          },
          fallbackUi: {
            container: {
              p: '16px',
              flexDirection: 'column',
            },
            text: {
              color: 'text.secondary',
              textAlign: 'center',
              p: '10px',
              bgcolor: '#F4F6F5',
              borderRadius: '10px',
              mt: '20px',
            },
          },
          fallbackUiSecondary: {
            text: {
              color: 'text.secondary',
              p: '10px',
            },
          },
        },
      },
    },

    header: {
      styles: {
        default: {
          // height: '64px',
          px: '16px',
          width: '100%',
          position: 'relative',
          title: {
            fontSize: '16px',
            fontWeight: 600,
            color: '#FFFFFF',
          },
          subtitle: {
            fontSize: '14px',
            fontWeight: 400,
            color: '#FDFDFD',
          },
        },
        home: {
          background: 'transparent',
          backgroundImage:
            'url(https://oa-flight-assets.s3.ap-south-1.amazonaws.com/offer/top-container.png)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '300px',
        },
        premium: {
          backgroundSize: '100%',
          backgroundPosition: 'none',
          minHeight: '30vh',
        },
      },
    },
    banner: {
      premium: {
        title: {
          color: '#FFFFFF',
          fontWeight: 600,
          fontSize: '30px',
          lineHeight: '40px',
          mt: '10px',
        },
        subtitle: {
          color: '#FDFDFD',
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
          mt: '10px',
        },
      },
      regular: {
        title: {
          color: '#FFFFFF',
          fontWeight: 400,
          fontSize: '25px',
          lineHeight: '35px',
        },
        subtitle: {
          color: '#FFFFFF',
          fontWeight: 600,
          fontSize: '30px',
          lineHeight: '40px',
        },
      },
    },
    backBtn: {
      styles: {
        default: {
          height: '40px',
          width: '40px',
          borderRadius: '50%',
          bgcolor: '#FFFFFF',
        },
      },
    },
    swapper: {
      styles: {
        default: {
          height: '40px',
          width: '40px',
          borderRadius: '50%',
          bgcolor: '#FDFDFD',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 1,
        },
      },
    },
    searchAirport: {
      styles: {
        inputAirportCity: {
          fontSize: '14px',
          lineHeight: '20px',
          whiteSpace: 'nowrap',
        },
        inputAirportName: {
          fontSize: '12px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: '#000000',
          paddingLeft: '10px',
        },
      },
    },
    airportSuggestions: {
      styles: {
        title: {
          mb: '10px',
          backgroundColor: '#F4F6F5',
          p: '10px 16px',
        },
        container: {
          py: '12px',
          mb: '10px',
        },
        airportIata: {
          bgcolor: '#D0F9F5',
          mr: '8px',
          borderRadius: '4px',
          textAlign: 'center',
          padding: '4px 6px',
          border: '1px solid #00C6A1',
          width: '30px',
          fontWeight: 600,
        },
        airportCity: {
          fontSize: '14px',
          fontWeight: 600,
          color: '#000000',
        },
        airportName: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          color: '#000000',
        },
        autoCompleteContainer: {
          px: '16px',
          pb: '10px',
          mb: '14px',
          bgcolor: 'white',
          position: 'sticky',
          top: '94px',
        },
      },
    },
    customTabs: {
      styles: {
        tabs: {
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: '#FFFFFF',
          borderRadius: '50px',
          border: '1px solid #E2E2E2',
          padding: '4px',
          minHeight: '32px !important',
          '& .MuiTabs-indicator': {
            height: '100%',
            borderRadius: '50px',
            transition: 'left 0.2s linear',
          },
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
        },
        tab: {
          textTransform: 'none',
          flex: '1',
          zIndex: 1,
          transition: 'color 0.2s linear',
          minHeight: '32px !important',
          paddingTop: '6px !important',
          paddingBottom: '6px !important',
          background: 'transparent',
          '&.Mui-selected': {
            color: 'white',
            borderRadius: '50px',
          },
          '&:first-of-type': {
            borderRadius: '50px',
          },
          '&:last-of-type': {
            borderRadius: '50px',
          },
        },
      },
    },
    dateDrawer: {
      styles: {
        container: {
          position: 'sticky',
          bottom: '0',
          bgcolor: 'white',
          borderTop: '1px solid #E0DFE1',
          p: '16px 20px 24px 20px',
        },
      },
    },
    studentFare: {
      styles: {
        container: {
          display: 'flex',
          mt: '10px',
          mb: '28px',
          p: '10px',
          border: 'none',
          bgcolor: '#F6F6F6',
        },
        infoIcon: {
          alignSelf: 'flex-start',
          mt: '2px',
        },
      },
    },
    coinSwitch: {
      styles: {
        container: {
          bgcolor: 'secondary.main',
          p: '10px 16px',
        },
      },
    },
    sectionDivider: {
      borderBottomWidth: '4px',
      borderColor: '#F4F6F5',
      my: '20px',
    },
    somethingWentWrong: {
      container: {
        pb: '24px',
      },
    },
  },
  assets: {
    images: {
      headerBg: 'https://oa-flight-assets.s3.ap-south-1.amazonaws.com/offer/top-container.png',
      logo: 'https://oa-flight-assets.s3.ap-south-1.amazonaws.com/logo.png',
      icons: {
        back: { path: 'arrow-back.svg', folder: 'icons' },
        arrowRight: { path: 'arrow-right', folder: 'icons' },
        close: { path: 'close.svg', folder: 'icons' },
        search: { path: 'search.svg' },
        flightUnavailable: { path: 'flight-unavailable.svg' },
        coin: { path: '3xNiyo_coin.png' },
        secondaryEmptyAsset: { path: 'person-without-phone.svg' },
        primaryEmptyAsset: { path: 'person-with-phone.svg' },
        premiumSecondaryIcon: { path: 'premium-secondary-icon.svg', folder: 'offer' },
        luggageIcon: { path: 'luggage-icon.svg', folder: 'icons' },
        cabinIcon: { path: 'cabin-icon.svg', folder: 'icons' },
        mealPlaceholder: { path: 'meals-placeholder.svg' },
        baggagePlaceholder: { path: 'baggage-placeholder.svg' },
        cancellation: { path: 'cancellation.svg', folder: 'icons' },
        dateChange: { path: 'date-change.svg', folder: 'icons' },
        lineFlightCard: { path: 'line-flight-card-lg.svg' },
        locationDot: { path: 'location-dot.svg', folder: 'icons' },
        location: { path: 'location.svg', folder: 'icons' },
        copy: { path: 'copy.svg', folder: 'icons' },
        tick: { path: 'tick.svg', folder: 'icons' },
        percentPrimary: { path: 'percent-green.svg', folder: 'icons' },
        oneWayArrow: { path: 'one-way-arrow.svg', folder: 'icons' },
        twoWayArrow: { path: 'two-way-arrow.svg', folder: 'icons' },
        error: { path: 'error-icon.svg', folder: 'icons' },
        infoError: { path: 'info-icon-error.svg', folder: 'icons' },
        info: { path: 'info-icon.svg', folder: 'icons' },
        premiumGold: { path: 'premium-gold.svg', folder: 'offer' },
        premiumGoldLg: { path: 'premium-gold-lg.svg', folder: 'offer' },
        airplaneDuotone: { path: 'airplane-duotone.svg', folder: 'icons' },
        addLight: { path: 'add-white.svg', folder: 'icons' },
        addBlack: { path: 'add-black.svg', folder: 'icons' },
        airlineLogo: { path: '', folder: 'airline-logos' },
        airFrance: { path: 'air-france.svg', folder: 'icons' },
        slider: { path: 'sliderTiny.png', folder: 'icons' },
        edit: { path: 'edit.svg', folder: 'icons' },
        myTrips: { path: 'mytrips.svg' },
        flightTakeOff: { path: 'flight-take-off.svg', folder: 'icons' },
        flightLand: { path: 'flight-land.svg', folder: 'icons' },
        forwardIcon: { path: 'forward-icon.svg', folder: 'icons' },
        regularFare: { path: 'regular-fare.svg', folder: 'icons' },
        studentFare: { path: 'student-fare.svg', folder: 'icons' },
        person: { path: 'person.svg', folder: 'icons' },
        insertInvitation: { path: 'insert-invitation.svg', folder: 'icons' },
        minusDisabled: { path: 'minus-disabled.svg', folder: 'icons' },
        minusEnabled: { path: 'minus-enabled.svg', folder: 'icons' },
        plusEnabled: { path: 'plus-enabled.svg', folder: 'icons' },
        plusDisabled: { path: 'plus-disabled.svg', folder: 'icons' },
        chatbot: { path: 'chatbot.svg', folder: 'icons' },
        discountBg: { path: 'discount_bg.svg', folder: 'offer' },
        delight: { path: 'delight.svg', folder: 'offer' },
        copySecondary: { path: 'copy-secondary.svg', folder: 'icons' },
        esim: { path: 'esim.svg', folder: 'offer' },
        esimSecondary: { path: 'esim-post.png', folder: 'offer' },
        esimBanner: { path: 'esim-srp.svg', folder: 'offer' },
        clock: { path: 'clock.svg', folder: 'offer' },
        wave: { path: 'wave.svg', folder: 'icons' },
        tataAigInsurance: { path: 'tata-aig-insur.svg', folder: 'icons' },
        tataAigInsuranceSecondary: { path: 'tata-aig-logo2.png' },
        bottomWave: { path: 'bottomwave.svg', folder: 'icons' },
        moneyIcon: { path: 'money-icon.svg', folder: 'icons' },
        priceIncrease: { path: 'price-increase.svg', folder: 'icons' },
        priceDecrease: { path: 'price-decrease.svg', folder: 'icons' },
        lessPrice: { path: 'less-price.svg', folder: 'icons' },
        dropDown: { path: 'drop-down.svg', folder: 'icons' },
        dropUp: { path: 'drop-up.svg', folder: 'icons' },
        earlyMorning: { path: 'early-morning.svg', folder: 'icons' },
        morning: { path: 'morning.svg', folder: 'icons' },
        midday: { path: 'midday.svg', folder: 'icons' },
        evening: { path: 'evening.svg', folder: 'icons' },
        night: { path: 'night.svg', folder: 'icons' },
        companyExclusiveBanner: { path: 'niyo-exclusive.svg', folder: 'offer' },
        companyExclusiveSecondaryBanner: { path: 'niyo-exclusive-secondary.svg', folder: 'offer' },
        rightChevron: { path: 'right-chevron.svg', folder: 'icons' },
        convenienceGold: { path: 'niyo-convenience-gold.svg', folder: 'offer' },
        convenienceSilver: { path: 'niyo-convenience-silver.svg', folder: 'offer' },
        noConvenienceBanner: { path: 'no-convenience-fee.svg', folder: 'icons' },
      },
    },
  },
};
