import React, { useEffect } from 'react';
import { Box, Container, Divider, Skeleton, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from '../../../store';
import {
  setCancellationData,
  setSelectedCancelReason,
} from '../../../store/slices/cancellationSlice';
import { useCancellationInfoQuery } from '../../../services/bookingsApi';
import { OAEmpty } from '../../../components';
import { useTheme } from '../../../config/theme/useTheme';
import FlexItemsBetween from '../../../components/FlexItemsBetween';

const CancellationReasons = ({ bookingData }: any) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { data, isError } = useCancellationInfoQuery<any>(bookingData?.id, {
    skip: bookingData?.bookingStatus !== 'BOOKING_CONFIRMED',
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.data) {
      dispatch(setCancellationData(data.data));
    }
  }, [data, dispatch]);

  const handleSelectCancelReason = (reason: any) => {
    dispatch(setSelectedCancelReason(reason));
    navigate(`cancel?reason=${reason?.value}`);
  };

  const renderSkeletons = () => (
    <>
      {Array.from(new Array(4)).map((_, index) => (
        <FlexItemsBetween key={index} sx={theme.components.pages.bookingStatus.cancel.cancelReasons.skeleton}>
          <Skeleton variant="text" width="100%" height="30px" />
        </FlexItemsBetween>
      ))}
    </>
  );

  const renderError = () => <OAEmpty bodytext="Uh-oh..something went wrong" />;

  return (
    <Container sx={theme.components.pages.bookingStatus.cancel.cancelReasons.container}>
      {isError
        ? renderError()
        : !data?.data?.reasons
        ? renderSkeletons()
        : data.data.reasons.map((reason: any, index: number) => (
            <React.Fragment key={index}>
              <FlexItemsBetween
                sx={theme.components.pages.bookingStatus.cancel.cancelReasons.reasonContainer}
                onClick={() => handleSelectCancelReason(reason)}
              >
                <Typography>{reason.display}</Typography>
                <ChevronRightIcon
                  fontSize="small"
                  sx={theme.components.pages.bookingStatus.cancel.cancelReasons.icon}
                />
              </FlexItemsBetween>
              {index !== data.data.reasons.length - 1 && (
                <Divider sx={theme.components.pages.bookingStatus.cancel.cancelReasons.divider} />
              )}
            </React.Fragment>
          ))}
    </Container>
  );
};

export default CancellationReasons;
