import { FC } from 'react';
import { Box, SxProps, Typography } from '@mui/material';

import { OAImage } from '../../components';
import { useSelector } from '../../store';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { useTheme } from '../../config/theme/useTheme';
import FlexItems from '../../components/FlexItems';

interface CoinsBannerProps {
  title: string;
  subTitle: string;
  titleSx: SxProps;
  subTitleSx: SxProps;
}
const CoinsBanner: FC<CoinsBannerProps> = ({ title, subTitle, titleSx, subTitleSx }) => {
  const { premiumUserFeat } = useSelector(getFeatureFlag);
  const theme = useTheme();

  return (
    <Box sx={theme.components.pages.flight.coinsBanner.wrapper}>
      <Typography variant="h3" sx={{ ...titleSx }}>
        {title}
      </Typography>
      <Typography variant="body2" sx={{ ...subTitleSx }} display="flex" alignItems="center">
        {premiumUserFeat && (
          <OAImage
            src={theme.assets.images.icons.coin.path}
            alt="coins"
            sx={theme.components.pages.flight.coinsBanner.icon}
          />
        )}
        {subTitle}
      </Typography>
      {!premiumUserFeat && (
        <>
          <FlexItems sx={{ alignItems: 'baseline' }}>
            <Typography
              variant="body2"
              component="sup"
              sx={{ color: '#FFFFFF', fontSize: '0.7em', verticalAlign: 'super', ml: '2px' }}
            >
              *
            </Typography>
            <Typography variant="body2" component="span" sx={{ color: '#FFFFFF' }}>
              {theme.strings.flight.header.regular.disclaimer}
            </Typography>
          </FlexItems>
          <FlexItems sx={{ mt: '15px', mb: '10px' }}>
            <OAImage
              src={theme.assets.images.icons.coin.path}
              alt="coins"
              sx={{ mx: '5px', height: '18px', width: '18px' }}
            />
            <Typography variant="body2" sx={{ color: '#FFFFFF', ml: '4px' }}>
              {theme.strings.flight.header.regular.coinsText}
            </Typography>
          </FlexItems>
        </>
      )}
    </Box>
  );
};

export default CoinsBanner;
