import { FC, useState } from 'react';
import { Box, Card, Container, Divider, IconButton, Skeleton, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { OAFareSummary, OAImage, OASwipeableDrawer } from '../../../components';
import { formatPaymentMethod, formatToINR } from '../../../utils';
import { useDispatch, useSelector } from '../../../store';
import { getFeatureFlag } from '../../../store/slices/featuresSlice';
import { getUserInfo } from '../../../store/slices/userInfoSlice';
import { format } from 'date-fns';
import { setMessage } from '../../../store/slices/snackbarSlice';
import DiscountOfferBanner from './DiscountOfferBanner';
import FlexItemsBetween from '../../../components/FlexItemsBetween';
import { useTheme } from '../../../config/theme/useTheme';
import FlexItems from '../../../components/FlexItems';

interface PaymentAndInvoiceProps {
  order: any;
  isLoading: boolean;
}



const PaymentAndInvoice: FC<PaymentAndInvoiceProps> = ({ order, isLoading }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { esimOffer } = useSelector(getFeatureFlag);
  const orderAmount = order?.orderAmount;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleDrawer = () => setIsOpen((prev) => !prev);
  const { tags } = useSelector(getUserInfo);
  const addOnServices = orderAmount?.addOnServicesAmount?.addonServiceBreakUps?.find(
    (item: any) => item?.type === 'NIYO_PREMIUM_PLAN' || item?.type === 'NIYO_GOLD_PREMIUM_USER'
  );

  const discountedAmount = orderAmount?.discountTotalAmount?.totalDiscountAmount;
  const isCardDiscountAvailable = orderAmount?.discountTotalAmount?.discountBreakUps?.some(
    (discount: any) => discount?.type === 'CARD_FLAT_DISCOUNT'
  );

  const flatDiscount = orderAmount?.discountTotalAmount?.discountBreakUps?.find(
    (discount: any) => discount?.type === 'FLAT_DISCOUNT'
  );

 
  // Check if `order.metaTags` is an array before using `includes`
  const isOrderPurchasedAsAPremiumUser =
    Array.isArray(order?.metaTags) && order?.metaTags?.includes('NIYO_PREMIUM_PLAN');

  // Check if `tags` is an array before using `includes`
  const isPremiumUser =
    Array.isArray(tags) &&
    (tags?.includes('NIYO_GOLD_PREMIUM_USER') || tags?.includes('NIYO_SILVER_PREMIUM_USER'));

  const copyToClipboard = () => {
    const copyText = orderAmount?.paymentSummary?.transactionId;
    if (navigator?.clipboard?.writeText) {
      navigator.clipboard.writeText(copyText);
      dispatch(setMessage('Transaction ID has been copied to the clipboard.'));
    } else {
      const textArea = document.createElement('textarea');
      textArea.value = copyText;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        document.execCommand('copy');
        dispatch(setMessage('Transaction ID has been copied to the clipboard.'));
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }

      document.body.removeChild(textArea);
    }
  };

  return (
    <>
      <Container>
        <Typography variant="body1" sx={{ fontWeight: 600, mb: '16px' }}>
          Payment details
        </Typography>
        {orderAmount?.paymentSummary?.amountInCoins > 0 ? (
          <FlexItemsBetween
            sx={theme.components.pages.bookingStatus.paymentAndInvoice.coinsDetailsContainer}
          >
            <FlexItems>
              <Typography variant="body3" sx={{ fontWeight: 600, mr: '4px' }}>
                {formatToINR(orderAmount?.paymentSummary?.amountInCoins)}
              </Typography>
              <Typography variant="body3">saved on this trip</Typography>
            </FlexItems>

            <FlexItems>
              <Typography variant="body3" sx={{ mr: '4px' }}>
                using
              </Typography>
              <Typography variant="body3" sx={{ fontWeight: 600 }}>
                {formatToINR(orderAmount?.paymentSummary?.coinsRedeemed, false)}
              </Typography>
              <OAImage
                src="3xNiyo_coin.png"
                alt="coins"
                sx={theme.components.pages.bookingStatus.paymentAndInvoice.coinIcon}
              />
            </FlexItems>
          </FlexItemsBetween>
        ) : null}
        <Card sx={theme.components.pages.bookingStatus.paymentAndInvoice.paymentDetails.container}>
          <Typography variant="body2" color="text.secondary">
            Amount Paid
          </Typography>

          <Typography variant="h2" mt="5px" mb="10px">
            {orderAmount?.paymentSummary?.amountInCash ? (
              formatToINR(orderAmount?.paymentSummary?.amountInCash)
            ) : isLoading ? (
              <Skeleton variant="text" />
            ) : (
              '0'
            )}
          </Typography>
          {orderAmount?.paymentSummary?.payedAt && (
            <Typography component="p" variant="body2" mb="15px">
              Paid via {formatPaymentMethod(orderAmount?.paymentSummary?.paymentMode ?? '')} on{' '}
              {format(orderAmount?.paymentSummary.payedAt, 'do MMM yyyy • h:mm a')}
            </Typography>
          )}

          {orderAmount?.paymentSummary?.transactionId && (
            <Box>
              <Typography component="p" variant="body3" color="text.secondary">
                Transaction ID:
              </Typography>

              <Box
                sx={theme.components.pages.bookingStatus.paymentAndInvoice.paymentDetails.transaction}
              >
                <Typography
                  variant="body2"
                  sx={theme.components.pages.bookingStatus.paymentAndInvoice.paymentDetails.transactionText}
                >
                  {orderAmount?.paymentSummary?.transactionId}
                </Typography>
                <Box display="flex">
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={theme.components.pages.bookingStatus.paymentAndInvoice.paymentDetails.divider}
                  />
                  <IconButton
                    edge={false}
                    aria-label="back"
                    sx={{
                      minWidth: '36px',
                    }}
                    onClick={copyToClipboard}
                  >
                    <OAImage src="copy-secondary.svg" folder="icons" alt="copy" />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          )}
        </Card>
        <FlexItemsBetween
          sx={theme.components.pages.bookingStatus.paymentAndInvoice.paymentDetails.viewCta.container}
          onClick={toggleDrawer}
        >
          <Typography variant="body2">View payment details</Typography>
          <ChevronRightIcon fontSize="small" sx={theme.components.pages.bookingStatus.paymentAndInvoice.paymentDetails.viewCta.icon} />
        </FlexItemsBetween>
        {/* <Box display="flex" alignItems="center" justifyContent="space-between" py="8px">
          <Typography variant="body2">Download invoice</Typography>
          <ChevronRightIcon fontSize="small" sx={{ color: 'secondary.main', fontWeight: 300 }} />
        </Box> */}
      </Container>
      {(isCardDiscountAvailable || flatDiscount) && <DiscountOfferBanner 
        discountedAmount={discountedAmount} 
        discountBreakUps={orderAmount?.discountTotalAmount?.discountBreakUps} 
        orderAmount={orderAmount}
      />}
      {order?.international && esimOffer && (
        <Box sx={theme.components.pages.bookingStatus.paymentAndInvoice.paymentDetails.offerBanner.container}>
          <OAImage src="esim-post.png" folder="offer" alt="esim" width="100%" />
        </Box>
      )}
      <Divider sx={theme.components.pages.bookingStatus.paymentAndInvoice.divider} />
      <OASwipeableDrawer
        title="Payment details"
        open={isOpen}
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
        isContainer={false}
      >
        <OAFareSummary
          data={order}
          isPremiumUser={addOnServices || isOrderPurchasedAsAPremiumUser || isPremiumUser}
          premiumPaidDetails={addOnServices}
        />
      </OASwipeableDrawer>
    </>
  );
};

export default PaymentAndInvoice;
