import { ThemeConfig } from '../types';
import { defaultTheme } from '../themes/default';

export const niyoTheme: ThemeConfig = {
  ...defaultTheme,
  name: 'niyo',
  colors: {
    ...defaultTheme.colors,
    primary: '#1A237E',
    secondary: '#0D47A1',
  },
  components: {
    ...defaultTheme.components,
    header: {
      ...defaultTheme.components.header,
      styles: {
        ...defaultTheme.components.header.styles,
        home: {
          ...defaultTheme.components.header.styles.home,
          backgroundImage: 'url(https://oa-flight-assets.s3.ap-south-1.amazonaws.com/offer/top-container.png)'
        }
      }
    },
    backBtn: defaultTheme.components.backBtn,
    searchAirport: {
      ...defaultTheme.components.searchAirport,
      styles: {
        ...defaultTheme.components.searchAirport.styles, 
      }
    },
    airportSuggestions: {
      ...defaultTheme.components.airportSuggestions,
      styles: {
        ...defaultTheme.components.airportSuggestions.styles,
        container: {
          ...defaultTheme.components.airportSuggestions.styles.container,
        },
        autoCompleteContainer: {
          ...defaultTheme.components.airportSuggestions.styles.autoCompleteContainer
        }
      }
    }
  }
};
