import { useCallback, useEffect, useState } from 'react';
import { Box, Card, List, ListItem, ListItemText, Typography } from '@mui/material';
import SearchAirports from './SearchAirports';
import { OAButton, OAChip, OAEmpty, OAImage, OASwipeableDrawer } from '../../components';
import SelectTravellers from './SelectTravelllers';
import { useDispatch, useSelector } from '../../store';
import { getUserInputState } from '../../store/slices/userInputSlice';
import { setTravelDetail } from '../../store/slices/userInputSlice';
import { useNavigate } from 'react-router-dom';
import TravelDate from './TravelDate';
import { isFuture, isToday, parseISO } from 'date-fns';
import { getAuthInfo } from '../../store/slices/authSlice';
import { setFilter, setFlightPrices, setSort } from '../../store/slices/searchFlightSlice';
import { eventsTracker } from '../../utils/ctEventsTracking';
import OASwitchComponent from '../../components/OASwitchComponent';
import { usePostHog } from 'posthog-js/react';
import TabSwitchSkeleton from '../SearchResult/TabSwitchSkeleton';
import { setMessage } from '../../store/slices/snackbarSlice';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { setPlanDetals } from '../../store/slices/userInfoSlice';
import { useGetPremiumPlansQuery } from '../../services/bookingsApi';
import { useLazyNearbyAirportQuery } from '../../services/airportDataApi';
import { useGetFarePriceMutation } from '../../services/flightSearchApi';
import FlexItemsCentered from '../../components/FlexItemsCentered';
import { useTheme } from '../../config/theme/useTheme';
import FlexItems from '../../components/FlexItems';

const SearchParameters = ({
  isEditing = false,
  toggleDrawer,
  shouldFetchLocations = false,
  isEditDrawerOpen,
  isUpdatingResults,
  setCurrentDirection,
  setFilterData,
  filterData,
  setIsResultsLoading,
  setScrollTop,
}: any) => {
  const { data } = useGetPremiumPlansQuery<any>({});
  const [getFarePrice] = useGetFarePriceMutation();
  const theme = useTheme();
  useEffect(() => {
    dispatch(setPlanDetals(data?.data));
  }, [data?.data?.length > 0]);

  const posthog = usePostHog();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { studentFareFeat } = useSelector(getFeatureFlag);
  const [fetchNearbyAirport] = useLazyNearbyAirportQuery();
  const { travelDetail } = useSelector<any>(getUserInputState);
  const { user, token } = useSelector(getAuthInfo);
  const { premiumUserFeat } = useSelector(getFeatureFlag);
  const [startTime, setStartTime] = useState(Date.now());
  const [returnTime, setReturnTime] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [startEditingTime, setStartEditingTime] = useState(Date.now());
  const [hasSeenStudentFareDrawer, setHasSeenStudentFareDrawer] = useState<boolean>(false);
  const [isStudentFareDrawerOpen, setIsStudentFareDrawerOpen] = useState<boolean>(false);

  const [localTravelDetail, setLocalTravelDetail] = useState<any>({
    from: {
      iata: travelDetail?.from?.iata ?? null,
      city: travelDetail?.from?.city ?? null,
      name: travelDetail?.from?.name ?? null,
      country: travelDetail?.from?.country ?? null,
    },
    to: {
      iata: travelDetail?.to?.iata ?? null,
      city: travelDetail?.to?.city ?? null,
      name: travelDetail?.to?.name ?? null,
      country: travelDetail?.to?.country ?? null,
    },
    tripStart: {
      date: travelDetail?.tripStart?.date ?? null,
    },
    tripEnd: {
      date: travelDetail?.tripEnd?.date ?? null,
    },
    cabinType: travelDetail?.cabinType ?? '',
    travellerCount: {
      adult: travelDetail?.travellerCount?.adult ?? 0,
      child: travelDetail?.travellerCount?.child ?? 0,
      infant: travelDetail?.travellerCount?.infant ?? 0,
    },
    tripType: travelDetail?.tripType ?? 'O',
    fareGroup: travelDetail?.fareGroup ?? 'REGULAR',
    isInternational: travelDetail?.isInternational ?? false,
  });

  const toggleLabels = ['One Way', 'Round Trip'];

  const handleSwapLocation = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    setLocalTravelDetail({
      ...localTravelDetail,
      from: localTravelDetail?.to,
      to: localTravelDetail?.from,
    });
  };

  const isValidRequest = (td: any): boolean => {
    // Check for required fields
    if (
      !td.tripStart?.date ||
      !td.cabinType ||
      !td.from?.iata ||
      !td.to?.iata ||
      !td.travellerCount?.adult
    ) {
      dispatch(setMessage('Field is missing!'));
      return false;
    }

    // Check if the trip start date is today or in the future
    const dateToCheck = parseISO(td.tripStart.date);
    if (!(isToday(dateToCheck) || isFuture(dateToCheck))) {
      dispatch(setMessage('Date should be today or future'));
      return false;
    }

    // Check if 'from' and 'to' iatas are the same
    if (td.from.iata === td.to.iata) {
      dispatch(
        setMessage({
          message: 'Departure and arrival cities must be different',
          severity: 'error',
        })
      );
      return false;
    }

    if (
      td.fareGroup === 'STUDENT' &&
      (td.travellerCount?.child > 0 || td.travellerCount?.infant > 0)
    ) {
      dispatch(setMessage('Student fares are available for adult passengers only'));
      return false;
    }

    // If all checks pass
    return true;
  };

  const handleSetTravelDetail = (updatedDetails: any) => {
    setLocalTravelDetail((prevState: any) => ({
      ...prevState,
      ...updatedDetails,
    }));
  };

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  useEffect(() => {
    if (isEditDrawerOpen) {
      setStartEditingTime(Date.now());
    }
  }, [isEditDrawerOpen]);

  const onSearch = () => {
    const isProduction = window.location.href.includes('goniyo.com');
    if (isProduction && window.flutter_inappwebview) {
      posthog.startSessionRecording();
    }

    setIsResultsLoading && localTravelDetail !== travelDetail && setIsResultsLoading(true);

    if (filterData) {
      setFilterData(null);
    }
    if (isEditing) {
      setCurrentDirection(0);
    }
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    const totalEditingDuration = Math.floor((Date.now() - startEditingTime) / 1000);

    const totalTravelers =
      localTravelDetail?.travellerCount?.adult +
      localTravelDetail?.travellerCount?.child +
      localTravelDetail?.travellerCount?.infant;

    const isDomestic = localTravelDetail?.from?.country === localTravelDetail?.to?.country;
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: isEditing ? 'SRP' : 'Home',
        ctaAction: isEditing ? 'Edit' : 'Search',
        screenDuration: isEditing
          ? totalEditingDuration?.toString()
          : totalScreenDuration?.toString(),
        otherData: {
          from: localTravelDetail?.from?.iata,
          to: localTravelDetail?.to?.iata,
          departureDate: localTravelDetail?.tripStart?.date,
          class: localTravelDetail?.cabinType,
          numberOfTravellers: totalTravelers,
          destination: isDomestic ? 'domestic' : 'international',
        },
      },
      posthog
    );
    if (!isValidRequest(localTravelDetail)) {
      return;
    }

    const draft = {
      cabinType: localTravelDetail?.cabinType,
      travellerCount: {
        adult: 1,
        child: 0,
        infant: 0,
      },
      from: localTravelDetail?.from,
      to: localTravelDetail?.to,
      isReturn: false,
    };

    getFarePrice(draft).then((res: any) => {
      dispatch(setFlightPrices(res?.data?.data?.results));
    });

    dispatch(setTravelDetail(localTravelDetail));
    const tempTraveller = localTravelDetail?.travellerCount;
    const nullToZero = (value: any) => (value === null || value === undefined ? 0 : value);
    const formattedString = `adults=${nullToZero(tempTraveller.adult)}&childs=${nullToZero(
      tempTraveller.child
    )}&infants=${nullToZero(tempTraveller.infant)}`;
    const tripType = localTravelDetail?.tripEnd?.date ? 'R' : 'O';
    const cabinType = localTravelDetail?.cabinType;
    const traveller = formattedString;

    const fromTo =
      `${localTravelDetail?.from?.iata}` +
      `&fromCity=${localTravelDetail?.from?.city}` +
      `&to=${localTravelDetail?.to?.iata}` +
      `&toCity=${localTravelDetail?.to?.city}` +
      `&fromDate=${localTravelDetail?.tripStart?.date}` +
      `${localTravelDetail?.tripEnd?.date ? `&toDate=${localTravelDetail?.tripEnd?.date}` : ''}` +
      `${localTravelDetail?.fareGroup ? `&fareGroup=${localTravelDetail?.fareGroup}` : ''}`;

    const baseURL = isEditing ? `` : `results`;
    const searchURL = `${baseURL}?tripType=${tripType}&cabin=${cabinType}&${traveller}&from=${fromTo}`;
    // Clear filter & sort
    dispatch(setSort('plh'));
    dispatch(setFilter(null));
    navigate(searchURL);
    if (toggleDrawer) {
      toggleDrawer();
    }
  };

  useEffect(() => {
    if (shouldFetchLocations && travelDetail?.from?.iata === null) {
      fetchNearbyAirport({})
        .unwrap()
        .then((data) => {
          setLocalTravelDetail((prevDetail: any) => ({
            ...prevDetail,
            from: data?.data?.from,
            to: data?.data?.to,
          }));
        })
        .catch((error) => {
          console.error('Error fetching nearby airport:', error);
        });
    }
  }, [shouldFetchLocations]);

  useEffect(() => {
    const hasSeenDrawer = sessionStorage.getItem('hasSeenStudentFareDrawer');
    if (hasSeenDrawer) {
      setHasSeenStudentFareDrawer(true);
    }
  }, []);

  const toggleStudentFareDrawer = () => {
    if (!hasSeenStudentFareDrawer) {
      setIsStudentFareDrawerOpen(true);
      setHasSeenStudentFareDrawer(true);
      sessionStorage.setItem('hasSeenStudentFareDrawer', 'true');
    }
  };

  useEffect(() => {
    if (travelDetail?.from?.iata && travelDetail?.to?.iata) {
      setLocalTravelDetail((prevDetail: any) => ({
        ...prevDetail,
        from: {
          iata: travelDetail?.from?.iata,
          city: travelDetail?.from?.city,
          name: travelDetail?.from?.name,
          country: travelDetail?.from?.country,
        },
        to: {
          iata: travelDetail?.to?.iata,
          city: travelDetail?.to?.city,
          name: travelDetail?.to?.name,
          country: travelDetail?.to?.country,
        },
      }));
    }
  }, [travelDetail?.from, travelDetail?.to]);

  const handleChange = useCallback(
    (newValue: number) => {
      const tempValue = newValue === 0 ? 'O' : 'R';
      let updatedDate = {};

      const dateObject = new Date(localTravelDetail?.tripStart?.date);
      dateObject.setDate(dateObject.getDate() + 1);
      let endDate = dateObject.toISOString();

      if (tempValue === 'O') {
        setReturnTime(
          localTravelDetail?.tripEnd?.date
            ? new Date(localTravelDetail?.tripEnd?.date).toISOString()
            : null
        );
        updatedDate = {
          tripStart: {
            date: localTravelDetail?.tripStart?.date
              ? new Date(localTravelDetail?.tripStart?.date).toISOString()
              : null,
          },
          tripEnd: {
            date: null,
          },
        };
      } else {
        updatedDate = {
          tripStart: {
            date: localTravelDetail?.tripStart?.date
              ? new Date(localTravelDetail?.tripStart?.date).toISOString()
              : null,
          },
          tripEnd: {
            date: isEditing
              ? (returnTime ?? endDate)
              : travelDetail?.tripEnd?.date
                ? new Date(travelDetail?.tripEnd?.date).toISOString()
                : localTravelDetail?.tripEnd?.date
                  ? new Date(localTravelDetail?.tripEnd?.date).toISOString()
                  : (returnTime ?? endDate),
          },
        };
      }

      const updatedTravelDetail: any = {
        ...localTravelDetail,
        tripType: tempValue,
        ...updatedDate,
      };
      setLocalTravelDetail(updatedTravelDetail);
    },
    [localTravelDetail]
  );

  useEffect(() => {
    setLocalTravelDetail(travelDetail);
  }, [isEditDrawerOpen]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2);

    // cleanup function to clear timeout if component unmounts
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    setLocalTravelDetail(travelDetail);
  }, [isEditDrawerOpen]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2);

    // cleanup function to clear timeout if component unmounts
    return () => clearTimeout(timer);
  }, []);

  const handleFareGroup = () => {
    setLocalTravelDetail((prev: any) => ({
      ...prev,
      fareGroup: prev.fareGroup === 'REGULAR' ? 'STUDENT' : 'REGULAR',
    }));
    toggleStudentFareDrawer();
  };

  return (
    <Box>
      {token ? (
        <>
          <Box sx={{ pt: !isEditing ? '20px' : '0px' }}>
            {loading ? (
              <TabSwitchSkeleton />
            ) : (
              <OASwitchComponent
                value={localTravelDetail?.tripType === 'O' ? 0 : 1}
                onChange={handleChange}
                labels={toggleLabels}
                isLoading={loading}
                sx={{
                  customTabs: {
                    ...theme?.components?.customTabs?.styles?.tabs
                  },
                  customTab: {
                    ...theme?.components?.customTabs?.styles?.tab
                  }
                }}
              />
            )}
          </Box>
          <FlexItems sx={theme?.components?.pages?.flight?.searchParameters?.searchAirport?.container}>
            <SearchAirports
              label="From"
              title="Select Departure"
              fieldId="departure"
              value={
                localTravelDetail?.from?.iata
                  ? `${localTravelDetail?.from?.iata}${
                      localTravelDetail?.from?.city ? ` - ${localTravelDetail?.from?.city}` : ''
                    }${localTravelDetail?.from?.name ? ` - ${localTravelDetail.from?.name}` : ''}`
                  : ''
              }
              localTravelDetail={localTravelDetail}
              onSelect={handleSetTravelDetail}
              setScrollTop={setScrollTop}
            />
            <FlexItemsCentered sx={theme?.components?.swapper?.styles?.default} onClick={handleSwapLocation}>
              <OAImage src="forward-icon.svg" alt="Swap Vert" folder="icons" />
            </FlexItemsCentered>
            <SearchAirports
              label="To"
              title="Select Arrival"
              fieldId="to"
              value={
                localTravelDetail?.to?.iata
                  ? `${localTravelDetail.to.iata}${
                      localTravelDetail?.to?.city ? ` - ${localTravelDetail?.to?.city}` : ''
                    }${localTravelDetail?.to?.name ? ` - ${localTravelDetail?.to?.name}` : ''}`
                  : ''
              }
              onSelect={handleSetTravelDetail}
              localTravelDetail={localTravelDetail}
              setScrollTop={setScrollTop}
            />
          </FlexItems>
          <Box sx={{ mt: '20px' }}>
            <TravelDate
              localTravelDetail={localTravelDetail}
              setLocalTravelDetail={setLocalTravelDetail}
              onSelect={handleSetTravelDetail}
              setCurrentDirection={setCurrentDirection}
            />
          </Box>
          <Box sx={{ mt: '20px' }}>
            <SelectTravellers
              localTravelDetail={localTravelDetail}
              onSelect={handleSetTravelDetail}
            />
          </Box>
          {studentFareFeat && (
            <Box mt="24px">
              <OAChip
                label="Regular"
                icon={
                  <OAImage
                    src={'regular-fare.svg'}
                    alt="regular"
                    folder="icons"
                    sx={{ mr: '10px' }}
                  />
                }
                onClick={handleFareGroup}
                selected={localTravelDetail?.fareGroup === 'REGULAR'}
              />
              <OAChip
                label="Student"
                icon={
                  <OAImage
                    src={'student-fare.svg'}
                    folder="icons"
                    alt="student"
                    sx={{ mr: '10px' }}
                  />
                }
                sx={{ ml: '10px' }}
                selected={localTravelDetail?.fareGroup === 'STUDENT'}
                onClick={handleFareGroup}
              />
            </Box>
          )}
          {premiumUserFeat && (
            <FlexItemsCentered sx={{ mt: '30px' }}>
              <OAImage src="premium-gold.svg" folder="offer" alt="premium-gold" />
              <Typography display="flex" alignItems="center" variant="body2">
                ₹0 convenience fee with{' '}
                <Typography variant="body2" color="#B79009" ml="4px">
                  Niyo Premium
                </Typography>
              </Typography>
            </FlexItemsCentered>
          )}
          <OAButton
            fullWidth
            variant="contained"
            color="secondary"
            onClick={onSearch}
            sx={{ mt: '15px' }}
            disabled={!token && !user?.id}
          >
            Search Flights
          </OAButton>
        </>
      ) : (
        <FlexItemsCentered sx={{ minHeight: '360px' }}>
          <OAEmpty
            illustration={<OAImage src="flight-unavailable.svg" alt="unavailable" />}
            bodytext="Flight services are temporarily unavailable"
            bodySubtext="Please reach out to our support team for assistance & swift resolution"
          />
        </FlexItemsCentered>
      )}
      <OASwipeableDrawer
        title="Student special fares"
        open={isStudentFareDrawerOpen}
        onOpen={toggleStudentFareDrawer}
        onClose={() => setIsStudentFareDrawerOpen(false)}
      >
        <Box>
          <Typography fontWeight={600} mb="10px">
            {theme?.strings?.flight?.studentFare?.title}
          </Typography>
          <List component="ul" sx={{ listStyleType: 'disc', pl: 4, py: 0 }}>
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <ListItemText
                primary={
                  <Typography variant="body3" sx={{ color: 'text.secondary' }}>
                    {theme?.strings?.flight?.studentFare?.text}
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <Card
            sx={theme?.components?.studentFare?.styles?.container}
          >
            <OAImage
              src="info-icon.svg"
              folder="icons"
              alt="info"
              sx={theme?.components?.studentFare?.styles?.infoIcon}
            />
            <Typography ml="10px" variant="body3">
              {theme?.strings?.flight?.studentFare?.subText}
            </Typography>
          </Card>
        </Box>
      </OASwipeableDrawer>
    </Box>
  );
};

export default SearchParameters;
