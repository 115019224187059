import { useEffect, useRef, useState } from 'react';
import { Box, Container, MenuItem, Stack, Typography } from '@mui/material';
import {
  OAButton,
  OAChip,
  OAHeader,
  OAHeaderTitle,
  OALoadingOverlay,
  OASwipeableDrawer,
  OATextField,
} from '../../components';
import MaxCharacterCount from './MaxCharacterCount';
import useKeyboardVisibility from '../../hooks/useKeyboardVisibility';
import { GENDER, NATIONALITY_OPTIONS, TRAVELLER_NAME_CHARACTER_LIMIT } from '../../constants';
import { formatDate, formatFieldName, validateFieldOnBlur, validateFieldOnChange } from './helper';
import { useSelector } from '../../store';
import { getTravellerState } from '../../store/slices/travellerSlice';
import { format, parse } from 'date-fns';
import { getFormatedDate } from '../../utils';
import { getFlightOrderState } from '../../store/slices/flightOrderSlice';
import { useTraveller } from '../../hooks/useTraveller';

const AddEditTraveller = () => {
  const isKeyboardVisible = useKeyboardVisibility();
  const { handleUpdateTraveller, handleAddTraveller, isSubmitting } = useTraveller();
  const inputRef = useRef<any>(null);

  const { travellerToEdit } = useSelector(getTravellerState);
  const { flightData } = useSelector(getFlightOrderState);
  const isEdit = travellerToEdit?.id ? true : false;
  const [fields, setFields] = useState({
    gender: travellerToEdit?.gender || '',
    firstName: travellerToEdit?.firstName || '',
    lastName: travellerToEdit?.lastName || '',
    dob: travellerToEdit?.dateOfBirth ? format(travellerToEdit?.dateOfBirth, 'dd/MM/yyyy') : '',
    ...(flightData?.international && {
      passportNumber: travellerToEdit?.passport?.number || '',
      passportIssueDate:
        getFormatedDate(travellerToEdit?.passport?.issuingDate, 'dd/MM/yyyy') || '',
      passportExpiry: getFormatedDate(travellerToEdit?.passport?.expiration, 'dd/MM/yyyy') || '',
      nationality: travellerToEdit?.nationality || 'IN',
    }),
    ...(flightData?.flightSearchInfo?.fareGroup === 'STUDENT' && {
      studentId: travellerToEdit?.studentId || '',
    }),
  });
  const [errors, setErrors] = useState({
    gender: '',
    firstName: '',
    lastName: '',
    dob: '',
    passportNumber: '',
    passportIssueDate: '',
    passportExpiry: '',
    nationality: '',
    studentId: '',
  });
  const [isNationalityDrawerOpen, setIsNationalityDrawerOpen] = useState<boolean>(false);
  // Format raw input (digits only) into dd/mm/yyyy format

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    let error = '';
    let newValue = value;
    // Clear previous error for this field
    setErrors((prev) => ({ ...prev, [name]: '' }));
    if (['passportIssueDate', 'passportExpiry', 'dob'].includes(name)) {
      const formatted = formatDate(value);
      newValue = formatted;
      setFields((prev: any) => ({ ...prev, [name]: newValue }));
      error = validateFieldOnChange(name, formatted, true);
    } else {
      error = validateFieldOnChange(name, value, true);
    }

    if (error) {
      setErrors((prev) => ({ ...prev, [name]: error }));
    } else {
      setFields((prev: any) => ({ ...prev, [name]: newValue }));
    }
  };

  const handleInputBlur = (e: any) => {
    const { name, value } = e.target;
    const error = validateFieldOnBlur(name, value);
    if (error) {
      setErrors((prev) => ({ ...prev, [name]: error }));
    }
  };

  // onKeyDown handler for date fields to handle backspace removal of slashes
  const handleDateKeyDown = (e: any) => {
    if (e.key === 'Backspace') {
      const { selectionStart, selectionEnd, value, name } = e.target;
      // Check if there is no text selection and the caret is not at the beginning
      if (selectionStart === selectionEnd && selectionStart > 0) {
        if (value[selectionStart - 1] === '/') {
          e.preventDefault(); // prevent the default backspace
          const newValue = value.slice(0, selectionStart - 1) + value.slice(selectionStart);
          setFields((prev: any) => ({ ...prev, [name]: newValue }));

          // Optionally, adjust the caret position after the update
          // This requires a ref to the input element:
          if (inputRef.current) {
            // Setting caret position using setTimeout ensures the update happens after the state change
            setTimeout(() => {
              inputRef.current.setSelectionRange(selectionStart - 1, selectionStart - 1);
            }, 0);
          }
        }
      }
    }
  };

  const validateFields = () => {
    const newErrors: { [key: string]: string } = {};
    const fieldsTemp: any = { ...fields };

    Object.keys(fieldsTemp).forEach((field) => {
      //Check if the field value is empty (you may use trim() for strings)
      if (!fieldsTemp[field]?.toString().trim()) {
        newErrors[field] = `${formatFieldName(field)} is required field`;
      }
    });

    setErrors((prev) => ({
      ...prev,
      ...newErrors,
    }));
    // setErrors(newErrors);
    // Return a boolean to indicate if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const onFocusHandler = (e: any) => {
    //later we will remove this
    console.log('Testing onFocusHandler');
  };

  useEffect(() => {
    if (isKeyboardVisible) {
      const activeElement = document.activeElement;
      if (activeElement) {
        setTimeout(() => {
          activeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 150);
      }
    }
  }, [isKeyboardVisible]);

  const handleNationalityChange = (country: string) => {
    setFields((prev: any) => ({ ...prev, nationality: country }));
    setIsNationalityDrawerOpen(false);
  };

  const handleSubmit = (e: any) => {
    if (validateFields()) {
      const hasError = Object.values(errors).some((value) => value !== '');
      if (hasError) {
        console.log('Errors are present');
      } else {
        let request: any = {
          firstName: fields?.firstName,
          lastName: fields?.lastName,
          dateOfBirth: getFormatedDate(parse(fields?.dob, 'dd/MM/yyyy', new Date()), 'yyyy-MM-dd'),
          gender: fields?.gender,
          residentCountry: 'IN',
          countryCode: 'IN',
          nationality: fields.nationality,
          ...(flightData?.flightSearchInfo?.fareGroup === 'STUDENT' && {
            studentId: fields?.studentId,
          }),
        };

        if (flightData?.international) {
          request = {
            ...request,
            passport: {
              number: fields?.passportNumber,
              issuingCountry: 'IN',
              expiration: getFormatedDate(
                parse(fields?.passportExpiry, 'dd/MM/yyyy', new Date()),
                'yyyy-MM-dd'
              ),
              issuingDate: getFormatedDate(
                parse(fields?.passportIssueDate, 'dd/MM/yyyy', new Date()),
                'yyyy-MM-dd'
              ),
            },
          };
        }

        if (travellerToEdit?.id) {
          handleUpdateTraveller({ id: travellerToEdit?.id, ...request });
        } else {
          handleAddTraveller(request);
        }
      }
    } else {
      console.log('falied to submit');
    }

    e.preventDefault();
  };

  return (
    <>
      {isSubmitting && <OALoadingOverlay />}
      <Box
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 100,
          bgcolor: 'background.paper',
          width: '100%',
        }}
      >
        <OAHeader title={`${isEdit ? 'Edit' : 'Add'} traveller`} />
      </Box>
      <Container
        sx={{
          mb: isKeyboardVisible ? '200px' : '24px',
          mt: '24px',
        }}
      >
        <form onSubmit={handleSubmit} noValidate>
          <Stack spacing={3}>
            <Box mb="20px" display="flex" flexDirection="column">
              <Box>
                {GENDER.map((title) => (
                  <OAChip
                    key={title}
                    label={title.toLowerCase()}
                    selected={fields.gender === title}
                    onClick={() => {
                      setFields((prev: any) => ({ ...prev, gender: title }));
                      setErrors((prev) => ({ ...prev, gender: '' }));
                    }}
                    sx={{ textTransform: 'capitalize', mr: '8px' }}
                  />
                ))}
              </Box>
              {errors.gender && (
                <Typography mt="3px" ml="14px" variant="body3" color="#C01100">
                  {errors.gender}
                </Typography>
              )}
            </Box>
            <Box position={'relative'}>
              <OATextField
                name="firstName"
                label="First & middle name"
                value={fields.firstName}
                inputProps={{
                  minLength: 2,
                  maxLength: TRAVELLER_NAME_CHARACTER_LIMIT,
                  pattern: '^[A-Za-z ]*$',
                }}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                error={!!errors.firstName}
                helperText={
                  errors.firstName !== ''
                    ? errors.firstName
                    : 'First & middle name should be same as on your ID'
                }
                fullWidth
              />
              <MaxCharacterCount charLength={fields.firstName?.length ?? 0} />
            </Box>
            <Box position={'relative'}>
              <OATextField
                name="lastName"
                label="Last name"
                value={fields.lastName}
                inputProps={{ maxLength: TRAVELLER_NAME_CHARACTER_LIMIT }}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                error={!!errors.lastName}
                helperText={
                  errors.lastName !== ''
                    ? errors.lastName
                    : 'Last name should be same as on your ID'
                }
                fullWidth
              />
              <MaxCharacterCount charLength={fields.lastName?.length ?? 0} />
            </Box>

            <OATextField
              name="dob"
              label="Date of birth"
              value={fields.dob}
              inputProps={{
                maxLength: 10,
                inputMode: 'numeric',
                placeholder: 'dd/mm/yyyy',
              }}
              onChange={handleInputChange}
              onBlur={handleInputBlur}
              onKeyDown={handleDateKeyDown}
              error={!!errors.dob}
              helperText={errors.dob}
              fullWidth
            />
            {flightData?.international && (
              <>
                <OATextField
                  name="passportNumber"
                  label="Passport number"
                  value={fields.passportNumber}
                  inputProps={{
                    minLength: 2,
                    maxLength: TRAVELLER_NAME_CHARACTER_LIMIT,
                    pattern: '^[A-Za-z0-9]*$',
                  }}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  error={!!errors.passportNumber}
                  helperText={errors.passportNumber}
                  fullWidth
                />

                <OATextField
                  name="passportIssueDate"
                  label="Passport issue date"
                  value={fields.passportIssueDate}
                  inputProps={{
                    maxLength: 10,
                    inputMode: 'numeric',
                    placeholder: 'dd/mm/yyyy',
                    onFocus: onFocusHandler,
                  }}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  onKeyDown={handleDateKeyDown}
                  error={!!errors.passportIssueDate}
                  helperText={errors.passportIssueDate}
                  fullWidth
                />
                <OATextField
                  name="passportExpiry"
                  label="Passport expiry date"
                  value={fields.passportExpiry}
                  inputProps={{
                    maxLength: 10,
                    inputMode: 'numeric',
                    placeholder: 'dd/mm/yyyy',
                    onFocus: onFocusHandler,
                  }}
                  onChange={handleInputChange}
                  onBlur={handleInputBlur}
                  onKeyDown={handleDateKeyDown}
                  error={!!errors.passportExpiry}
                  helperText={errors.passportExpiry}
                  fullWidth
                />
                <OATextField
                  name="nationality"
                  label="Nationality"
                  value={
                    NATIONALITY_OPTIONS.find((c) => c.value === fields.nationality)?.label ||
                    'India'
                  }
                  inputProps={{
                    readOnly: true,
                    onFocus: onFocusHandler,
                  }}
                  onClick={() => setIsNationalityDrawerOpen(true)}
                  error={!!errors.nationality}
                  helperText={errors.nationality}
                  fullWidth
                />
              </>
            )}
            {flightData?.flightSearchInfo?.fareGroup === 'STUDENT' && (
              <OATextField
                name="studentId"
                label="Student Id"
                value={fields.studentId}
                onChange={handleInputChange}
                error={!!errors.studentId}
                helperText={errors.studentId}
                onFocus={onFocusHandler}
                fullWidth
              />
            )}
          </Stack>
          <OAButton
            fullWidth
            variant="contained"
            color="secondary"
            sx={{ my: '24px' }}
            type="submit"
            disabled={isSubmitting}
          >
            {isEdit ? 'Save' : 'Add'} Traveller
          </OAButton>
        </form>
      </Container>
      <OASwipeableDrawer
        open={isNationalityDrawerOpen}
        onClose={() => setIsNationalityDrawerOpen(false)}
        onOpen={() => setIsNationalityDrawerOpen(true)}
        title="Nationality"
      >
        {NATIONALITY_OPTIONS?.map((country: any) => (
          <MenuItem
            key={country?.value}
            selected={fields?.nationality === country?.value}
            onClick={() => handleNationalityChange(country?.value)}
          >
            {country?.label}
          </MenuItem>
        ))}
      </OASwipeableDrawer>
    </>
  );
};

export default AddEditTraveller;
