import { ThemeConfig } from './types';
import { defaultTheme } from './themes/default';
import { niyoTheme } from './variants/niyo';
import { zolveTheme } from './variants/zolve/zolve';
import { unicardsTheme } from './variants/unicards/unicards';

export const getThemeByName = (name: 'default' | 'niyo' | 'zolve' | 'unicards'): ThemeConfig => {
  switch (name) {
    case 'niyo':
      return niyoTheme;
    case 'zolve':
      return zolveTheme;
    case 'unicards':
      return unicardsTheme;
    default:
      return defaultTheme;
  }
};
