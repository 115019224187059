import { Box, SxProps } from "@mui/material"

const FlexItemsBetween = ({ 
  children, 
  sx, 
  onClick, 
  id 
}: { 
  children: React.ReactNode, 
  sx?: SxProps, 
  onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void, 
  id?: string 
}) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" sx={sx} onClick={onClick} id={id}>
      {children}
    </Box>
  )
}

export default FlexItemsBetween;