import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyGetCampaignsQuery } from '../services/authApi';
import { userLogin } from '../utils/userLogin';
import { compareVersions, isTestEmailForProd, parseObject } from '../utils';
import { initializeWindowFunctions } from '../windowFunctions';
import { setCampaigns, setDeviceInfo } from '../store/slices/authSlice';
import {
  setSmbFeat,
  setPremiumUserFeat,
  setConvenienceFeeTag,
  setStudentFareFeat,
} from '../store/slices/featuresSlice';
import { setTags } from '../store/slices/userInfoSlice';
import { useLazyGetOffersQuery } from '../services/offersApi';
import { setOffersData } from '../store/slices/offersSlice';

interface UserData {
  userId?: string;
  id?: string;
  email?: string;
  tags?: string[];
  appVersion?: string;
}

interface DeviceInfo {
  [key: string]: any;
}

const PREMIUM_USER_TAGS = [
  'NIYO_GOLD_PREMIUM_USER',
  'NIYO_PREMIUM_USER',
  'NIYO_SILVER_PREMIUM_USER',
];

export const useInitialization = () => {
  const params = new URLSearchParams(window.location.search);
  const [trigger] = useLazyGetCampaignsQuery({});
  const [triggerOffers] = useLazyGetOffersQuery({});
  const dispatch = useDispatch();
  const token = params.get('token') ?? process.env.REACT_APP_TEST_TOKEN;
  const [isInitialized, setIsInitialized] = useState(false);

  const handleOffers = async (userData: UserData) => {
    try {
      const userId = userData.userId || userData.id;
      if (!userId) return;

      const result = await triggerOffers(userId).unwrap();
      if (result?.data) {
        dispatch(setOffersData(result.data));
      }
    } catch (error) {
      console.error('Offers fetch error:', error);
    }
  };

  const handleCampaigns = async (userData: UserData) => {
    try {
      const userId = userData.userId || userData.id;
      if (!userId) return;

      const result = await trigger(userId).unwrap();
      if (result?.data) {
        dispatch(setCampaigns(result.data));
      }

      // Handle initial redirect if needed
      if (
        window.flutter_inappwebview &&
        window.niyo_initial_redirect &&
        typeof window.niyo_initial_redirect === 'function'
      ) {
        window.niyo_initial_redirect();
      }
    } catch (error) {
      console.error('Campaign fetch error:', error);
    }
  };

  const handleDeviceInfo = async () => {
    try {
      if (
        window.flutter_inappwebview &&
        window?.niyo_deviceInfo &&
        typeof window?.niyo_deviceInfo === 'function'
      ) {
        const deviceRes = await window.niyo_deviceInfo();
        const deviceInfo = parseObject(deviceRes) as DeviceInfo;
        dispatch(setDeviceInfo(deviceInfo));
      }
    } catch (error) {
      console.error('Device info error:', error);
    }
  };

  const handleUserFeatures = (userData: UserData) => {
    try {
      if (!userData) {
        throw new Error('User data is undefined');
      }

      // Set user tags (ensure it's an array)
      const tags = Array.isArray(userData.tags) ? userData.tags : [];
      dispatch(setTags(tags));

      // Handle premium status
      const isPremiumUser = tags.some((tag) => PREMIUM_USER_TAGS.includes(tag));
      const appVersion = userData.appVersion || '0.0.0';

      // Set default values
      dispatch(setPremiumUserFeat(false));
      dispatch(setConvenienceFeeTag(false));
      dispatch(setStudentFareFeat(false));

      // Update based on conditions
      // Premium features for premium users or newer versions
      if (isPremiumUser || compareVersions(appVersion, '3.0.0') > 0) {
        dispatch(setPremiumUserFeat(true));
      }

      // Convenience fee for premium users or older versions
      if (isPremiumUser || compareVersions(appVersion, '3.0.0') < 0) {
        dispatch(setConvenienceFeeTag(true));
      }

      dispatch(setSmbFeat(true));
      dispatch(setStudentFareFeat(false));
    } catch (error) {
      console.error('Error handling user features:', error);
      // Set safe default values
      dispatch(setTags([]));
      dispatch(setPremiumUserFeat(false));
      dispatch(setConvenienceFeeTag(false));
      dispatch(setStudentFareFeat(false));
    }
  };

  useEffect(() => {
    const initAsync = async () => {
      try {
        // Initialize window functions if needed
        if (!window.flutter_inappwebview || !window.niyo_user_login) {
          await initializeWindowFunctions();
        }
        // Get device info
        await handleDeviceInfo();

        // Handle user login
        if (
          window.flutter_inappwebview &&
          window.niyo_user_login &&
          typeof window.niyo_user_login === 'function'
        ) {
          const res = await window.niyo_user_login();
          const userObj = parseObject(res);
          const userRes = await userLogin(userObj);

          handleUserFeatures(userRes.data);
          await handleCampaigns(userRes.data);
          await handleOffers(userRes.data);
        } else if (token && process.env.NODE_ENV === 'development') {
          // Handle token-based login (development only)
          const req = {
            id: process.env.REACT_APP_TEST_USER_ID || '123456778',
            username: process.env.REACT_APP_TEST_USERNAME || 'Test User',
            mobile: process.env.REACT_APP_TEST_MOBILE || '9999999999',
            email: process.env.REACT_APP_TEST_EMAIL || 'test@onarrival.travel',
            token,
          };

          const userRes = await userLogin(req);
          handleUserFeatures(userRes.data);
          await handleCampaigns(userRes.data);
          await handleOffers(userRes.data);
        }
      } catch (error) {
        console.error('Initialization error:', error);
      } finally {
        setIsInitialized(true);
      }
    };

    initAsync();
  }, []);

  return isInitialized;
};
