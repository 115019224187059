import { Card, Divider, Typography } from "@mui/material";
import { formatDate, formatToINR } from "../../../utils";
import { useTheme } from "../../../config/theme/useTheme";
import FlexItemsBetween from "../../../components/FlexItemsBetween";

const CancellationChargesInfo = ({ data }: any) => {
  const theme = useTheme();
  return (
    <>
      {data && Object?.entries(data || {})?.map(([travellerType, charges]: any) => (
        <Card key={travellerType} sx={theme.components.pages.bookingStatus.cancel.cancellationChargesInfo.container}>
          <FlexItemsBetween sx={theme.components.pages.bookingStatus.cancel.cancellationChargesInfo.subContainer}>
            <Typography variant="caption" fontWeight={600} color="gray">CANCEL BETWEEN</Typography>
            <Typography variant="caption" fontWeight={600} color="gray">CHARGES ({travellerType ?? 'ADULT'})</Typography>
          </FlexItemsBetween>
          {charges?.map((item: any, index: number) => (
            <>
              <FlexItemsBetween key={index}>
                <Typography variant="body2">
                  {index === 0 ? `From now` : formatDate(item?.start)} - {formatDate(item?.end)}
                </Typography>
                <Typography variant="body2">{formatToINR(item?.totalCharges)}</Typography>
              </FlexItemsBetween>
              {index !== charges?.length - 1 && (
                <Divider sx={theme.components.pages.bookingStatus.cancel.cancellationChargesInfo.divider} />
              )}
            </>
          ))}
        </Card>
      ))}
    </>
  )
}

export default CancellationChargesInfo;