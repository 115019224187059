import { Box, Card, Typography } from '@mui/material';
import { OAImage } from '../../components';
import { useTheme } from '../../config/theme/useTheme';

const CfarTerms = () => {
  const theme = useTheme();
  return (
    <Box>
      <Box display="flex" flexDirection="column" gap="15px" py="15px" px="20px" bgcolor="#F4F6F5">
        <Card>
          <Typography variant="body2" fontWeight={600} display="flex" alignItems="center" gap="5px">
            <OAImage
              src={theme.assets.images.icons.airFrance.path}
              folder={theme.assets.images.icons.airFrance.folder}
              alt="book"
            />
            Book Risk-Free
          </Typography>
          <Typography variant="body2" mt="5px">
            Plan your trips confidently, knowing you’re covered for cancellations up to{' '}
            <span style={{ fontWeight: 600 }}>24 hours</span> before departure.
          </Typography>
        </Card>
        <Card>
          <Typography variant="body2" fontWeight={600} display="flex" alignItems="center" gap="5px">
            <OAImage
              src={theme.assets.images.icons.moneyIcon.path}
              folder={theme.assets.images.icons.moneyIcon.folder}
              alt="book"
            />
            Zero Cancellation Fees
          </Typography>
          <Typography variant="body2" mt="5px">
            Get <span style={{ fontWeight: 600 }}>full airfare*</span> refunded for cancellations
            for domestic flights booked via Niyo
          </Typography>
        </Card>
      </Box>
      <Box textAlign="center" bgcolor="#E6EEFF" pt="15px" pb="5px" px="20px">
        <Typography fontWeight={600} color="#13387E">
          Change plans, stress-free!
        </Typography>
        <Typography variant="body2" mt="5px" color="text.secondary">
          Plans change, and that’s okay. We’ve got you covered on airline penalties
        </Typography>
      </Box>
      <Box>
        <OAImage
          src={theme.assets.images.icons.bottomWave.path}
          folder={theme.assets.images.icons.bottomWave.folder}
          alt="wave"
          width="100%"
        />
      </Box>
      <Box px="20px" mt="15px">
        <Typography fontWeight={600}>Terms & conditions</Typography>
        <Box>
          <ul style={{ paddingLeft: '20px' }}>
            <li>
              <Typography variant="body3">
                For partial cancellations, you’ll get a full refund of the penalty for the cancelled
                portion, subject to airline terms.
              </Typography>
            </li>
            <li>
              <Typography variant="body3">
                Cancellations made with airlines directly are not eligible
              </Typography>
            </li>
            <li>
              <Typography variant="body3">
                The cancellation premium, convenience fee, booking discounts, seat, meal, baggage
                and add-on charges are non-refundable, even with the Zero Cancellation benefit.
              </Typography>
            </li>
            <li>
              <Typography variant="body3">
                Niyo is not liable for cancellations or losses caused by unforeseen events like
                weather, strikes, or government regulations.
              </Typography>
            </li>
            <li>
              <Typography variant="body3">
                Niyo reserves the right to modify, extend, or withdraw the Zero Cancellation offer
                at any time without prior notice.
              </Typography>
            </li>
            <li>
              <Typography variant="body3">
                Niyo's liability is capped at the value of the promotional discount. No
                responsibility for additional expenses due to delays, illness, or missed
                connections.
              </Typography>
            </li>
            <li>
              <Typography variant="body3">
                The Zero Cancellation benefit is void for changes to original booking (other than
                cancellation) & misuse of the offer.
              </Typography>
            </li>
          </ul>
        </Box>
      </Box>
    </Box>
  );
};

export default CfarTerms;
