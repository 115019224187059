import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { OAHeader, OASwitch } from '../../components';
import { HeaderActionFlight } from './FlightHeaderAction';
import { useDispatch, useSelector } from '../../store';
import RecentFlightSearch from './RecentFlightSearch';
import { getUserInfo, setMock } from '../../store/slices/userInfoSlice';
import SearchParameters from './SearchParameters';
import { getAuthInfo } from '../../store/slices/authSlice';
import { useGetBookingsListQuery } from '../../services/bookingsApi';
import CoinsBanner from './CoinsBanner';
import { setIsCoinsApplied } from '../../store/slices/userInputSlice';
import OffersBenefits from './OffersBenefits';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { getOffersState } from '../../store/slices/offersSlice';
import SpecialOffers from './SpecialOffers';
import { useLocation } from './hooks/useLocation';
import { Booking, Campaign, FeatureFlags, UserInfo } from './types';
import { isTestEmailForProd } from '../../utils';
import { useTheme } from '../../config/theme/useTheme';
import { getHeaderStyles } from './styles';

import { VERSION } from '../../version';
import FlexItemsCentered from '../../components/FlexItemsCentered';

const Flight = () => {
  const dispatch = useDispatch();
  const { user, campaigns, token } = useSelector(getAuthInfo);
  const { data: bookings } = useGetBookingsListQuery({}, { skip: !user });
  const { isMock, coins } = useSelector<UserInfo>(getUserInfo);
  // scrollTop is used by SearchParameters component
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scrollTop, setScrollTop] = useState(true);
  const { burnCoinsFeat, premiumUserFeat } = useSelector<FeatureFlags>(getFeatureFlag);
  const { data: offers } = useSelector(getOffersState);

  // Use custom location hook
  useLocation();

  // Memoized values
  const isBannerDisplayed = useMemo(
    () => burnCoinsFeat || (campaigns as Campaign)?.eligibleCampaigns?.length > 0,
    [burnCoinsFeat, campaigns]
  );

  const activeBookingsCount = useMemo(() => {
    if (!bookings) return 0;
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return bookings.filter(
      (booking: Booking) =>
        ['BOOKING_CONFIRMED', 'UPCOMING', 'BOOKING_IN_PROGRESS', 'PROCESSING_OFFLINE'].includes(
          booking.bookingStatus
        ) && new Date(booking.tripStartDate) >= today
    ).length;
  }, [bookings]);

  // Effects
  useEffect(() => {
    if (coins > 9) {
      dispatch(setIsCoinsApplied(true));
    }
  }, [coins, dispatch]);

  useEffect(() => {
    console.log(`Version ${VERSION}`);
  }, []);

  // Event handlers
  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setMock(event.target.checked));
    },
    [dispatch]
  );

  const theme = useTheme();

  return (
    <Box sx={theme.components.pages.flight.wrapper}>
      <OAHeader
        title={''}
        appBarSx={{
          ...getHeaderStyles(premiumUserFeat, window?.innerHeight || 0, window?.innerWidth || 0),
          width: '100%',
        }}
        action={<HeaderActionFlight totalActiveBookings={activeBookingsCount} />}
        backgroundImageText={
          <CoinsBanner
            title={
              premiumUserFeat
                ? theme.strings.flight.header.premium.title
                : theme.strings.flight.header.regular.title
            }
            subTitle={
              premiumUserFeat
                ? theme.strings.flight.header.premium.subtitle
                : theme.strings.flight.header.regular.subtitle
            }
            titleSx={
              premiumUserFeat
                ? theme.components.banner.premium.title
                : theme.components.banner.regular.title
            }
            subTitleSx={
              premiumUserFeat
                ? theme.components.banner.premium.subtitle
                : theme.components.banner.regular.subtitle
            }
          />
        }
      />

      <Container sx={theme.components.pages.flight.container}>
        <SearchParameters shouldFetchLocations={true} setScrollTop={setScrollTop} />
      </Container>
      <SpecialOffers />
      {isBannerDisplayed && <OffersBenefits />}
      <Box
        sx={{
          ...theme.components.pages.flight.recentFlightSearch.wrapper,
          ...(isBannerDisplayed
            ? theme.components.pages.flight.recentFlightSearch.wrapper.withBanner
            : theme.components.pages.flight.recentFlightSearch.wrapper.withoutBanner),
        }}
      >
        <RecentFlightSearch />
      </Box>
      {isTestEmailForProd(user?.email) && (
        <FlexItemsCentered sx={theme.components.pages.flight.vConsoleFooter.container}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Version {VERSION}
          </Typography>

          <Box onClick={() => navigator.clipboard.writeText(token)} ml="24px">
            (Token)
          </Box>

          <Box ml="24px">
            <OASwitch onChange={handleOnChange} checked={isMock} />
          </Box>
        </FlexItemsCentered>
      )}
    </Box>
  );
};

export default Flight;
