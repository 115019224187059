import { Box, Skeleton } from '@mui/material';
import { OAChip } from '../../components';
import FlexItems from '../../components/FlexItems';

const TabSwitchSkeleton = () => {
  return (
    <OAChip
      label={
        <FlexItems>
          <Skeleton
            variant="rectangular"
            width="300px"
            height="30px"
            sx={{ backgroundColor: '#f4f6f5', borderRadius: '16px' }}
          />
          <Skeleton
            variant="rectangular"
            width="300px"
            height="30px"
            sx={{ backgroundColor: '#f4f6f5', borderRadius: '16px', marginLeft: '10px' }}
          />
        </FlexItems>
      }
      variant="outlined"
      sx={{ width: '100%', height: '40px', backgroundColor: '#ffffff' }}
    />
  );
};

export default TabSwitchSkeleton;
