import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { size, flatten, values } from 'lodash';
import { Box, Container, Divider, Grid, Tab, Tabs, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import CloseIcon from '@mui/icons-material/Close';
import {
  OAAirlineLogo,
  OAButton,
  OAChip,
  OAImage,
  OALoadingOverlay,
  OASwipeableDrawer,
} from '../../../components';
import { getFilterState, getSearchFlightState } from '../../../store/slices/searchFlightSlice';
import { getUserInputState } from '../../../store/slices/userInputSlice';
import FilterCheckboxItem from './FilterCheckboxItem';
import FilterLabel from './FilterLabel';
import { eventsTracker } from '../../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import OASlider from '../../../components/OASlider';
import { convertDurationToMinutes } from '../../../utils';
import { formatHours } from './formatHours';
import { getFilterSections } from './filterSectionItems';
import FilterSection from './FilterSection';
import FlexItemsBetween from '../../../components/FlexItemsBetween';
import { useTheme } from '../../../config/theme/useTheme';
import FlexItems from '../../../components/FlexItems';

interface FilterProps {
  filterData: any;
  filtersCount: any;
  countOfStops: any;
  countOfAirports: any;
  setFilterData: (values: any) => void;
  onClick: (filterData: any) => void;
  onClickClearFilter: () => void;
}

const Filter: React.FC<FilterProps> = ({
  filterData,
  filtersCount,
  countOfStops,
  countOfAirports,
  setFilterData,
  onClick,
  onClickClearFilter,
}) => {
  const { data } = useSelector(getSearchFlightState);
  const { travelDetail } = useSelector(getUserInputState);
  const filterValue = useSelector(getFilterState);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showAllAirlines, setShowAllAirlines] = useState<boolean>(false);
  const [localFilterData, setLocalFilterData] = useState<any>(filterData);
  const [airlinePrices, setAirlinePrices] = useState<{ [key: string]: number }>({});
  const [startTime, setStartTime] = useState(Date.now());
  const [activeTab, setActiveTab] = useState<string>('stops');
  const [layoverPrices, setLayoverPrices] = useState<{ [key: string]: number }>({});
  const [showAllLayovers, setShowAllLayovers] = useState<boolean>(false);
  const [flightDuration, setFlightDuration] = useState<number>(0);
  const [layoverDuration, setLayoverDuration] = useState<number>(0);
  const posthog = usePostHog();
  const theme = useTheme();
  const drawerContentRef = React.useRef<HTMLDivElement | null>(null);

  const layoverAirports = data?.meta?.layoverAirports || [];
  const displayedLayovers = showAllLayovers ? layoverAirports : layoverAirports.slice(0, 4);

  const isInternationalReturn =
    data?.international && travelDetail?.tripType === 'R' && travelDetail?.tripEnd?.date
      ? true
      : false;

  const filterSections = useMemo(
    () =>
      getFilterSections(data, isInternationalReturn)?.filter((section: any) => section?.available),
    [data?.meta, isInternationalReturn]
  );

  useEffect(() => {
    if (data?.results) {
      const prices: { [key: string]: number } = {};
      const layoverPrices: { [key: string]: number } = {};

      data?.results?.onward?.forEach((item: any) => {
        const airlineCode = item?.airlineCode;
        const price = item?.price?.value;
        if (price && (!prices[airlineCode] || price < prices[airlineCode])) {
          prices[airlineCode] = price;
        }
        item?.layovers?.forEach((layover: any) => {
          const layoverIata = layover?.iataCode;
          if (price && (!layoverPrices[layoverIata] || price < layoverPrices[layoverIata])) {
            layoverPrices[layoverIata] = price;
          }
        });
      });

      data?.results?.return?.forEach((item: any) => {
        const airlineCode = item?.airlineCode;
        const price = item?.price?.total;
        if (price && (!prices[airlineCode] || price < prices[airlineCode])) {
          prices[airlineCode] = price;
        }

        item?.layovers?.forEach((layover: any) => {
          const layoverIata = layover?.iataCode;
          if (price && (!layoverPrices[layoverIata] || price < layoverPrices[layoverIata])) {
            layoverPrices[layoverIata] = price;
          }
        });
      });

      setAirlinePrices(prices);
      setLayoverPrices(layoverPrices);
    }
  }, [data]);

  useEffect(() => {
    if (data?.meta?.maxFightDuration?.display) {
      const initialFlightDuration = localFilterData?.flightDuration?.[0]
        ? parseInt(localFilterData.flightDuration[0])
        : convertDurationToMinutes(data.meta.maxFightDuration.display);
      setFlightDuration(initialFlightDuration);
    }

    if (data?.meta?.maxLayoverDuration?.display) {
      const initialLayoverDuration = localFilterData?.layoverDuration?.[0]
        ? parseInt(localFilterData.layoverDuration[0])
        : convertDurationToMinutes(data.meta.maxLayoverDuration.display);
      setLayoverDuration(initialLayoverDuration);
    }
  }, [data?.meta?.maxFightDuration, data?.meta?.maxLayoverDuration, localFilterData]);

  useEffect(() => {
    const handleScroll = () => {
      if (!drawerContentRef.current) return;

      const container = drawerContentRef.current;
      const scrollPosition = container.scrollTop;

      const sections = filterSections
        .map((section) => ({
          id: section.id,
          element: document.getElementById(section.id),
        }))
        .filter((section) => section.element);

      let currentSection = sections[0]?.id;
      let minDistance = Infinity;

      // Special handling for the first section (stops)
      const stopsElement = document.getElementById('stops');
      if (stopsElement) {
        const stopsRect = stopsElement.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();
        if (stopsRect.top - containerRect.top >= -50) {
          // Increased threshold for "stops"
          setActiveTab('stops');
          return;
        }
      }

      sections.forEach(({ id, element }) => {
        if (!element) return;

        const rect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        // Calculate distance from the top of the viewport, accounting for header
        const distanceFromTop = rect.top - containerRect.top;

        // Consider a section "visible" when it's within the top portion of the container
        const targetPosition = 90; // header height
        const distance = Math.abs(distanceFromTop - targetPosition);

        if (distance < minDistance) {
          minDistance = distance;
          currentSection = id;
        }
      });

      if (currentSection && currentSection !== activeTab) {
        setActiveTab(currentSection);
      }
    };

    const container = drawerContentRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      // Initial check
      setTimeout(handleScroll, 100); // Delay to ensure elements are rendered
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [filterSections, activeTab]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);

    setTimeout(() => {
      if (!drawerContentRef.current) return; // If ref is not set, do nothing.

      const container = drawerContentRef.current;
      const targetElement = document.getElementById(newValue);
      if (!targetElement) return;

      // Get bounding boxes.
      const containerRect = container.getBoundingClientRect();
      const elementRect = targetElement.getBoundingClientRect();

      // The offset for your sticky header *inside* the drawer container
      const offset = 90;

      // How far the element is from the container top:
      const elementOffset = elementRect.top - containerRect.top;

      // Where we should scroll in the container:
      const scrollPosition = container.scrollTop + elementOffset - offset;

      container.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }, 0);
  };

  const toggleDrawer = (open: boolean) => {
    setIsOpen(open);
    if (!open) {
      setLocalFilterData(filterData || null);
    }
  };

  const handleChangeFilter = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const { checked, value: targetValue } = event.target;

    setLocalFilterData((currentFilterData: any) => {
      const updatedFilter = { ...currentFilterData };

      if (!updatedFilter[name]) {
        updatedFilter[name] = [];
      }

      if (checked) {
        if (!updatedFilter[name]?.includes(targetValue)) {
          updatedFilter[name] = [...updatedFilter[name], targetValue];
        }
      } else {
        updatedFilter[name] = updatedFilter[name]?.filter((a: any) => a !== targetValue);
        if (updatedFilter[name]?.length === 0) {
          delete updatedFilter[name];
        }
      }

      return updatedFilter;
    });
  };

  const handleApplyBtn = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    const updatedFilterData = {
      ...localFilterData,
      flightDuration:
        flightDuration < convertDurationToMinutes(data?.meta?.maxFightDuration?.display)
          ? [flightDuration.toString()]
          : undefined,
      layoverDuration:
        layoverDuration < convertDurationToMinutes(data?.meta?.maxLayoverDuration?.display)
          ? [layoverDuration.toString()]
          : undefined,
    };

    Object.keys(updatedFilterData).forEach(
      (key) => updatedFilterData[key] === undefined && delete updatedFilterData[key]
    );

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'SRP',
        ctaAction: 'ApplyFilter',
        screenDuration: totalScreenDuration?.toString(),
        otherData: {
          localFilterData: updatedFilterData,
        },
      },
      posthog
    );

    setIsLoading(true);
    toggleDrawer(false);
    setFilterData(updatedFilterData);
    onClick(updatedFilterData);
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const handleClearBtn = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'SRP',
        ctaAction: 'ClearFilter',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    setIsLoading(true);
    onClickClearFilter();
    setLocalFilterData({});
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  };

  const totalFilter = useMemo(() => size(flatten(values(filterValue))), [filterValue]);

  const from = travelDetail?.from?.city;
  const to = travelDetail?.to?.city;

  const getFilterCount = (filterType: string) => {
    return localFilterData?.[filterType]?.length || 0;
  };

  useEffect(() => {
    setLocalFilterData(filterData || null);
  }, [filterData]);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const renderStopsFilter = () => (
    <FilterSection title="Stops" filterType="stops" filterCount={getFilterCount('stops')}>
      <FlexItemsBetween sx={{ gap: '8px' }}>
        {data?.meta?.stops?.map((stop: any) => {
          const count = countOfStops[stop.value] || 0;
          const isDisabled = count === 0;
          return (
            <Box
              key={stop.value}
              sx={{
                ...theme.components.pages.searchResults.filterAndSort.filter.stops.container,
                ...(localFilterData?.stops?.includes(stop.value) &&
                  theme.components.pages.searchResults.filterAndSort.filter.stops.withApplied),
                ...(isDisabled &&
                  theme.components.pages.searchResults.filterAndSort.filter.stops.disabled),
              }}
              onClick={() => {
                if (!isDisabled) {
                  handleChangeFilter(
                    {
                      target: {
                        checked: !localFilterData?.stops?.includes(stop.value),
                        value: stop.value,
                      },
                    } as React.ChangeEvent<HTMLInputElement>,
                    'stops'
                  );
                }
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  ...(isDisabled
                    ? theme.components.pages.searchResults.filterAndSort.filter.stops.stopsContent
                        .disabled.text
                    : {
                        color: localFilterData?.stops?.includes(stop.value)
                          ? theme.components.pages.searchResults.filterAndSort.filter.stops
                              .stopsContent.text.color
                          : 'revert',
                        fontWeight:
                          theme.components.pages.searchResults.filterAndSort.filter.stops
                            .stopsContent.text.fontWeight,
                      }),
                }}
              >
                {stop.value === '0' ? 'Direct' : `${stop.value} stop`}{' '}
                <Typography
                  variant="body3"
                  sx={{
                    ...(isDisabled
                      ? theme.components.pages.searchResults.filterAndSort.filter.stops.stopsContent
                          .disabled.subText
                      : {
                          color: localFilterData?.stops?.includes(stop.value)
                            ? theme.components.pages.searchResults.filterAndSort.filter.stops
                                .stopsContent.subText.color
                            : 'text.secondary',
                          fontWeight:
                            theme.components.pages.searchResults.filterAndSort.filter.stops
                              .stopsContent.subText.fontWeight,
                        }),
                  }}
                >
                  ({count})
                </Typography>
              </Typography>
            </Box>
          );
        })}
      </FlexItemsBetween>
    </FilterSection>
  );

  const renderAirlinesFilter = () => (
    <FilterSection title="Airlines" filterType="airlines" filterCount={getFilterCount('airlines')}>
      {data?.meta?.airlines
        ?.slice(0, showAllAirlines ? undefined : 4)
        ?.filter((airline: any) => airlinePrices[airline.value])
        ?.map((airline: any) => (
          <FilterCheckboxItem
            key={airline.value}
            label={
              <FlexItems>
                <OAAirlineLogo
                  airlineCode={airline?.value}
                  sx={{
                    width: '20px',
                    height: '20px',
                    marginRight: '8px',
                  }}
                />
                <Typography variant="body2">{airline.label}</Typography>
              </FlexItems>
            }
            value={airline.value}
            checked={Boolean(localFilterData?.airlines?.includes(airline.value))}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleChangeFilter(event, 'airlines')
            }
            price={airlinePrices[airline.value]}
          />
        ))}
      {data?.meta?.airlines?.length > 4 && (
        <FlexItemsBetween
          sx={{ mt: '10px' }}
          onClick={() => {
            setShowAllAirlines(!showAllAirlines);
          }}
        >
          <Typography variant="body2" color="#009E82" sx={{ cursor: 'pointer' }}>
            {showAllAirlines ? 'Show less' : `Show all`}
          </Typography>
          <OAImage
            src={
              !showAllAirlines
                ? theme.assets.images.icons.dropDown.path
                : theme.assets.images.icons.dropUp.path
            }
            alt="down"
            folder={theme.assets.images.icons.dropDown.folder}
            mr="8px"
          />
        </FlexItemsBetween>
      )}
    </FilterSection>
  );

  const renderDepartureFilter = (title: string, filterType: string, from: string) => (
    <FilterSection
      title={`${title} from ${from}`}
      filterType={filterType}
      filterCount={getFilterCount(filterType)}
    >
      {data?.meta?.[filterType]?.map((item: any) => (
        <FilterCheckboxItem
          key={item.value}
          label={
            <Typography variant="body2" display="flex" alignItems="center">
              <OAImage
                src={
                  item.value === 'EARLY_MORNING'
                    ? theme.assets.images.icons.earlyMorning.path
                    : item.value === 'MORNING'
                      ? theme.assets.images.icons.morning.path
                      : item.value === 'MID_DAY'
                        ? theme.assets.images.icons.midday.path
                        : item.value === 'EVENING'
                          ? theme.assets.images.icons.evening.path
                          : item.value === 'NIGHT'
                            ? theme.assets.images.icons.night.path
                            : theme.assets.images.icons.earlyMorning.path
                }
                alt="time"
                folder={theme.assets.images.icons.earlyMorning.folder}
                mr="10px"
              />
              {item.label}
            </Typography>
          }
          value={item.value}
          checked={Boolean(localFilterData?.[filterType]?.includes(item.value))}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeFilter(event, filterType)
          }
          time={item.info}
        />
      ))}
    </FilterSection>
  );

  const renderAirportsFilter = (title: string, filterType: string) => (
    <FilterSection title={title} filterType={filterType} filterCount={getFilterCount(filterType)}>
      {data?.meta?.[filterType]?.airports?.map((airport: any) => (
        <FilterCheckboxItem
          key={airport.iata}
          label={
            <FlexItems sx={{ width: '100%' }}>
              <Typography
                variant="body2"
                sx={theme.components.pages.searchResults.filterAndSort.filter.airports.text}
              >
                {airport?.name}
              </Typography>
              {airport?.distance?.label && (
                <Typography
                  variant="body2"
                  sx={theme.components.pages.searchResults.filterAndSort.filter.airports.subText}
                >
                  &nbsp;({airport?.distance?.label})
                </Typography>
              )}
            </FlexItems>
          }
          value={airport.iata}
          checked={Boolean(localFilterData?.[filterType]?.includes(airport.iata))}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeFilter(event, filterType)
          }
        />
      ))}
    </FilterSection>
  );

  const renderLayoverFilter = () => (
    <FilterSection
      title="Layover airports"
      filterType="layover"
      filterCount={getFilterCount('layoverAirports')}
    >
      {displayedLayovers.map((airport: any) => (
        <FilterCheckboxItem
          key={airport.iata}
          label={
            <FlexItems
              sx={theme.components.pages.searchResults.filterAndSort.filter.airlines.container}
            >
              <Typography
                variant="body2"
                sx={theme.components.pages.searchResults.filterAndSort.filter.airlines.text}
              >
                {airport?.name}
              </Typography>
              <Typography
                variant="body2"
                sx={theme.components.pages.searchResults.filterAndSort.filter.airlines.subText}
              >
                &nbsp;({airport?.iata})
              </Typography>
            </FlexItems>
          }
          value={airport.iata}
          checked={Boolean(localFilterData?.layoverAirports?.includes(airport.iata))}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeFilter(event, 'layoverAirports')
          }
          price={layoverPrices[airport.iata]}
        />
      ))}
      {layoverAirports?.length > 4 && (
        <FlexItemsBetween
          onClick={() => setShowAllLayovers(!showAllLayovers)}
          sx={{ cursor: 'pointer', mt: '10px' }}
        >
          <Typography variant="body2" color="#009E82">
            {showAllLayovers ? 'Show less' : `Show all`}
          </Typography>
          <OAImage
            src={
              !showAllLayovers
                ? theme.assets.images.icons.dropDown.path
                : theme.assets.images.icons.dropUp.path
            }
            alt="toggle"
            folder="icons"
            mr="8px"
          />
        </FlexItemsBetween>
      )}
    </FilterSection>
  );

  return (
    <>
      {isLoading && <OALoadingOverlay />}
      <OAChip
        selected={totalFilter > 0}
        icon={<TuneIcon fontSize="small" sx={{ color: '#5A6068 !important' }} />}
        label={<FilterLabel label="Filter" total={totalFilter} />}
        onClick={() => toggleDrawer(true)}
        {...(totalFilter > 0 ? { onDelete: onClickClearFilter } : {})}
        {...(totalFilter > 0 ? { deleteIcon: <CloseIcon fontSize="small" sx={{ mr: 0 }} /> } : {})}
        disabled={data?.meta && data?.results?.onward?.length > 0 ? false : true}
      />
      <OASwipeableDrawer
        title={'Filters'}
        open={isOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
        isContainer={false}
        height={95}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            position: 'sticky',
            top: '90px',
            bgcolor: 'white',
            zIndex: 1,
          }}
        >
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            {filterSections.map((section) => (
              <Tab
                key={section.id}
                label={section.label}
                value={section.id}
                sx={{ textTransform: 'none' }}
              />
            ))}
          </Tabs>
        </Box>
        <Box
          ref={drawerContentRef}
          sx={theme.components.pages.searchResults.filterAndSort.filter.stops.subContainer}
        >
          {data?.meta?.stops?.length > 0 && (
            <Box
              id="stops"
              sx={theme.components.pages.searchResults.filterAndSort.filter.stops.containerRenderer}
            >
              {renderStopsFilter()}
            </Box>
          )}
          {data?.meta?.maxFightDuration?.display && (
            <Box pl="20px" pr="30px" id="flightDuration">
              <Divider sx={{ my: '30px', mr: '-20px', borderStyle: 'dashed' }} />
              <Typography fontWeight="600" mb="10px">
                Flight Duration
              </Typography>
              <Typography variant="body3" fontWeight="600" mb="10px">
                {formatHours(flightDuration)}
              </Typography>
              <OASlider
                value={flightDuration}
                onChange={(e, value) => setFlightDuration(Array.isArray(value) ? value[0] : value)}
                min={convertDurationToMinutes(data?.meta?.minFightDuration?.display)}
                max={convertDurationToMinutes(data?.meta?.maxFightDuration?.display)}
              />
              <FlexItemsBetween>
                <Typography variant="body3" color="text.secondary">
                  {data?.meta?.minFightDuration?.display}
                </Typography>
                <Typography mr="-10px" variant="body3" color="text.secondary">
                  {data?.meta?.maxFightDuration?.display}
                </Typography>
              </FlexItemsBetween>
            </Box>
          )}
          {data?.meta?.airlines?.length > 0 && (
            <Box id="airlines">
              <Box px="20px">
                <Divider
                  sx={
                    theme.components.pages.searchResults.filterAndSort.filter.divider.styles
                      .container
                  }
                />
              </Box>
              {renderAirlinesFilter()}
            </Box>
          )}
          {data?.meta?.onwardDeparture && (
            <Box id="onwardDeparture">
              <Box px="20px">
                <Divider
                  sx={
                    theme.components.pages.searchResults.filterAndSort.filter.divider.styles
                      .container
                  }
                />
              </Box>
              {renderDepartureFilter('Departure', 'onwardDeparture', from)}
            </Box>
          )}
          {data?.meta?.returnDeparture && !isInternationalReturn && (
            <Box id="returnDeparture">
              <Box px="20px">
                <Divider
                  sx={
                    theme.components.pages.searchResults.filterAndSort.filter.divider.styles
                      .container
                  }
                />
              </Box>
              {renderDepartureFilter('Return', 'returnDeparture', to)}
            </Box>
          )}
          {data?.meta?.originNearbyAirports?.airports?.length > 0 && (
            <Box id="originAirports">
              <Box px="20px">
                <Divider
                  sx={
                    theme.components.pages.searchResults.filterAndSort.filter.divider.styles
                      .container
                  }
                />
              </Box>
              {renderAirportsFilter('Departure airports', 'originNearbyAirports')}
            </Box>
          )}
          {data?.meta?.destinationNearbyAirports?.airports?.length > 0 && (
            <Box id="destinationAirports">
              <Box px="20px">
                <Divider
                  sx={
                    theme.components.pages.searchResults.filterAndSort.filter.divider.styles
                      .container
                  }
                />
              </Box>
              {renderAirportsFilter('Arrival airports', 'destinationNearbyAirports')}
            </Box>
          )}
          {data?.meta?.layoverAirports?.length > 0 && (
            <Box id="layover">
              <Box px="20px">
                <Divider
                  sx={
                    theme.components.pages.searchResults.filterAndSort.filter.divider.styles
                      .container
                  }
                />
              </Box>
              {renderLayoverFilter()}
            </Box>
          )}
          {data?.meta?.maxLayoverDuration?.display && (
            <Box pl="20px" pr="30px" mb="16px" id="layoverDuration">
              <Divider sx={{ my: '30px', mr: '-10px', borderStyle: 'dashed' }} />
              <Typography fontWeight="600" mb="10px">
                Layover Duration
              </Typography>
              <Typography variant="body3" fontWeight="600" mb="10px">
                {formatHours(layoverDuration)}
              </Typography>
              <OASlider
                value={layoverDuration}
                onChange={(e, value) => setLayoverDuration(Array.isArray(value) ? value[0] : value)}
                min={convertDurationToMinutes(data?.meta?.minLayoverDuration?.display)}
                max={convertDurationToMinutes(data?.meta?.maxLayoverDuration?.display)}
              />
              <FlexItemsBetween>
                <Typography variant="body3" color="text.secondary">
                  {data?.meta?.minLayoverDuration?.display}
                </Typography>
                <Typography mr="-10px" variant="body3" color="text.secondary">
                  {data?.meta?.maxLayoverDuration?.display}
                </Typography>
              </FlexItemsBetween>
            </Box>
          )}
        </Box>
        <Container
          sx={theme.components.pages.searchResults.filterAndSort.filter.filtersCtaContainer}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <OAButton
                fullWidth
                disableRipple
                variant="outlined"
                color="secondary"
                onClick={handleClearBtn}
              >
                Clear Filter
              </OAButton>
            </Grid>
            <Grid item xs={6}>
              <OAButton onClick={handleApplyBtn} fullWidth variant="contained" color="secondary">
                Apply Filter
              </OAButton>
            </Grid>
          </Grid>
        </Container>
      </OASwipeableDrawer>
    </>
  );
};

export default Filter;
