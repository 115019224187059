import { Box, Container } from "@mui/material";
import { useEffect } from "react";
import { setOffersData } from "../../store/slices/offersSlice";
import { useDispatch } from "../../store";
import { useOffers } from "../../hooks/useOffers";
import { useLazyGetOffersQuery } from "../../services/offersApi";
import { useTheme } from "../../config/theme/useTheme";

const SpecialOffers = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [triggerOffers, { data: offersData }] = useLazyGetOffersQuery({});

  const { applicableOffers, getBannerDetails } = useOffers({
    currentPage: ["HOME"],
    offers: offersData?.data,
    skipRouteChecks: true
  })

  useEffect(() => {
    triggerOffers({});
  }, [triggerOffers]);

  useEffect(() => {
    if (offersData?.data?.length) {
      dispatch(setOffersData(offersData?.data));
    }
  }, [offersData?.data?.length]);
  
  return (
    <Box>
      {applicableOffers?.length > 0 && (
        <Container sx={theme.components.pages.flight.specialOffers.container}>
          {applicableOffers?.[0] && (
            <Box>
              <img 
                src={getBannerDetails(applicableOffers[0].offerId, "HOME")?.imageUrl} 
                alt={getBannerDetails(applicableOffers[0].offerId, "HOME")?.title || "Special offer"} 
                width="100%" 
              />
            </Box>
          )}
        </Container>
      )}
    </Box>
  );
};

export default SpecialOffers;