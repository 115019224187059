import { Box, Typography } from '@mui/material';
import { OAImage } from './OAImage';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme } from '../config/theme/useTheme';
import FlexItems from './FlexItems';

const OAFlightRoute = ({ from, to, icon }: { from: string; to: string; icon: any }) => {
  const theme = useTheme();
  return (
    <FlexItems sx={theme.components.pages.itinerary.oaFlightRoute.container}>
      <OAImage
        src={icon}
        folder="icons"
        alt="flight"
        sx={{ ml: '4px', height: '18px', width: '18px', color: '#5A6068' }}
      />
      <Typography variant="body3" fontWeight={600} ml="6px">
        {from}
      </Typography>
      <ArrowForwardIcon sx={{ ml: '4px', height: '18px', width: '18px', color: '#5A6068' }} />
      <Typography variant="body3" fontWeight={600} ml="4px">
        {to}
      </Typography>
    </FlexItems>
  );
};

export default OAFlightRoute;
