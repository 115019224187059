import { combineReducers, configureStore } from '@reduxjs/toolkit';
import userInputSlice from './slices/userInputSlice';
import { useDispatch as useDispatchBase, useSelector as useSelectorBase } from 'react-redux';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import globalReducer from './slices/globalSlice';
import airportSearchSlice from './slices/airportSearchSlice';
import searchFlightSlice from './slices/searchFlightSlice';
import snackbarSlice from './slices/snackbarSlice';
import userInfoSlice from './slices/userInfoSlice';
import flightOrderSlice from './slices/flightOrderSlice';
import { flightSearchApi } from '../services/flightSearchApi';
import { getBookingsApi } from '../services/bookingsApi';
import { airportDataApi } from '../services/airportDataApi';
import { travellersApi } from '../services/travellersApi';
import { authApi } from '../services/authApi';
import authSlice from './slices/authSlice';
import { paymentApi } from '../services/paymentApi';
import cancellationSlice from './slices/cancellationSlice';
import featuresSlice from './slices/featuresSlice';
import { createOrderApi } from '../services/createOrderApi';
import offersSlice from './slices/offersSlice';
import { offersApi } from '../services/offersApi';
import travellerSlice from './slices/travellerSlice';

// a persist config specifically for the airportSearch slice
const airportSearchPersistConfig = {
  key: 'airportSearch',
  storage: storage,
  whitelist: ['fromAirportSuggestions', 'toAirportSuggestions'], // only these fields will be persisted
};

const userInputPersistConfig = {
  key: 'userInput',
  storage: storage,
  whitelist: ['traveller', 'travelDetail'],
};

const flightOrderPersistConfig = {
  key: 'flightData',
  storage: storage,
  whitelist: ['flightData'],
};

const userInfoPersistConfig = {
  key: 'userInfo',
  storage: storage,
  whitelist: ['tags', 'premiumUserFeat'],
};

// persistReducer to enhance airportSearch reducer
const persistedAirportSearchReducer = persistReducer(
  airportSearchPersistConfig,
  airportSearchSlice
);

const persistedUserInputReducer = persistReducer(userInputPersistConfig, userInputSlice);

const persistedFlightOrderReducer = persistReducer(flightOrderPersistConfig, flightOrderSlice);

const persistedUserInfoReducer = persistReducer(userInfoPersistConfig, userInfoSlice);

const rootReducer = combineReducers({
  global: globalReducer,
  userInput: persistedUserInputReducer,
  flightList: searchFlightSlice,
  airportSearch: persistedAirportSearchReducer,
  snackbar: snackbarSlice,
  userInfo: persistedUserInfoReducer,
  flightData: persistedFlightOrderReducer,
  auth: authSlice,
  features: featuresSlice,
  cancellationData: cancellationSlice,
  offers: offersSlice,
  traveller: travellerSlice,
  [getBookingsApi.reducerPath]: getBookingsApi.reducer,
  [flightSearchApi.reducerPath]: flightSearchApi.reducer,
  [airportDataApi.reducerPath]: airportDataApi.reducer,
  [paymentApi.reducerPath]: paymentApi.reducer,
  [travellersApi.reducerPath]: travellersApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [createOrderApi.reducerPath]: createOrderApi.reducer,
  [offersApi.reducerPath]: offersApi.reducer,
});

// store configuration
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these redux-persist actions
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        // Ignore these field paths in all actions
        ignoredActionPaths: ['register', 'meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['features.tags', 'userInfo.tags', 'flights.searchResults', 'offers.data'],
      },
    }).concat(
      airportDataApi.middleware,
      flightSearchApi.middleware,
      getBookingsApi.middleware,
      paymentApi.middleware,
      travellersApi.middleware,
      authApi.middleware,
      createOrderApi.middleware,
      offersApi.middleware
    ),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: { users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Since we use typescript, lets utilize `useDispatch`
export const useDispatch = () => useDispatchBase<AppDispatch>();

// And utilize `useSelector`
export const useSelector = <TSelected = unknown>(
  selector: (state: RootState) => TSelected
): TSelected => useSelectorBase<RootState, TSelected>(selector);
