import { FC, useEffect, useState } from 'react';
import OATabs from './components/OATabs';
import { Box, Skeleton } from '@mui/material';
import BottomBar from './components/BottomBar';
import { usePostHog } from 'posthog-js/react';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { getCurrentTabString } from '../../utils';
import { OALoadingSearch } from '../OALoadingSearch';
import { useTheme } from '../../config/theme/useTheme';
import FlexItems from '../FlexItems';
import FlexItemsBetween from '../FlexItemsBetween';

interface OASMBProps {
  isLoading: boolean;
  selectedTab: 'Seat' | 'Meal' | 'Baggage';
  tabValue: number;
  setTabValue: (value: number) => void;
  smbData: any;
  totalTravellers: number;
  updateSmbAction: any;
  isDiscountApplied: boolean;
  totalSelectedFlightPrice: number;
  discountPrice: number;
  selectedSmbData: any;
  segmentBasedTabs: any;
  selectedSegment: { from: string; to: string };
  setSelectedSegment: any;
  onClickHandler: () => void;
}

const loadingText = [
  `🛋️ Get comfy! We're finding the perfect spot for you`,
  `👑 Hang tight while we fetch the best options for you`,
  `🍲 Hold on! We're preparing a delicious menu for you`,
  `🌟 Loading your meal choices`,
  `🧳 Finding the best baggage deals for you`
]

const OASMB: FC<OASMBProps> = ({
  isLoading,
  tabValue,
  setTabValue,
  smbData,
  totalTravellers,
  updateSmbAction,
  isDiscountApplied,
  totalSelectedFlightPrice,
  discountPrice,
  selectedSmbData,
  segmentBasedTabs,
  selectedSegment,
  setSelectedSegment,
  onClickHandler,
}) => {
  const posthog = usePostHog();
  const theme = useTheme();
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleContinue = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'SMB',
        ctaAction: getCurrentTabString(tabValue) + 'Continue',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );

    if (tabValue === 3) {
      onClickHandler();
    } else {
      const newTabValue = (currentTabValue: number) => {
        if (currentTabValue === 1) {
          return 2;
        } else if (currentTabValue === 2) {
          return 3;
        } else {
          return 1;
        }
      };
      setTabValue(newTabValue(tabValue));
    }
  };

  const totalPrice = totalSelectedFlightPrice;

  return (
    <>
      <Box>
        {isLoading ?
          <FlexItems sx={theme.components.pages.itinerary.smbWrapper.smbContent.loaderContainer.container}>
            <Box width='100%'>
              <Box sx={theme.components.pages.itinerary.smbWrapper.smbContent.loaderContainer.subContainer}>
                <FlexItemsBetween sx={theme.components.pages.itinerary.smbWrapper.smbContent.loaderContainer.loaderFlex}>
                  <Skeleton variant='rectangular' sx={theme.components.pages.itinerary.smbWrapper.smbContent.loaderContainer.skeleton}  />
                  <Skeleton variant='rectangular' sx={theme.components.pages.itinerary.smbWrapper.smbContent.loaderContainer.skeletonWithMargin}  />
                  <Skeleton variant='rectangular' sx={theme.components.pages.itinerary.smbWrapper.smbContent.loaderContainer.skeleton}  />
                </FlexItemsBetween>
              </Box>
              <Box>
                <OALoadingSearch loadingText={loadingText} />
              </Box>
            </Box>
          </FlexItems>
          :
          <OATabs
            isLoading={isLoading}
            tabValue={tabValue}
            handleTabChange={handleTabChange}
            setTabValue={setTabValue}
            smbData={smbData}
            updateSmbAction={updateSmbAction}
            selectedSmbData={selectedSmbData}
            totalTravellers={totalTravellers}
            selectedSegment={selectedSegment}
            setSelectedSegment={setSelectedSegment}
            segmentBasedTabs={segmentBasedTabs}
          />}
      </Box>
      {isLoading ?
        <FlexItems sx={theme.components.pages.itinerary.smbWrapper.smbContent.loaderContainer.itemsLoader.container}>
          <FlexItems>
            <Skeleton variant='rectangular' sx={theme.components.pages.itinerary.smbWrapper.smbContent.loaderContainer.itemsLoader.primaryItemLoader} />
            <Skeleton variant='rectangular' sx={theme.components.pages.itinerary.smbWrapper.smbContent.loaderContainer.itemsLoader.secondaryItemLoader} />
          </FlexItems>
          <Box>
            <Skeleton variant='rectangular' sx={theme.components.pages.itinerary.smbWrapper.smbContent.loaderContainer.itemsLoader.complimentaryItemLoader} />
          </Box>
        </FlexItems>
        :
        <BottomBar
          totalPrice={totalPrice}
          addOnType={tabValue === 1 ? 'Seat' : tabValue === 2 ? 'Meal' : 'Baggage'}
          totalAddOnCount={totalTravellers}
          smbData={selectedSmbData}
          discountPrice={discountPrice}
          isDiscountApplied={isDiscountApplied}
          onClick={handleContinue}
          selectedSegment={selectedSegment}
          isLoading={isLoading}
        />}
    </>
  );
};

export default OASMB;
