import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppBar, Box, IconButton, Stack, SxProps, Toolbar, Typography } from '@mui/material';
import { BookingStatusSkeleton } from '../pages/MyBooking/BookingStatus/BookingStatusSkeleton';
import { OAImage } from '.';
import { eventsTracker } from '../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { useSelector } from '../store';
import { getAuthInfo } from '../store/slices/authSlice';
import { useTheme } from '../config/theme/useTheme';
import FlexItemsCentered from './FlexItemsCentered';
import { getUserInputState } from '../store/slices/userInputSlice';
import { urlQueryParamString } from '../utils/urlQueryParamString';

export const HANDLER_NAMES = {
  GET_NIYO_COINS: 'eventCoins',
  // Add other handler names as needed
};

interface OAHeaderProps {
  title: string | ReactNode;
  subTitle?: string | ReactNode;
  showBackButton?: boolean;
  appBarSx?: SxProps;
  action?: ReactNode;
  loading?: boolean;
  backgroundImageText?: ReactNode;
}

export const OAHeader = ({
  title,
  subTitle,
  showBackButton = true,
  appBarSx,
  action,
  loading = false,
  backgroundImageText,
}: OAHeaderProps) => {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { deviceInfo } = useSelector(getAuthInfo);
  const { travelDetail } = useSelector(getUserInputState);
  const theme = useTheme();
  const isHomePage = window.location.pathname === '/' || window.location.pathname === '/home'; // Adjust '/home' as needed
  const shouldShowBackground = isHomePage && backgroundImageText;

  const handleBackBtnClick = () => {
    const isHomePage = window.location.pathname === '/' || window.location.pathname === '/home'; // Adjust '/home' as needed
    const hasHash = window.location.hash !== '';
    const isRedirectToApp = window?.location?.href?.includes('?redirectToApp');
    const isResultsPage = window.location.pathname === '/results';
    const isReviewPage = window.location.pathname === '/review';
    const isBookingPage = window?.location?.href?.includes('?review');
    const isBookingStatusPage = window?.location?.href?.includes('?overview');

    // If we're on homepage, always exit to app
    if (isHomePage) {
      if (window.flutter_inappwebview) {
        eventsTracker(
          {
            flowName: 'Flight',
            screenName: 'Home',
            ctaAction: 'ExitFlight',
            screenDuration: '1',
            UserAction: {
              userAction: 'user has exited flight',
            },
          },
          posthog
        );
        console.log('isHomePage swiped "window back"');
        window.back();
      }
      console.log('isHomePage swiped "window back return"');
      return;
    }

    // Handle other navigation cases
    if (hasHash || isRedirectToApp) {
      if (window.flutter_inappwebview) {
        console.log('isResultsPage swiped "window back"');
        window.back();
      }
    } else if (isBookingStatusPage) {
      navigate(`/my-bookings?review`);
    } else if (isBookingPage) {
      navigate('/');
    } else if (isReviewPage) {
      navigate(`/results?${urlQueryParamString(travelDetail)}`);
    } else {
      if (isResultsPage) {
        console.log('isResultsPage swiped "/"');
        navigate('/');
      } else {
        console.log('isResultsPage swiped "-1"');
        navigate(-1);
      }
    }
  };

  // Hardware back
  useEffect(() => {
    window.oaBack = () => {
      handleBackBtnClick();
    };
  }, []);

  // Calculate status bar height for iOS
  const ratio = window.devicePixelRatio || 1;
  const statusBarHeight = deviceInfo?.statusBarHeight
    ? parseInt(deviceInfo.statusBarHeight, 10)
    : 0;
  const scale = statusBarHeight > 0 ? Math.round(statusBarHeight / ratio) : 0;

  return (
    <AppBar
      position="static"
      sx={{
        boxShadow: 'none',
        paddingTop: scale > 0 ? `${scale}px` : '0',
        ...(theme?.components?.header?.styles?.default || {}),
        ...(shouldShowBackground ? theme?.components?.header?.styles?.home || {} : {}),
        ...appBarSx,
      }}
    >
      <Toolbar sx={{ paddingLeft: '16px', paddingRight: '0px' }}>
        {showBackButton && (
          <IconButton
            edge={false}
            aria-label="back"
            onClick={handleBackBtnClick}
            sx={{
              padding: '16px 12px 16px 22px',
              ml: '-40px',
              color: 'white',
            }}
          >
            {isHomePage ? (
              <FlexItemsCentered sx={theme?.components?.backBtn?.styles?.default}>
                <OAImage
                  src={theme.assets.images.icons.back.path}
                  folder={theme.assets.images.icons.back.folder}
                  alt="Go back"
                />
              </FlexItemsCentered>
            ) : (
              <OAImage
                src={theme.assets.images.icons.back.path}
                folder={theme.assets.images.icons.back.folder}
                alt="Go back"
              />
            )}
          </IconButton>
        )}

        {!loading ? (
          <Stack direction="column" justifyContent="flex-end" alignItems="flex-start">
            {typeof title === 'string' ? (
              <Typography variant="body1" sx={{ fontWeight: 400 }}>
                {title}
              </Typography>
            ) : (
              title
            )}

            {typeof subTitle === 'string' ? (
              <Typography variant="body3" sx={{ color: 'grey.800', fontWeight: 400 }}>
                {subTitle}
              </Typography>
            ) : (
              subTitle
            )}
          </Stack>
        ) : (
          <BookingStatusSkeleton />
        )}

        <Box sx={{ flexGrow: 1 }} />
        {action && action}
      </Toolbar>
      {backgroundImageText && backgroundImageText}
    </AppBar>
  );
};
