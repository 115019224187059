import { Box, SxProps } from "@mui/material"

const FlexItemsCentered = ({ children, sx, onClick }: { children: React.ReactNode, sx?: SxProps, onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={sx} onClick={onClick}>
      {children}
    </Box>
  )
}

export default FlexItemsCentered;