import { useCallback, useEffect, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Skeleton, Typography, IconButton } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {
  OACoinSwitch,
  OAFooter,
  OAHeader,
  OAHeaderSubTitle,
  OAHeaderTitle,
  OAImage,
} from '../../components';
import { useDispatch, useSelector } from '../../store';
import {
  getFilterState,
  getSearchFlightState,
  setFilter,
  setFlights,
  setSelectedFlight,
  setSort,
} from '../../store/slices/searchFlightSlice';
import { IFlight, ISegment } from '../../type';
import { getUserInputState } from '../../store/slices/userInputSlice';
import {
  filterFlightsBy,
  sortFlights,
  parseObject,
  getUniqueAirlines,
  checkFlights,
  getParamsBody,
} from '../../utils';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { useCreateOrderMutation } from '../../services/createOrderApi';
import { setMessage } from '../../store/slices/snackbarSlice';
import { setFlightData, setError, setSmbData } from '../../store/slices/flightOrderSlice';
import { usePostHog } from 'posthog-js/react';
import { useTheme } from '../../config/theme/useTheme';
import { setCoins, getUserInfo } from '../../store/slices/userInfoSlice';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { SORT_OPTIONS, TEST_COINS_WEB } from '../../constants';

import {
  countAirports,
  countDeparture,
  countFlightStops,
  countSupplierValues,
} from './getCountOfFilters';
import { parse } from 'date-fns';
import ConfirmFlightDrawer from './SearchPageDrawers/ConfirmFlight';
import EditSearchDrawer from './SearchPageDrawers/EditSearch';
import FilterAndSortContainer from './FilterAndSort/FilterAndSortContainer';
import {
  useFlightSearchQuery,
  useLazyFetchFareAndMiniRuleQuery,
} from '../../services/flightSearchApi';
import FlightContent from './FlightContent';
import { useOffers } from '../../hooks/useOffers';
import { TripType } from '../Flight/types';
import { getOffersState } from '../../store/slices/offersSlice';
import OAIconAndText from '../../components/OAIconAndText';
import FlexItems from '../../components/FlexItems';
import FlexItemsBetween from '../../components/FlexItemsBetween';
import { setSelectedTravellersIds } from '../../store/slices/travellerSlice';

const SearchResult = () => {
  const posthog = usePostHog();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  // Memoize query params to prevent unnecessary re-renders
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const { data: offers } = useSelector(getOffersState);

  const {
    data: flights,
    error,
    isError,
    refetch,
    isLoading,
    isSuccess,
  } = useFlightSearchQuery(getParamsBody(queryParams), {
    selectFromResult: ({ data, error, isError, isLoading, isFetching, isSuccess }) => ({
      data,
      error,
      isError,
      isLoading: isLoading && !data,
      isFetching,
      isSuccess,
    }),
  });

  // Memoize offers to prevent unnecessary recalculations
  const { applicableOffers, getTopBarBanner } = useOffers({
    currentPage: useMemo(() => ['ITINERARY', 'CONFIRM_ITINERARY'], []),
    isInternationalRoute: flights?.data?.international,
    tripType:
      flights?.data?.tripType === 'O' || flights?.data?.tripType === 'ONWARD'
        ? 'ONE_WAY'
        : ('ROUND_TRIP' as TripType),
    offers: offers,
  });
  const { burnCoinsFeat, srpBottomDrawerFeat } = useSelector(getFeatureFlag);
  const filterValue = useSelector(getFilterState);
  const [isUpdatingResults, setIsUpdatingResults] = useState<boolean>(true);
  const { sort, filter, selectedFlight } = useSelector(getSearchFlightState);
  const { travelDetail } = useSelector(getUserInputState);
  const [createOrder] = useCreateOrderMutation();
  const [flightOptions, setFlightOptions] = useState<any>({
    onwardFlightOptions: flights?.data?.results?.onward ?? [],
    returnFlightOptions: flights?.data?.results?.return ?? [],
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState<boolean>(false);
  const [filterData, setFilterData] = useState<any>(filterValue);
  const [startTime, setStartTime] = useState(Date.now());
  const [currentDirection, setCurrentDirection] = useState<number>(0); // 0 for from->to, 1 for to->from
  const [isConfirmFlight, setIsConfirmFlight] = useState<boolean>(false);
  const { coins } = useSelector(getUserInfo);
  const { isCoinsApplied } = useSelector(getUserInputState);
  const [tabValue, setTabValue] = useState<number>(0);
  const [isResultsLoading, setIsResultsLoading] = useState<boolean>(false);
  const [currentSortTab, setCurrentSortTab] = useState<number>(0);
  const isInternational = flights?.data?.international;
  const [trigger] = useLazyFetchFareAndMiniRuleQuery({});
  const [miniRuleData, setMiniRuleData] = useState<any>();
  const [isMiniRuleLoading, setIsMiniRuleLoading] = useState<boolean>(false);
  const [pendingApiCallForOrder, setPendingApiCallForOrder] = useState<boolean>(false);
  const tripType = travelDetail?.tripType;

  const searchId = flights?.data?.searchId;

  let shouldRenderOAFooter =
    tripType === 'R' &&
    !isInternational &&
    flightOptions?.onwardFlightOptions?.length !== 0 &&
    flightOptions?.returnFlightOptions?.length !== 0 &&
    !isError;

  useEffect(() => {
    setStartTime(Date.now());
    // reset the selectedTravellersIds on page load
    dispatch(setSelectedTravellersIds([]));
  }, []);

  useEffect(() => {
    if ((isSuccess || isError) && !isUpdatingResults && !isResultsLoading) {
      const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
      const totalTravelers =
        (travelDetail?.travellerCount?.adult || 0) +
        (travelDetail?.travellerCount?.child || 0) +
        (travelDetail?.travellerCount?.infant || 0);
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'SRP',
          ctaAction: 'LoadResult',
          screenDuration: totalScreenDuration?.toString(),
          UserAction: {
            userAction: isSuccess
              ? `User has landed on the search results page successfully`
              : 'User encountered an error on the search results page',
          },
          otherData: {
            from: travelDetail?.from?.iata,
            to: travelDetail?.to?.iata,
            departureDate: travelDetail?.tripStart?.date,
            numberOfTravellers: totalTravelers,
            class: travelDetail?.cabinType,
            destination: isInternational ? 'international' : 'domestic',
          },
        },
        posthog
      );
    }
  }, [isSuccess, isError, isUpdatingResults, isResultsLoading]);

  const toggleDrawer = (flight: IFlight | null = null) => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'SRP',
        ctaAction: 'FlightSelect',
        screenDuration: totalScreenDuration?.toString(),
        UserAction: {
          userAction: `user has closed the flight detail Drawer without clicking confirm`,
        },
      },
      posthog
    );
    if (!isOpen) {
      dispatch(setSelectedFlight(flight));
    } else if (tripType === 'O') {
      dispatch(setSelectedFlight({}));
      setIsConfirmFlight(false);
    } else {
      setIsConfirmFlight(false);
    }
    setIsOpen(!isOpen);
    setTabValue(0);
  };

  useEffect(() => {
    const fetchCoins = async () => {
      try {
        if (
          window.flutter_inappwebview &&
          window.niyo_coins &&
          typeof window.niyo_coins === 'function'
        ) {
          const res = await window.niyo_coins();
          const resParse = parseObject(res);
          const coinsBalance = resParse?.coinsBalance ?? 0;

          dispatch(setCoins(burnCoinsFeat ? coinsBalance : 0));
        } else {
          dispatch(setCoins(TEST_COINS_WEB));
        }
      } catch (error) {
        dispatch(setCoins(0));
      }
    };

    fetchCoins();
  }, []);

  useEffect(() => {
    setIsUpdatingResults(true);
  }, [location?.search]);

  useEffect(() => {
    refetch();
  }, [refetch]);

  useEffect(() => {
    if (flights?.data && flights?.data?.results?.onward) {
      const resultsOnward = filter
        ? filterFlightsBy(flights?.data?.results?.onward, filter)
        : flights?.data?.results?.onward;
      const resultsReturn = filter
        ? filterFlightsBy(flights?.data?.results?.return, filter)
        : flights?.data?.results?.return;
      const sortedFlightsOnward = sortFlights(resultsOnward, sort);
      const sortedFlightsReturn = sortFlights(resultsReturn, sort);
      setFlightOptions({
        onwardFlightOptions: sortedFlightsOnward,
        returnFlightOptions: sortedFlightsReturn,
      });
      dispatch(setFlights(flights?.data));
      setIsUpdatingResults(false);
      setIsResultsLoading(false);
    }
  }, [flights]);

  useEffect(() => {
    if (isError) {
      dispatch(setSort(null));
      setFilterData(null);
      dispatch(setFilter(null));
    }
  }, [isError]);

  const handleFlightSelect = useCallback(
    (flight: IFlight, type?: any) => {
      const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
      const totalTravelers =
        (travelDetail?.travellerCount?.adult || 0) +
        (travelDetail?.travellerCount?.child || 0) +
        (travelDetail?.travellerCount?.infant || 0);
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'SRP',
          ctaAction: 'FlightSelect',
          screenDuration: totalScreenDuration?.toString(),
          otherData: {
            from: travelDetail?.from?.iata,
            to: travelDetail?.to?.iata,
            departureDate: travelDetail?.tripStart?.date,
            numberOfTravellers: totalTravelers,
            class: travelDetail?.cabinType,
            price: flight?.price?.value,
            destination: isInternational ? 'international' : 'domestic',
          },
        },
        posthog
      );

      const nearbyAirports = {
        origin: flights?.data?.meta?.originNearbyAirports,
        destination: flights?.data?.meta?.destinationNearbyAirports,
      };

      if (tripType === 'O' || isInternational) {
        const selectedFlight = {
          onward: flight,
          return: {},
          searchId: flights?.data?.searchId,
          international: isInternational,
          nearbyAirports: nearbyAirports,
        };
        dispatch(setSelectedFlight(selectedFlight));
        if (srpBottomDrawerFeat) {
          setIsOpen(true);
          setIsConfirmFlight(true);
          setIsMiniRuleLoading(true);
          const request = {
            onwardFlight: flight,
            searchId,
            ...(tripType === 'R' && !isInternational && { returnFlight: selectedFlight?.return }),
          };
          trigger(request)
            .then((res: any) => {
              setIsMiniRuleLoading(false);
              setMiniRuleData(res?.data?.data);
            })
            .catch((error) => {
              setIsMiniRuleLoading(false);
              console.log('fare rule api failed');
            })
            .finally(() => {
              setIsMiniRuleLoading(false);
            });
        } else {
          setPendingApiCallForOrder(true);
        }
      } else {
        if (type === 'onward') {
          dispatch(
            setSelectedFlight({
              onward: flight,
              return: { ...selectedFlight?.return },
              searchId: flights?.data?.searchId,
              international: isInternational,
              nearbyAirports: nearbyAirports,
            })
          );
        } else {
          dispatch(
            setSelectedFlight({
              onward: { ...selectedFlight?.onward },
              return: flight,
              searchId: flights?.data?.searchId,
              international: isInternational,
              nearbyAirports: nearbyAirports,
            })
          );
        }
      }
    },
    [
      dispatch,
      posthog,
      startTime,
      tripType,
      isInternational,
      selectedFlight?.return,
      selectedFlight?.onward,
      flights?.data?.meta?.destinationNearbyAirports,
    ]
  );

  useEffect(() => {
    if (pendingApiCallForOrder && selectedFlight?.onward) {
      handleCreateFlightOrder();
      setPendingApiCallForOrder(false);
    }
  }, [selectedFlight, pendingApiCallForOrder]);

  const handleOpen = () => {
    let onwardFlightStart = parse(
      selectedFlight?.onward?.segments?.[0]?.departure?.time,
      'HH:mm',
      selectedFlight?.onward?.segments?.[0]?.departure?.date
    );
    let onwardFlightEnd = parse(
      selectedFlight?.onward?.segments?.[selectedFlight?.onward?.segments?.length - 1]?.arrival
        ?.time,
      'HH:mm',
      selectedFlight?.onward?.segments?.[selectedFlight?.onward?.segments?.length - 1]?.arrival
        ?.date
    );
    let returnFlightStart = parse(
      selectedFlight?.return?.segments?.[0]?.departure?.time,
      'HH:mm',
      selectedFlight?.return?.segments?.[0]?.departure?.date
    );
    let returnFlightEnd = parse(
      selectedFlight?.return?.segments?.[selectedFlight?.return?.segments?.length - 1]?.arrival
        ?.time,
      'HH:mm',
      selectedFlight?.return?.segments?.[selectedFlight?.return?.segments?.length - 1]?.arrival
        ?.date
    );

    const flightOverlapping = checkFlights(
      onwardFlightStart,
      onwardFlightEnd,
      returnFlightStart,
      returnFlightEnd
    );
    if (flightOverlapping?.error) {
      dispatch(setMessage(flightOverlapping.error));
    } else {
      setIsOpen(true);
      setIsConfirmFlight(true);
      setIsMiniRuleLoading(true);
      const request = {
        onwardFlight:
          tripType === 'R' && !isInternational ? selectedFlight?.onward : selectedFlight?.onward,
        searchId,
        ...(tripType === 'R' && !isInternational && { returnFlight: selectedFlight?.return }),
      };
      trigger(request)
        .then((res: any) => {
          setIsMiniRuleLoading(false);
          setMiniRuleData(res?.data?.data);
        })
        .catch((error) => {
          setIsMiniRuleLoading(false);
          console.log('fare rule api failed');
        })
        .finally(() => {
          setIsMiniRuleLoading(false);
        });
    }
  };

  const handleSort = (value: string) => {
    let sortedValue = SORT_OPTIONS?.find((obj: any) => obj?.value === value);
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'SRP',
        ctaAction: 'Sort',
        screenDuration: totalScreenDuration?.toString(),
        otherData: {
          sortby: sortedValue?.label,
        },
      },
      posthog
    );

    dispatch(setSort(value));
    if (currentSortTab === 0) {
      const sortedFlightsOnwards = sortFlights(flightOptions?.onwardFlightOptions, value);
      setFlightOptions((prevState: any) => ({
        ...prevState,
        onwardFlightOptions: sortedFlightsOnwards,
      }));
    } else {
      const sortedFlightsReturn = sortFlights(flightOptions?.returnFlightOptions, value);
      setFlightOptions((prevState: any) => ({
        ...prevState,
        returnFlightOptions: sortedFlightsReturn,
      }));
    }
  };

  const handleFilter = (filteringData: any) => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    Object.entries(filteringData)?.forEach(([filterType, filterSelected]) => {
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'SRP',
          ctaAction: 'Filter',
          screenDuration: totalScreenDuration?.toString(),
        },
        posthog
      );
    });

    if (filteringData) {
      dispatch(setFilter(filteringData));
      setFilterData(filteringData);

      const { returnDeparture, onwardDeparture, ...otherFilters } = filteringData;

      const filteredFlights = {
        onwardFlightOptions: filterFlightsBy(flights?.data?.results?.onward, {
          ...(onwardDeparture && { onwardDeparture }),
          ...otherFilters,
        }),
        returnFlightOptions: filterFlightsBy(flights?.data?.results?.return, {
          ...(returnDeparture && { returnDeparture }),
          ...otherFilters,
        }),
      };
      const sortedFlights = {
        onwardFlightOptions: sortFlights(filteredFlights.onwardFlightOptions, sort),
        returnFlightOptions: sortFlights(filteredFlights.returnFlightOptions, sort),
      };

      setFlightOptions(sortedFlights);
    }
  };

  const handleClearSort = () => {
    dispatch(setSort(null));
    if (filter) {
      const filteredFlightsOnwards = filterFlightsBy(flights?.data?.results?.onward, filter);
      const filteredFlightsReturn = filterFlightsBy(flights?.data?.results?.return, filter);
      setFlightOptions({
        onwardFlightOptions: filteredFlightsOnwards,
        returnFlightOptions: filteredFlightsReturn,
      });
    } else {
      setFlightOptions({
        onwardFlightOptions: flights?.data?.results?.onward,
        returnFlightOptions: flights?.data?.results?.return,
      });
    }
  };

  const handleClearFilter = () => {
    setFilterData(null);
    dispatch(setFilter(null));
    if (sort) {
      const sortedFlightsOnward = sortFlights(flights?.data?.results?.onward, sort);
      const sortedFlightsReturn = sortFlights(flights?.data?.results?.return, sort);
      setFlightOptions({
        onwardFlightOptions: sortedFlightsOnward,
        returnFlightOptions: sortedFlightsReturn,
      });
    } else {
      setFlightOptions({
        onwardFlightOptions: flights?.data?.results?.onward,
        returnFlightOptions: flights?.data?.results?.return,
      });
    }
  };

  const handleCreateFlightOrder = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'SRP',
        ctaAction: 'FlightConfirm',
        screenDuration: totalScreenDuration?.toString(),
        UserAction: {
          userAction: 'user has clicked the confirm button in bottom sheet',
        },
      },
      posthog
    );
    dispatch(setFlightData({}));
    setIsOpen(false);
    navigate('/review');
    dispatch(
      setSmbData({
        userSelectedJourneyAncillaries: [],
      })
    );
    const draft = {
      onwardFlight: selectedFlight?.onward,
      searchId: flights?.data?.searchId,
      ...(isInternational && tripType === 'R'
        ? { returnFlight: selectedFlight?.onward }
        : selectedFlight?.return &&
          Object.keys(selectedFlight?.return).length > 0 && {
            returnFlight: selectedFlight?.return,
          }),
    };
    createOrder(draft)
      .then((res: any) => {
        if (res?.error) {
          dispatch(setError(res?.error));
          // dispatch(setMessage('Order creation failed'));
        } else {
          dispatch(setFlightData(res?.data?.data));
        }
      })
      .catch((error: any) => {
        console.error('An error occurred:', error);
      });
  };

  const toggleEditDrawer = () => {
    if (!isEditDrawerOpen) {
      const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
      const totalTravelers =
        (travelDetail?.travellerCount?.adult || 0) +
        (travelDetail?.travellerCount?.child || 0) +
        (travelDetail?.travellerCount?.infant || 0);
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: 'SRP',
          ctaAction: 'Edit',
          screenDuration: totalScreenDuration?.toString(),
          otherData: {
            from: travelDetail?.from?.iata,
            to: travelDetail?.to?.iata,
            departureDate: travelDetail?.tripStart?.date,
            class: travelDetail?.cabinType,
            numberOfTravellers: totalTravelers,
          },
        },
        posthog
      );
    }
    setIsEditDrawerOpen(!isEditDrawerOpen);
  };

  const countOfSuppliers = countSupplierValues([
    ...(flights?.data?.results?.onward || []),
    ...(flights?.data?.results?.return || []),
  ]);

  const countOfStopsOnward = countFlightStops([
    ...(flights?.data?.results?.onward || []),
    ...(flights?.data?.results?.return || []),
  ]);

  const countOfDeparture = countDeparture(flights?.data?.results?.onward);
  const countOfReturnDeparture = countDeparture(flights?.data?.results?.return);

  const countOfAirports = countAirports([
    ...(flights?.data?.results?.onward || []),
    ...(flights?.data?.results?.return || []),
  ]);

  const paramsBody = getParamsBody(queryParams);

  const from = travelDetail?.from?.iata;
  const to = travelDetail?.to?.iata;

  useEffect(() => {
    if (tripType === 'R' && !isInternational) {
      let onwardFlight = flightOptions?.onwardFlightOptions?.find(
        (obj: any) => obj?.id === selectedFlight.onward?.id
      );
      let returnFlight = flightOptions?.returnFlightOptions?.find(
        (obj: any) => obj?.id === selectedFlight?.return?.id
      );

      const nearbyAirports = {
        origin: flights?.data?.meta?.originNearbyAirports,
        destination: flights?.data?.meta?.destinationNearbyAirports,
      };

      dispatch(
        setSelectedFlight({
          onward: onwardFlight ?? flightOptions?.onwardFlightOptions[0],
          return: returnFlight ?? flightOptions?.returnFlightOptions[0],
          searchId: flights?.data?.searchId,
          international: isInternational,
          nearbyAirports: nearbyAirports,
        })
      );
    }

    dispatch(
      setSmbData({
        userSelectedJourneyAncillaries: [],
      })
    );
  }, [flightOptions]);

  const disableConfirmButton = !selectedFlight?.onward?.id || !selectedFlight?.return?.id;

  const onwardSegments = selectedFlight?.onward?.segments?.filter(
    (segment: ISegment) => segment?.departureType === 'ONWARD'
  );
  const returnSegments = isInternational
    ? selectedFlight?.onward?.segments?.filter(
        (segment: ISegment) => segment?.departureType === 'RETURN'
      )
    : selectedFlight?.return?.segments;

  const uniqueAirlinesOnward = getUniqueAirlines(selectedFlight?.onward?.segments);
  const uniqueAirlinesReturn = getUniqueAirlines(selectedFlight?.return?.segments);

  const onwardPrice = selectedFlight?.onward?.price?.display;
  const returnPrice = selectedFlight?.return?.price?.display;
  const totalPrice =
    selectedFlight?.onward?.price?.value +
    (selectedFlight?.return && Object?.keys(selectedFlight?.return)?.length > 0
      ? selectedFlight?.return?.price?.value
      : 0);
  const switchToggleLabels = [`${from} -> ${to}`, `${to} -> ${from}`];

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  const nearbyAirports = {
    origin: flights?.data?.meta?.originNearbyAirports,
    destination: flights?.data?.meta?.destinationNearbyAirports,
  };

  return (
    <>
      <Box sx={theme.components.pages.searchResults.container}>
        <OAHeader
          title={<OAHeaderTitle travelDetail={travelDetail} params={paramsBody} />}
          subTitle={<OAHeaderSubTitle travelDetail={travelDetail} />}
          action={
            <IconButton
              aria-label="edit"
              onClick={toggleEditDrawer}
              size="large"
              sx={{ mr: '-14px' }}
            >
              <OAImage
                src={theme.assets.images.icons.edit.path}
                folder={theme.assets.images.icons.edit.folder}
                alt="edit"
              />
            </IconButton>
          }
        />
        <OACoinSwitch />
        <FilterAndSortContainer
          isLoading={isLoading}
          isUpdatingResults={isUpdatingResults}
          isResultsLoading={isResultsLoading}
          isError={isError}
          filterData={filterData}
          flights={flights}
          countOfSuppliers={countOfSuppliers}
          countOfStopsOnward={countOfStopsOnward}
          countOfDeparture={countOfDeparture}
          countOfReturnDeparture={countOfReturnDeparture}
          countOfAirports={countOfAirports}
          currentSortTab={currentSortTab}
          handleSort={handleSort}
          handleFilter={handleFilter}
          handleClearSort={handleClearSort}
          setFilterData={setFilterData}
          handleClearFilter={handleClearFilter}
          setCurrentSortTab={setCurrentSortTab}
        />
        {tripType === 'R' && !isInternational && (
          <>
            {(isLoading || isUpdatingResults || isResultsLoading) && !isError ? (
              <FlexItems sx={theme.components.pages.searchResults.loadingContainer}>
                <Skeleton sx={theme.components.pages.searchResults.loadingContainer.loadingItem} />
                <Skeleton sx={theme.components.pages.searchResults.loadingContainer.loadingItem} />
              </FlexItems>
            ) : (
              <FlexItemsBetween
                sx={theme.components.pages.searchResults.locationContainer.container}
              >
                <Typography
                  textAlign="center"
                  width="100%"
                  variant="body2"
                  display="inline-flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {from}{' '}
                  <ArrowForwardIcon
                    sx={{ mx: '4px', height: '16px', width: '18px', color: '#5A6068' }}
                  />{' '}
                  {to}
                </Typography>
                <Typography color="#DEE2E1">|</Typography>
                <Typography
                  textAlign="center"
                  width="100%"
                  variant="body2"
                  display="inline-flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  {to}{' '}
                  <ArrowForwardIcon
                    sx={{ mx: '4px', height: '16px', width: '18px', color: '#5A6068' }}
                  />{' '}
                  {from}
                </Typography>
              </FlexItemsBetween>
            )}
          </>
        )}
      </Box>
      {applicableOffers?.length > 0 &&
        applicableOffers?.map((offer) => {
          const topBarBanner = getTopBarBanner(offer?.offerId, 'SRP');
          if (!topBarBanner?.htmlText) return null;
          return (
            <OAIconAndText
              iconSrc={'badge-percent.svg'}
              text={topBarBanner?.htmlText ?? ''}
              isHtml={true}
              bgColor={topBarBanner?.bgColor ?? ''}
            />
          );
        })}
      <Box>
        <FlightContent
          isError={isError}
          error={error}
          refetch={refetch}
          isLoading={isLoading}
          isUpdatingResults={isUpdatingResults}
          isResultsLoading={isResultsLoading}
          flights={flights}
          flightOptions={flightOptions}
          tripType={tripType}
          isInternational={isInternational}
          filter={filter}
          sort={sort}
          selectedFlight={selectedFlight}
          handleFlightSelect={handleFlightSelect}
          toggleEditDrawer={toggleEditDrawer}
          handleClearFilter={handleClearFilter}
          nearbyAirports={nearbyAirports}
        />
      </Box>
      {shouldRenderOAFooter && (
        <OAFooter
          priceLoading={isLoading || isUpdatingResults || isResultsLoading}
          onwardAndReturnData={selectedFlight}
          // onClick={handleOpen}
          onClick={srpBottomDrawerFeat ? handleOpen : handleCreateFlightOrder}
          disabled={disableConfirmButton}
          isSearchPage
          isDomesticReturn={true}
        />
      )}
      <ConfirmFlightDrawer
        isOpen={isOpen}
        toggleDrawer={toggleDrawer}
        isLoading={isLoading}
        selectedFlight={selectedFlight}
        travelDetail={travelDetail}
        tripType={tripType}
        tabValue={tabValue}
        setTabValue={setTabValue}
        onwardSegments={onwardSegments}
        returnSegments={returnSegments}
        isConfirmFlight={isConfirmFlight}
        handleCreateFlightOrder={handleCreateFlightOrder}
        flights={flights}
        isInternational={isInternational}
        uniqueAirlinesOnward={uniqueAirlinesOnward}
        uniqueAirlinesReturn={uniqueAirlinesReturn}
        onwardPrice={onwardPrice}
        returnPrice={returnPrice}
        totalPrice={totalPrice}
        isCoinsApplied={isCoinsApplied}
        coins={coins}
        switchToggleLabels={switchToggleLabels}
        miniRuleData={miniRuleData}
        isMiniRuleLoading={isMiniRuleLoading}
      />
      <EditSearchDrawer
        isEditDrawerOpen={isEditDrawerOpen}
        toggleEditDrawer={toggleEditDrawer}
        isUpdatingResults={isUpdatingResults}
        setIsResultsLoading={setIsResultsLoading}
        setFilterData={setFilterData}
        filterData={filterData}
        setCurrentDirection={setCurrentDirection}
      />
    </>
  );
};

export default SearchResult;
