import { FC, useState } from 'react';
import { Box, Container, Divider, Typography } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { OAImage, OASwipeableDrawer } from '../../../components';
import FlexItemsBetween from '../../../components/FlexItemsBetween';
import { useTheme } from '../../../config/theme/useTheme';
import { ThemeConfig } from '../../../config/theme/types';

interface CustomSectionProps {
  theme: ThemeConfig;
  icon: string;
  title: string;
  description: string;
  onClick?: () => void;
}

const CustomSection: FC<CustomSectionProps> = ({ theme, icon, title, description, onClick }) => (
  <Container onClick={onClick}>
    <FlexItemsBetween>
      <Box sx={theme.components.pages.bookingStatus.contactSupport.customSection.subContainer}>
        <OAImage src={icon} folder="icons" alt="support" sx={{ mt: '4px' }} />

        <Box sx={theme.components.pages.bookingStatus.contactSupport.customSection.contentWrapper}>
          <Typography variant="body2" sx={theme.components.pages.bookingStatus.contactSupport.customSection.title}>
            {title}
          </Typography>
          <Typography variant="body3" sx={theme.components.pages.bookingStatus.contactSupport.customSection.description}>
            {description}
          </Typography>
        </Box>
      </Box>
      <ChevronRightIcon sx={theme.components.pages.bookingStatus.contactSupport.customSection.icon} />
    </FlexItemsBetween>
  </Container>
);
const ContactSupport = ({ onClick, bookingId }: { onClick: any; bookingId: any }) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDrawer = () => setIsOpen((prev) => !prev);

  const handleEmailClick = () => {
    if (
      window?.flutter_inappwebview &&
      window.niyo_open_url &&
      typeof window.niyo_open_url === 'function'
    ) {
      const strEmail = `mailto:traveldesk@goniyo.com?subject=${bookingId} : Need help with my flight booking`;
      window.niyo_open_url(strEmail);
    } else {
      console.log('only allowed in mobile');
    }
  };

  return (
    <>
      <Container sx={theme.components.pages.bookingStatus.contactSupport.container} onClick={toggleDrawer}>
        <FlexItemsBetween>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            Contact support
          </Typography>
          <ChevronRightIcon />
        </FlexItemsBetween>
      </Container>
      <OASwipeableDrawer
        title="Contact support"
        open={isOpen}
        isContainer={false}
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
      >
        <CustomSection
          theme={theme}
          onClick={onClick}
          icon="chatbot.svg"
          title="Chat support"
          description="Searching for something? We're here for you. Feel free to ask!"
        />
        <Divider sx={theme.components.pages.bookingStatus.contactSupport.divider} />
        <CustomSection
          theme={theme}
          onClick={handleEmailClick}
          icon="email-support.svg"
          title="Email"
          description="traveldesk@goniyo.com"
        />
        <Box sx={{ height: '24px' }}></Box>
      </OASwipeableDrawer>
    </>
  );
};

export default ContactSupport;
