import React from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import { OAImage } from '../../components';
import { useDispatch } from '../../store';
import { setMessage } from '../../store/slices/snackbarSlice';
import FlexItemsBetween from '../../components/FlexItemsBetween';
import FlexItems from '../../components/FlexItems';

interface TravellerCountProps {
  label: string;
  countInit: number;
  onChange?: any;
  maxCount: number;
  totalPax: number;
}

const TravellerCount: React.FC<TravellerCountProps> = ({
  label,
  countInit,
  onChange,
  maxCount,
  totalPax,
}) => {
  const dispatch = useDispatch();

  const getAgeInfo = (label: string) => {
    switch (label) {
      case 'Adults':
        return '12+ Years';
      case 'Children':
        return '2 - 12 Years';
      default:
        return 'Below 2 Years';
    }
  };

  const isMinusDisabled = countInit === (label === 'Adults' ? 1 : 0);
  const isPlusDisabled = countInit >= maxCount;

  const handleParentClick = (e: any) => {
    // Check if the disabled icon button was clicked
    if (isPlusDisabled) {
      if (label === 'Infant' && totalPax < 9) {
        dispatch(setMessage(`Infants cannot exceed the number of adults`));
      } else {
        dispatch(setMessage(`Total number of travelers cannot exceed 9`));
      }
    }
  };

  return (
    <FlexItemsBetween sx={{ mb: '24px' }}>
      <Box>
        <Typography variant="body2" sx={{ fontWeight: 500 }}>
          {label}
        </Typography>
        <Typography variant="body3" sx={{ color: 'text.secondary' }}>
          {getAgeInfo(label)}
        </Typography>
      </Box>
      <FlexItems>
        <IconButton onClick={() => onChange(countInit - 1)} disabled={isMinusDisabled}>
          <OAImage
            src={isMinusDisabled ? 'minus-disabled.svg' : 'minus-enabled.svg'}
            alt="minus"
            folder="icons"
          />
        </IconButton>
        <Typography variant="body1" sx={{ width: '30px', textAlign: 'center' }}>
          {countInit}
        </Typography>
        <Box onClick={handleParentClick}>
          <IconButton
            onClick={() => onChange(countInit + 1)}
            disabled={isPlusDisabled}
            sx={{ color: isPlusDisabled ? '#C7CCCA' : '#009E82', pr: 0 }}
          >
            <OAImage
              src={isPlusDisabled ? 'plus-disabled.svg' : 'plus-enabled.svg'}
              alt="plus"
              folder="icons"
            />
          </IconButton>
        </Box>
      </FlexItems>
    </FlexItemsBetween>
  );
};

export default TravellerCount;
