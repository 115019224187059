import { format, isAfter, isBefore, isValid, parse, startOfDay } from 'date-fns';

export const formatDate = (value: string): string => {
  const cleaned = value.replace(/\D/g, '').slice(0, 8); // max 8 digits for ddmmyyyy
  if (!cleaned) return '';

  let formatted = cleaned.substring(0, Math.min(2, cleaned.length));
  if (cleaned.length >= 2) {
    formatted += '/';
  }
  if (cleaned.length > 2) {
    formatted += cleaned.substring(2, Math.min(4, cleaned.length));
    if (cleaned.length >= 4) {
      formatted += '/';
    }
  }
  if (cleaned.length > 4) {
    formatted += cleaned.substring(4);
  }
  return formatted;
};

export const formatFieldName = (field: string): string => {
  // Inserts a space before all caps and capitalize the first letter
  const result = field.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const validateFieldOnChange = (name: string, value: string, isInternational: boolean) => {
  if (!value) {
    return '';
  }

  // Skip passport validations if traveller is not international
  if (
    !isInternational &&
    ['passportNumber', 'passportIssueDate', 'passportExpiry'].includes(name)
  ) {
    return '';
  }

  if (['firstName', 'lastName'].includes(name)) {
    if (!/^[A-Za-z ]+$/.test(value)) {
      return `${formatFieldName(name)} should contain letters and spaces only`;
    }
  }

  if (name === 'passportNumber') {
    if (!/^[A-Za-z0-9]+$/.test(value)) {
      return `${formatFieldName(name)} should contain alphabets and numbers only`;
    }
  }

  if (['dob', 'passportIssueDate', 'passportExpiry'].includes(name)) {
    if (value.length === 10) {
      const dateObj = parse(value, 'dd/MM/yyyy', new Date());
      if (!isValid(dateObj) || format(dateObj, 'dd/MM/yyyy') !== value) {
        return 'Please enter a valid date.';
      }

      const today = startOfDay(new Date());

      if (name === 'dob' || name === 'passportIssueDate') {
        if (!isBefore(dateObj, today)) {
          return 'The date must be in the past';
        }
      }

      if (name === 'passportExpiry') {
        if (!isAfter(dateObj, today)) {
          return 'The Date must be in the future';
        }
      }
    }
  }

  return '';
};

export const validateFieldOnBlur = (name: string, value: string) => {
  if (!value) {
    return '';
  }

  if (['firstName', 'lastName'].includes(name)) {
    if (value?.length < 3) {
      return `${formatFieldName(name)} must contain at least 2 characters`;
    }
  }

  if (['dob', 'passportIssueDate', 'passportExpiry'].includes(name)) {
    if (value.length !== 10) {
      return 'Please enter a complete date';
    }
  }
  return '';
};
