import { Box, Typography } from '@mui/material';
import { FC } from 'react';
import { OAImage } from '../OAImage';

interface AncillaryRowProps {
  label: string;
  value: string;
  icon: string;
  onAddClick: () => void;
  isClickAllowed: boolean;
}
const AncillaryRow: FC<AncillaryRowProps> = ({ label, value, icon, onAddClick, isClickAllowed }) => {
  return (
    <Box 
      display="flex" 
      alignItems="center"
      justifyContent="space-between"
      my="10px"
      sx={{ 
        cursor: 'pointer',
        WebkitTapHighlightColor: 'transparent',
        '&:active': { backgroundColor: 'transparent' },
      }}
    >
      <Box display="flex" alignItems="center">
        <OAImage src={icon} folder="icons" alt="seat" />
        <Typography variant="body3" color="text.secondary" ml="8px">
          {label}
        </Typography>
      </Box>
      <Typography
        variant="body3"
        color={isClickAllowed ? '#009E82' : 'text.secondary'}
        fontWeight={isClickAllowed ? 600 : 400}
        sx={{
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          width: '100%',
          textOverflow: 'ellipsis',
          ml: '25px',
          textAlign: 'right',
        }}
        onClick={isClickAllowed ? onAddClick : undefined}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default AncillaryRow;
