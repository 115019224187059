import React from 'react';
import { Box } from '@mui/material';
import { OAEmpty, OAImage, OALoadingSearch } from '../../components';
import FlightsList from './FlightsList';
import ReturnsFlightList from './ReturnFlightsList';
import { useTheme } from '../../config/theme/useTheme';

const FlightContent = ({
  isError,
  error,
  refetch,
  isLoading,
  isUpdatingResults,
  isResultsLoading,
  flights,
  flightOptions,
  tripType,
  isInternational,
  filter,
  selectedFlight,
  handleFlightSelect,
  toggleEditDrawer,
  handleClearFilter,
  nearbyAirports,
  sort,
}: any) => {
  const theme = useTheme();
  if (isError) {
    return isInvalidDateError(error) ? (
      <OAEmpty
        bodytext="Invalid date. Try a new search."
        buttonText="Edit Search"
        onClickBtn={toggleEditDrawer}
      />
    ) : (
      <OAEmpty onClickBtn={refetch} error={error} />
    );
  }

  if (isLoading || isUpdatingResults || isResultsLoading) {
    // return <FlightCardSkeleton tripType={tripType} isInternational={isInternational} count={10} />;
    return <OALoadingSearch />;
  }

  if (
    filter &&
    !flightOptions?.onwardFlightOptions?.length &&
    !flightOptions?.returnFlightOptions?.length
  ) {
    return (
      <OAEmpty
        illustration={
          <OAImage src={theme.assets.images.icons.primaryEmptyAsset.path} alt="empty" />
        }
        bodytext="No flights are available for this route"
        buttonText="Clear Filter"
        onClickBtn={handleClearFilter}
      />
    );
  }
  const onwardFlights = flightOptions?.onwardFlightOptions || [];

  const lowestPrice = Math.min(...onwardFlights?.map((flight: any) => flight?.price?.value));

  // Find all flights with the lowest price
  const lowestPriceFlights = onwardFlights?.filter(
    (flight: any) => flight?.price?.value === lowestPrice
  );

  // Determine if there is a unique lowest price flight
  const uniqueLowestPriceFlight = lowestPriceFlights?.length === 1 ? lowestPriceFlights[0] : null;

  // Find the shortest duration
  const shortestDuration = Math.min(
    ...onwardFlights?.map((flight: any) => flight?.durationInfo?.seconds)
  );

  // Find all flights with the shortest duration
  const shortestDurationFlights = onwardFlights?.filter(
    (flight: any) => flight?.durationInfo?.seconds === shortestDuration
  );

  // Determine if there is a unique fastest flight
  const uniqueFastestFlight =
    shortestDurationFlights?.length === 1 ? shortestDurationFlights[0] : null;

  const isFastestAndLowestSame =
    uniqueFastestFlight &&
    uniqueLowestPriceFlight &&
    uniqueFastestFlight?.id === uniqueLowestPriceFlight?.id;

  // finding all non-stop flights
  const nonStopFlights = onwardFlights?.filter((flight: any) => flight?.stops?.value === 0);

  // finding the lowest price among non-stop flights
  const lowestNonStopPrice = nonStopFlights?.length
    ? Math.min(...nonStopFlights?.map((flight: any) => flight?.price?.value))
    : null;

  // finding all non-stop flights with the lowest price
  const cheapestNonStopFlights = nonStopFlights?.filter(
    (flight: any) => flight?.price?.value === lowestNonStopPrice
  );

  // getting the unique cheapest non-stop flight if there's only one
  const cheapestNonStopFlight =
    cheapestNonStopFlights?.length === 1 ? cheapestNonStopFlights[0] : null;

  if (flights?.data?.results?.onward?.length) {
    return (
      <Box>
        {tripType === 'O' || isInternational ? (
          <FlightsList
            flights={flightOptions?.onwardFlightOptions}
            onFlightSelect={handleFlightSelect}
            tripType={tripType}
            isInternational={isInternational}
            selectedFlight={selectedFlight?.onward}
            fastestFlight={uniqueFastestFlight}
            lowestPriceFlight={uniqueLowestPriceFlight}
            isFastestAndLowestSame={isFastestAndLowestSame}
            nearbyAirports={nearbyAirports}
            cheapestNonStopFlight={cheapestNonStopFlight}
            filters={filter}
            sort={sort}
          />
        ) : (
          <ReturnsFlightList
            flightOptions={flightOptions}
            selectedFlight={selectedFlight}
            tripType={tripType}
            isInternational={isInternational}
            onSelect={handleFlightSelect}
            handleClearFilter={handleClearFilter}
          />
        )}
      </Box>
    );
  }

  return (
    <OAEmpty
      illustration={<OAImage src={theme.assets.images.icons.primaryEmptyAsset.path} alt="empty" />}
      bodytext="No flights are available for this route"
      buttonText="Edit Search"
      onClickBtn={toggleEditDrawer}
    />
  );
};

const isInvalidDateError = (error: any) => {
  return error?.data?.errors?.some((e: any) => e.field === 'tripStart.date');
};

// export default FlightContent;
export default React.memo(FlightContent, (prevProps, nextProps) => {
  return (
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.isUpdatingResults === nextProps.isUpdatingResults &&
    prevProps.isResultsLoading === nextProps.isResultsLoading &&
    prevProps.isError === nextProps.isError &&
    prevProps.tripType === nextProps.tripType &&
    prevProps.isInternational === nextProps.isInternational &&
    prevProps.filter === nextProps.filter &&
    prevProps.selectedFlight === nextProps.selectedFlight &&
    JSON.stringify(prevProps.flightOptions) === JSON.stringify(nextProps.flightOptions)
  );
});
