import { Box, Card, Checkbox, Divider, styled, Typography } from '@mui/material';
import { OAImage } from './OAImage';
import { OACheckbox } from './basic/OACheckbox';
import { eventsTracker } from '../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { useEffect, useState } from 'react';
import { OAButton } from './basic/OAButton';
import { useTheme } from '../config/theme/useTheme';

const ImgBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  height: '30px',
  width: '30px',
  backgroundColor: '#FFFFFF',
  justifyContent: 'center',
  borderRadius: '50%',
});

const OAPremiumMembership = ({
  convenienceFee,
  isPremiumSelected,
  goldDetails,
  setIsPremiumSelected,
}: {
  convenienceFee: string;
  isPremiumSelected: boolean;
  goldDetails: any;
  setIsPremiumSelected: (val: boolean) => void;
}) => {
  const posthog = usePostHog();
  const theme = useTheme();
  const [startTime, setStartTime] = useState(Date.now());

  const url = 'https://www.goniyo.com/oa/premium?spoke=flight';

  const checkBenefits = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Trips',
        ctaAction: 'ViewBenefit',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    window.niyo_open_native_routes(url);
  };

  const onPremiumSelected = (value: boolean) => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'PayConfirm',
        ctaAction: value ? 'AddPremium' : 'RemovePremium',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );

    setIsPremiumSelected(value);
  };

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  return (
    <>
      <Card sx={{ bgcolor: '#F4F6F5' }}>
        <Box display="flex" justifyContent="space-between" mb="15px">
          <Box display="flex" alignItems="center">
            <OAImage
              src={theme.assets.images.icons.premiumGold.path}
              folder={theme.assets.images.icons.premiumGold.folder}
              alt="niyo-premium"
              style={{ height: '40px' }}
            />
            <Typography
              variant="body1"
              sx={{ fontWeight: 600, lineHeight: '20px', letterSpacing: '0.15px' }}
            >
              Niyo
              <br />
              Premium
            </Typography>
          </Box>
          <Typography
            variant="body3"
            sx={{ textDecoration: 'underline', fontWeight: 400, lineHeight: '20px' }}
            color="#009E82"
            onClick={checkBenefits}
          >
            View all benefits
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mb="5px">
          <ImgBox>
            <OAImage
              src={theme.assets.images.icons.airplaneDuotone.path}
              alt="coins"
              sx={{ width: '20px' }}
              folder={theme.assets.images.icons.airplaneDuotone.folder}
            />
          </ImgBox>

          <Typography variant="body3" ml="11px" sx={{ lineHeight: '20px' }}>
            Pay <strong>₹0 convenience fees</strong> on flight & visa
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <ImgBox>
            <OAImage src={theme.assets.images.icons.coin.path} alt="coins" sx={{ width: '20px' }} />
          </ImgBox>

          <Typography variant="body3" ml="11px" sx={{ lineHeight: '20px' }}>
            Welcome Niyo coins worth <strong>₹500</strong>
          </Typography>
        </Box>
        <Divider
          sx={{
            border: 0,
            borderTop: '1px solid',
            borderImageSource:
              'linear-gradient(90deg, rgba(0, 0, 0, 0.3) 0%, #000000 39.5%, rgba(0, 0, 0, 0) 100%)',
            borderImageSlice: 1,
            mt: '10px',
            mb: '15px',
          }}
        />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="baseline">
            <Typography variant="h2" sx={{ mr: '5px' }}>
              ₹{goldDetails?.planAmount?.totalAmount}
            </Typography>
            <Typography variant="body1" sx={{ verticalAlign: 'sub', fontWeight: 600 }}>
              / year
            </Typography>
          </Box>
          <OAButton
            onClick={() => onPremiumSelected(!isPremiumSelected)}
            size="small"
            sx={{
              color: isPremiumSelected ? '#14191F' : '#fff',
              backgroundColor: isPremiumSelected ? '#FFE5E2' : '#0A1017',
              '&&:hover': {
                backgroundColor: isPremiumSelected ? '#FFE5E2' : '#0A1017',
              },
              '&&:focus': {
                backgroundColor: isPremiumSelected ? '#FFE5E2' : '#0A1017',
              },
              display: 'flex',
              width: 'fit-content',
              minWidth: '75px',
            }}
          >
            <Box display="flex" alignItems="center">
              {!isPremiumSelected && <OAImage src="add-white.svg" folder="icons" alt="add" />}
              <Typography
                variant="body3"
                sx={{ color: isPremiumSelected ? '#14191F' : '#fff', ml: '5px' }}
              >
                {isPremiumSelected ? 'Remove' : 'Add'}
              </Typography>
            </Box>
          </OAButton>
        </Box>
      </Card>
      {/* <Box>
        <Typography mb="20px" fontWeight="600">
          <span style={{ textDecoration: 'line-through', marginRight: '2px' }}>
            ₹{convenienceFee}
          </span>{' '}
          <span style={{ color: '#009E82', marginLeft: '2px' }}>₹0 convenience fee</span> with{' '}
          <br />
          <span> Niyo Premium</span>
        </Typography>
        <Card sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box display="flex" alignItems="center" gap="8px">
            <OAImage src="premium-gold.svg" folder="offer" alt="niyo-premium" />
            <Box>
              <Typography display="flex" flexDirection="column">
                Gold at just ₹{goldDetails?.planAmount?.totalAmount}/year
                <Typography
                  variant="body3"
                  sx={{ textDecoration: 'underline' }}
                  color="#009E82"
                  onClick={checkBenefits}
                >
                  View Benefits
                </Typography>
              </Typography>
            </Box>
          </Box>
          <Box>
            <OACheckbox
              checked={isPremiumSelected}
              onChange={() => onPremiumSelected(!isPremiumSelected)}
            />
          </Box>
        </Card>
      </Box> */}
    </>
  );
};

export default OAPremiumMembership;
