export const urlQueryParamString = (travelDetail: any) => {
  if (!travelDetail) return '';

  const { travellerCount = {}, tripEnd, cabinType, fareGroup }: any = travelDetail;
  const { adult = 0, child = 0, infant = 0 } = travellerCount;

  const tripType = tripEnd?.date ? 'R' : 'O';
  const traveller = `adults=${adult}&childs=${child}&infants=${infant}`;

  const fromTo =
    `${travelDetail?.from?.iata}` +
    `&fromCity=${travelDetail?.from?.city}` +
    `&to=${travelDetail?.to?.iata}` +
    `&toCity=${travelDetail?.to?.city}` +
    `&fromDate=${travelDetail?.tripStart?.date}` +
    `${travelDetail?.tripEnd?.date ? `&toDate=${travelDetail?.tripEnd?.date}` : ''}`;

  return `tripType=${tripType}&cabin=${cabinType}&${traveller}&from=${fromTo}`;
};
