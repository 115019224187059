import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { usePostHog } from 'posthog-js/react';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';

import { OAChip, OAImage } from '../../components';
import { setCoins } from '../../store/slices/userInfoSlice';
import { getAuthInfo } from '../../store/slices/authSlice';
import { getFeatureFlag } from '../../store/slices/featuresSlice';
import { eventsTracker } from '../../utils/ctEventsTracking';
import { formatToINR, parseObject } from '../../utils';
import { TEST_COINS_WEB } from '../../constants';
import { useTheme } from '../../config/theme/useTheme';

const CHIP_STYLE = {
  px: 0,
  minHeight: '40px',
};

export const HeaderActionFlight: React.FC<{
  totalActiveBookings: number;
}> = ({ totalActiveBookings }) => {
  const posthog = usePostHog();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { user, token } = useSelector(getAuthInfo);
  const { burnCoinsFeat } = useSelector(getFeatureFlag);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [coinsLocal, setCoinsLocal] = useState<number>(0);
  const [startTime] = useState<number>(Date.now());
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const fetchCoins = useCallback(async () => {
    try {
      if (
        window.flutter_inappwebview &&
        window.niyo_coins &&
        typeof window.niyo_coins === 'function'
      ) {
        const res = await window.niyo_coins();
        const resParse = parseObject(res);
        const coinsBalance = resParse?.coinsBalance ?? 0;
        setCoinsLocal(coinsBalance);
        dispatch(setCoins(burnCoinsFeat ? coinsBalance : 0));
      } else {
        setCoinsLocal(TEST_COINS_WEB);
        dispatch(setCoins(TEST_COINS_WEB));
      }
    } catch (error) {
      console.error('Failed to fetch coins:', error);
      setCoinsLocal(0);
      dispatch(setCoins(0));
    } finally {
      setIsLoading(false);
    }
  }, [burnCoinsFeat, dispatch]);

  useEffect(() => {
    fetchCoins();
  }, [fetchCoins]);

  useEffect(() => {
    if (totalActiveBookings && !sessionStorage.getItem('tooltipShown')) {
      setShowTooltip(true);
      sessionStorage.setItem('tooltipShown', 'true');
      const timer = setTimeout(() => {
        setShowTooltip(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [totalActiveBookings]);

  const handleOpenRewardScreen = () => {
    try {
      if (
        window.flutter_inappwebview &&
        window.niyo_reward_screen &&
        typeof window.niyo_reward_screen === 'function'
      ) {
        window.niyo_reward_screen();
      } else {
        console.log('Reward screen feature is only available on mobile');
      }
    } catch (error) {
      console.error('Failed to open reward screen:', error);
    }
  };

  const handleMyTrips = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Home',
        ctaAction: 'Trips',
        screenDuration: totalScreenDuration.toString(),
      },
      posthog
    );
    navigate('/my-bookings');
  };

  const isDisabled = !token && !user?.id;

  return (
    <>
      {burnCoinsFeat && (
        <CoinsChip
          isLoading={isLoading}
          coinsLocal={coinsLocal}
          isDisabled={isDisabled}
          onClick={handleOpenRewardScreen}
          theme={theme}
        />
      )}
      <Tooltip
        title="You have an upcoming trip!"
        open={showTooltip}
        placement="bottom-end"
        arrow
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [-12, 8],
              },
            },
          ],
        }}
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: 'black',
              borderRadius: '10px',
              fontSize: '12px',
              marginTop: '2px !important',
              padding: '6px 10px',
              left: '14px',
              transformOrigin: 'left center',
            },
          },
          arrow: {
            sx: {
              color: 'black',
              borderRadius: '2px',
              left: '22px !important',
              '&::before': {
                borderRadius: '2px',
              },
            },
          },
        }}
      >
        <span>
          <MyTripsChip
            isDisabled={isDisabled}
            onClick={handleMyTrips}
            totalActiveBookings={totalActiveBookings}
            theme={theme}
          />
        </span>
      </Tooltip>
    </>
  );
};

const CoinsChip: React.FC<{
  isLoading: boolean;
  coinsLocal: number;
  isDisabled: boolean;
  onClick: () => void;
  theme?: any;
}> = ({ isLoading, coinsLocal, isDisabled, onClick, theme }) => {
  if (isLoading) {
    return (
      <Skeleton
        variant="rectangular"
        height={40}
        width={55}
        sx={{ borderRadius: '18px', mr: '10px' }}
      />
    );
  }

  return (
    <OAChip
      label={
        <Box display="flex" alignItems="center" sx={{ px: '6px' }}>
          <OAImage
            src={theme.assets.images.icons.coin.path}
            alt="coins"
            sx={{ height: '20px', width: '20px' }}
          />
          <Typography variant="body2" sx={{ ml: '4px' }}>
            {formatToINR(coinsLocal, false)}
          </Typography>
        </Box>
      }
      sx={{ ...CHIP_STYLE, mr: '10px', backgroundColor: 'white !important' }}
      disabled={isDisabled}
      onClick={onClick}
    />
  );
};

const MyTripsChip: React.FC<{
  isDisabled: boolean;
  onClick: () => void;
  totalActiveBookings: number;
  theme?: any;
}> = ({ isDisabled, onClick, totalActiveBookings, theme }) => (
  <OAChip
    label={
      <Box display="flex" alignItems="center" sx={{ pl: '1px', pr: '6px' }}>
        <OAImage
          src={theme.assets.images.icons.myTrips.path}
          alt="trip"
          sx={{ height: '30px', width: '30px' }}
        />
        <Typography variant="body2" sx={{ ml: '4px' }}>
          My Trips{totalActiveBookings > 0 ? ` (${totalActiveBookings})` : ''}
        </Typography>
      </Box>
    }
    sx={{ ...CHIP_STYLE, backgroundColor: 'white' }}
    disabled={isDisabled}
    onClick={onClick}
  />
);
