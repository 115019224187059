import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { OAImage, OASwitch } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInputState, setIsCoinsApplied } from '../store/slices/userInputSlice';
import { getUserInfo } from '../store/slices/userInfoSlice';
import { formatToINR, getBurnCoin } from '../utils';
import { getFeatureFlag } from '../store/slices/featuresSlice';
import { eventsTracker } from '../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { getAuthInfo } from '../store/slices/authSlice';
import FlexItemsBetween from './FlexItemsBetween';
import { useTheme } from '../config/theme/useTheme';
import FlexItems from './FlexItems';

export const OACoinSwitch = ({ isReviewPage = false }: { isReviewPage?: boolean }) => {
  const posthog = usePostHog();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { isCoinsApplied } = useSelector(getUserInputState);
  const { coins } = useSelector(getUserInfo);
  const { burnCoinsFeat } = useSelector(getFeatureFlag);
  const { campaigns } = useSelector(getAuthInfo);
  const [startTime, setStartTime] = useState(Date.now());

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    if (event.target.checked) {
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: isReviewPage ? 'Confirm' : 'SRP',
          ctaAction: 'CoinOn',
          screenDuration: totalScreenDuration?.toString(),
        },
        posthog
      );
    } else {
      eventsTracker(
        {
          flowName: 'Flight',
          screenName: isReviewPage ? 'Confirm' : 'SRP',
          ctaAction: 'CoinOff',
          screenDuration: totalScreenDuration?.toString(),
        },
        posthog
      );
    }
    dispatch(setIsCoinsApplied(event.target.checked));
  };
  return (
    <>
      {burnCoinsFeat && coins > 9 ? (
        <FlexItemsBetween sx={theme.components.coinSwitch.styles.container}>
          <FlexItems>
            <Typography variant="body3" sx={{ color: 'secondary.contrastText', fontWeight: 600 }}>
              {coins > 9
                ? theme.strings.searchResults.coinSwitch.withCoins.title
                : theme.strings.searchResults.coinSwitch.withoutCoins.title}
            </Typography>
            <OAImage
              src={theme.assets.images.icons.coin.path}
              alt="coins"
              sx={{ mx: '8px', height: '20px', width: '20px' }}
            />
            <Typography variant="body3" sx={{ color: 'secondary.contrastText', fontWeight: 600 }}>
              {coins > 9
                ? `${formatToINR(coins, false)} ${theme.strings.searchResults.coinSwitch.withCoins.subtitle}`
                : theme.strings.searchResults.coinSwitch.withoutCoins.subtitle}
            </Typography>
          </FlexItems>

          {coins > 9 && <OASwitch onChange={handleOnChange} checked={isCoinsApplied} />}
        </FlexItemsBetween>
      ) : null}
    </>
  );
};
