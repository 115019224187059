import { Box, Card, Container, Skeleton, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { OAButton, OAChip, OACoinBanner, OAImage } from '../../../components';
import { bookingStatusLabels } from '../../../constants';
import { eventsTracker } from '../../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import { useLazyGetEticketQuery } from '../../../services/bookingsApi';
import { useDispatch } from '../../../store';
import { setMessage } from '../../../store/slices/snackbarSlice';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '../../../config/theme/useTheme';
import FlexItemsBetween from '../../../components/FlexItemsBetween';

interface StatusMsgAndBtnProps {
  order: any;
  isLoading: boolean;
}
const StatusMsgAndBtn: FC<StatusMsgAndBtnProps> = ({ order, isLoading }) => {
  const posthog = usePostHog();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [trigger] = useLazyGetEticketQuery({});
  const [isLoadingDownload, setIsLoadingDownload] = useState<boolean>(false);
  const [startTime, setStartTime] = useState(Date.now());

  const showDownloadBtn = ['BOOKING_CONFIRMED', 'CANCELLATION_REQUESTED'].includes(order?.bookingStatus);
  const showCheckInBtn =
    ['BOOKING_CONFIRMED'].includes(order?.bookingStatus) &&
    order?.flights?.[0]?.segments?.[0]?.webCheckIn;

  const isCompleted =
    order?.bookingStatus === 'BOOKING_CONFIRMED' && order?.orderCurrentStatus === 'COMPLETED';
  const bookingStatus = isCompleted ? 'COMPLETED' : order?.bookingStatus;
  const isPastTrip = order?.tripStartDate < new Date().toISOString();

  let status: any = null;
  if (order) {
    status = bookingStatusLabels?.find((obj: any) => bookingStatus === obj?.value);
  }

  const handleDonwloadTicket = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Booking',
        ctaAction: 'DownloadTicket',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );

    setIsLoadingDownload(true);
    trigger(order?.id)
      .then((result) => {
        setIsLoadingDownload(false);
        const eticketUrl = result?.data?.data?.eticket?.url;
        if (eticketUrl) {
          if (
            window?.flutter_inappwebview &&
            window.niyo_download &&
            typeof window.niyo_download === 'function'
          ) {
            window.niyo_download(eticketUrl);
          } else {
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            document.body.appendChild(anchor);
            anchor.href = eticketUrl;
            anchor.target = '_BLANK';
            anchor.download = `${order?.bookingId}_ETICKET.pdf`;
            anchor.click();
            document.body.removeChild(anchor);
          }
        } else {
          dispatch(setMessage('Download failed, please try again.'));
        }
      })
      .catch((error) => {
        setIsLoadingDownload(false);
        console.error('Network Error:', error);
      });
  };

  const handleCheckInClick = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Booking',
        ctaAction: 'CheckIn',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    if (
      window?.flutter_inappwebview &&
      window.niyo_open_url &&
      typeof window.niyo_open_url === 'function'
    ) {
      const seg = order?.flights?.find((f: any) => f.departureType === 'ONWARD')?.segments;
      if (seg && seg?.length > 0) {
        // Note: Temp we added [0]
        const webCheckIn = seg?.[0]?.webCheckIn;
        if (webCheckIn) window.niyo_open_url(webCheckIn);
      }
    } else {
      console.log('only allowed in mobile');
    }
  };

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  return (
    <Container sx={theme.components.pages.bookingStatus.statusMsgAndBtn.container}>
      <FlexItemsBetween sx={theme.components.pages.bookingStatus.statusMsgAndBtn.subContainer}>
        <Box>
          {order ? (
            <Typography
              variant="body3"
              sx={{
                fontWeight: 600,
                color: status?.type === 'error' ? 'error.main' : 'primary.main',
              }}
            >
              {status?.message}
            </Typography>
          ) : (
            <Skeleton variant="text" sx={{ fontSize: '12px', width: '120px' }} />
          )}
          {order ? (
            <Typography variant="body2" sx={{ letterSpacing: '0.15px' }}>
              Booking ID: {order?.bookingId}
            </Typography>
          ) : (
            <Skeleton variant="text" sx={{ fontSize: '16px', width: '200px' }} />
          )}
        </Box>
        <OAChip
          size="small"
          label={status?.label ?? order?.bookingStatus}
          clickable={false}
          variant="filled"
          color={status?.type ?? 'default'}
          sx={{
            mb: '4px',
            minHeight: '16px',
            '& .MuiChip-label': {
              fontSize: '0.75rem',
              fontWeight: 600,
            },
          }}
        />
      </FlexItemsBetween>
      {isLoading ? (
        <>
          <Box sx={theme.components.pages.bookingStatus.statusMsgAndBtn.loaderContainer}>
            <Skeleton variant="rectangular" width="100%" height="30px" />
          </Box>
          <Skeleton variant="rectangular" sx={theme.components.pages.bookingStatus.statusMsgAndBtn.loader} />
        </>
      ) : (
        <>
          {order?.orderAmount?.paymentSummary?.earnedCoin > 0 && (
            <OACoinBanner
              title={
                order?.bookingStatus === 'BOOKING_CONFIRMED' &&
                order?.orderCurrentStatus === 'COMPLETED'
                  ? theme.strings.itinerary.footer.fareSummary.coinBanner.bookingSuccess.title
                  : theme.strings.itinerary.footer.fareSummary.coinBanner.bookingPending.title
              }
              subTitle={order?.orderAmount?.paymentSummary?.earnedCoin}
              isCurrency={false}
              sx={{ mt: '20px' }}
            />
          )}
          {showDownloadBtn && (
            <>
              <OAButton
                onClick={handleDonwloadTicket}
                fullWidth
                variant="contained"
                color="secondary"
                sx={{ mt: '20px' }}
                loading={isLoadingDownload}
              >
                Download Ticket
              </OAButton>
            </>
          )}
        </>
      )}
      {isLoading ? (
        <Skeleton variant="rectangular" sx={theme.components.pages.bookingStatus.statusMsgAndBtn.checkInLoader} />
      ) : (
        showCheckInBtn &&
        !isPastTrip && (
          <OAButton
            onClick={handleCheckInClick}
            fullWidth
            variant="outlined"
            color="secondary"
            sx={{ mt: '10px' }}
          >
            Check-In
          </OAButton>
        )
      )}
      {order?.bookingStatus === 'CANCELLATION_REQUESTED' && (
        <Card
          sx={theme.components.pages.bookingStatus.statusMsgAndBtn.cancellationRequest.container}
        >
          <OAImage src="info-icon.svg" folder="icons" alt="info" sx={{
            alignSelf: 'flex-start',
            mt: '2px'
          }} />
          <Typography ml="10px" variant="body3">
            {theme.strings.myBookings.cancellationRequest.title}
          </Typography>
        </Card>
      )}
    </Container>
  );
};

export default StatusMsgAndBtn;
