import { Box, Container, Divider, Typography } from '@mui/material';
import { OAButton, OAImage, OASwipeableDrawer } from '../../../components';
import { useEffect, useState } from 'react';
import CancellationReasons from './CancellationReasons';
import CancellationPolicy from './CancellationPolicy';
import { eventsTracker } from '../../../utils/ctEventsTracking';
import { usePostHog } from 'posthog-js/react';
import OAFlightRoute from '../../../components/OAFlightRoute';
import { useSelector } from '../../../store';
import { getCancellationState } from '../../../store/slices/cancellationSlice';
import { bookingStatusLabels } from '../../../constants';
import FlexItemsBetween from '../../../components/FlexItemsBetween';
import { useTheme } from '../../../config/theme/useTheme';
import FlexItems from '../../../components/FlexItems';
import { ThemeConfig } from '../../../config/theme/types';

const CancelBooking = ({ data }: any) => {
  const posthog = usePostHog();
  const theme = useTheme();
  const { cancellationData } = useSelector(getCancellationState);
  const [isCancellationReasonsOpen, setIsCancellationReasonsOpen] = useState<boolean>(false);
  const [isCancellationPolicyOpen, setIsCancellationPolicyOpen] = useState<boolean>(false);
  const [isCancellationPolicyDrawerOpen, setIsCancellationPolicyDrawerOpen] =
    useState<boolean>(false);
  const [startTime, setStartTime] = useState(Date.now());
  const [requestSupport, setRequestSupport] = useState<boolean>(false);

  const onwardMinirule = data?.flights?.find(
    (flight: any) => flight?.departureType === 'ONWARD'
  )?.minirule;
  const returnMinirule = data?.flights?.find(
    (flight: any) => flight?.departureType === 'RETURN'
  )?.minirule;

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const toggleCancellationReasons = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Booking',
        ctaAction: 'Cancel',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    data?.orderCurrentStatus === 'COMPLETED'
      ? setRequestSupport(!requestSupport)
      : setIsCancellationReasonsOpen(!isCancellationReasonsOpen);
  };

  const toggleCancellationPolicy = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);

    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Booking',
        ctaAction: 'CancelPolicy',
        screenDuration: totalScreenDuration?.toString(),
      },
      posthog
    );
    setIsCancellationPolicyOpen(!isCancellationPolicyOpen);
  };

  const toggleCancelPolicy = () => {
    setIsCancellationPolicyDrawerOpen(!isCancellationPolicyDrawerOpen);
  };

  const eventFreshChat = () => {
    if (
      window.flutter_inappwebview &&
      window.niyo_fresh_chat &&
      typeof window.niyo_fresh_chat === 'function'
    ) {
      let appSubStage = `${data?.data?.bookingStatus?.toLowerCase()}`;
      const status = bookingStatusLabels?.find(
        (obj: any) => data?.data?.bookingStatus === obj?.value
      )?.label;
      if (status) {
        appSubStage = status?.toLowerCase();
      }
      const freshChatData = {
        appTag: 'oa_onarrival_mytrips',
        appSpoke: 'onarrival',
        appStage: 'onarrival_mytrips',
        appSubStage: `onarrival_mytrips_${appSubStage}`,
        faqTag: ['oa_onarrival_mytrips'],
      };
      const freshChatDataStringify = JSON.stringify(freshChatData);
      window.niyo_fresh_chat(freshChatDataStringify);
    } else {
      console.log('only allowed in mobile');
    }
  };

  return (
    <>
      <Container>
        <Typography variant="body1" sx={{ fontWeight: 600, mb: '4px' }}>
          Plan changed?
        </Typography>
        <Typography variant="body3" sx={{ color: 'text.secondary' }}>
          Cancel your ticket and claim a refund with ease. Cancellations within{' '}
          <strong>3-4 hours</strong> of departure are tax only refunds. Refunds are subject to
          airline approval.
        </Typography>

        <OAButton
          onClick={toggleCancellationReasons}
          fullWidth
          variant="outlined"
          color="error"
          sx={theme.components.pages.bookingStatus.cancel.cta}
        >
          {cancellationData?.cancellable ? 'Cancel Booking' : 'Request Cancellation'}
        </OAButton>
        <Typography
          variant="body2"
          sx={theme.components.pages.bookingStatus.cancel.viewText}
          onClick={toggleCancellationPolicy}
        >
          View Cancellation policy
        </Typography>
      </Container>
      <Divider sx={theme.components.pages.bookingStatus.cancel.divider} />
      <OASwipeableDrawer
        title="Why do you want to cancel"
        open={isCancellationReasonsOpen}
        onClose={toggleCancellationReasons}
        onOpen={toggleCancellationReasons}
        isContainer={false}
      >
        <CancellationReasons bookingData={data} />
      </OASwipeableDrawer>
      <OASwipeableDrawer
        title="Cancellation policy"
        open={isCancellationPolicyOpen}
        onClose={toggleCancellationPolicy}
        onOpen={toggleCancellationPolicy}
        isContainer={false}
      >
        {!onwardMinirule && !returnMinirule ? (
          <FallbackUI theme={theme} />
        ) : (
          <>
            {returnMinirule && (
              <FlexItems sx={theme.components.pages.bookingStatus.cancel.miniruleContainer}>
                <OAFlightRoute
                  from={data?.flightSearchInfo?.from?.city}
                  to={data?.flightSearchInfo?.to?.city}
                  icon="flight-take-off.svg"
                />
              </FlexItems>
            )}
            <Container>
              {onwardMinirule ? (
                <CancellationPolicy data={onwardMinirule} />
              ) : (
                <FallbackUISecondary theme={theme} />
              )}
            </Container>

            {returnMinirule && (
              <FlexItems sx={theme.components.pages.bookingStatus.cancel.miniruleContainer}>
                <OAFlightRoute
                  from={data?.flightSearchInfo?.from?.city}
                  to={data?.flightSearchInfo?.to?.city}
                  icon="flight-take-off.svg"
                />
              </FlexItems>
            )}
            {returnMinirule && (
              <Container>
                {returnMinirule ? (
                  <CancellationPolicy data={returnMinirule} />
                ) : (
                  <FallbackUISecondary theme={theme} />
                )}
              </Container>
            )}
            <Box display="flex" justifyContent="center" mb="16px">
              <OAButton
                onClick={toggleCancelPolicy}
                size="medium"
                sx={{ color: '#009E82', fontSize: '14px' }}
              >
                View terms and conditions
              </OAButton>
            </Box>
          </>
        )}
      </OASwipeableDrawer>
      <OASwipeableDrawer
        title="Terms & conditions"
        open={isCancellationPolicyDrawerOpen}
        onClose={toggleCancelPolicy}
        onOpen={toggleCancelPolicy}
      >
        {data?.supplierInfo?.supplierCode === 'TBO'
          ? onwardMinirule?.cancelTermsAndConditions?.map((item: any, index: number) => {
              const htmlRegex = /<[^>]*>/;
              const isHTMLString = htmlRegex.test(item);
              if (isHTMLString) {
                return <div key={index} dangerouslySetInnerHTML={{ __html: item }} />;
              } else {
                return (
                  <Box key={index} display="flex" justifyContent="space-between" mb="15px">
                    <Typography variant="body2" color="gray">
                      {item}
                    </Typography>
                  </Box>
                );
              }
            })
          : onwardMinirule?.cancelTermsAndConditions?.map((item: any, index: number) => (
              <Box key={index} display="flex" justifyContent="space-between" mb="15px">
                <Typography variant="body2" color="gray">
                  {item}
                </Typography>
              </Box>
            ))}
      </OASwipeableDrawer>
      <OASwipeableDrawer
        title="Looking to cancel?"
        open={requestSupport}
        onClose={toggleCancellationReasons}
        onOpen={toggleCancellationReasons}
      >
        <Typography>Reach out to our support team to help you with your concerns</Typography>
        <OAButton
          fullWidth
          variant="contained"
          color="secondary"
          sx={{ my: '20px' }}
          onClick={eventFreshChat}
        >
          Request support
        </OAButton>
      </OASwipeableDrawer>
    </>
  );
};

export default CancelBooking;

const FallbackUI = ({ theme }: { theme: ThemeConfig }) => (
  <Box display="flex" flexDirection="column" alignItems="center" p="16px">
    <OAImage src="person-without-phone.svg" height="150px" width="150px" alt="edit" />
    <Typography
      variant="body3"
      color="text.secondary"
      textAlign="center"
      p="10px"
      bgcolor="#F4F6F5"
      borderRadius="10px"
      mt="20px"
    >
      {theme.strings.bookingStatus.cancel.fallbackUi.text}
    </Typography>
  </Box>
);

const FallbackUISecondary = ({ theme }: { theme: ThemeConfig }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center">
    <OAImage src="person-without-phone.svg" height="80px" width="80px" alt="edit" />
    <Typography variant="body3" color="text.secondary" p="10px">
      {theme.strings.bookingStatus.cancel.fallbackUi.text}
    </Typography>
  </Box>
);
