import { useEffect, useState } from 'react';
import {
  useCreateTravellerMutation,
  useGetTravellersQuery,
  useUpdateTravellerMutation,
} from '../services/travellersApi';
import { useDispatch, useSelector } from '../store';
import {
  getTravellerState,
  setSelectedTravellersIds,
  setTravellerList,
} from '../store/slices/travellerSlice';
import { setMessage } from '../store/slices/snackbarSlice';
import { useNavigate } from 'react-router-dom';
import { getCurrentTravellerSelectionCounts } from '../utils/getCurrentTravellerSelectionCounts';
import { getUserInputState } from '../store/slices/userInputSlice';
import { getFlightOrderState } from '../store/slices/flightOrderSlice';

export const useTraveller = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { travellerList, selectedTravellersIds } = useSelector(getTravellerState);
  const { travelDetail } = useSelector(getUserInputState);
  const { flightData } = useSelector(getFlightOrderState);

  const { data, refetch, isFetching, isLoading, isError } = useGetTravellersQuery({});
  const [updateTraveller] = useUpdateTravellerMutation();
  const [createTraveller] = useCreateTravellerMutation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (data?.data && data.data.length > 0 && !travellerList) {
      dispatch(setTravellerList(data.data));
    }
  }, [data]);

  const checkTravellerDiff = (traveller: any) => {
    const currentSelectionCounts = getCurrentTravellerSelectionCounts(
      selectedTravellersIds,
      travellerList
    );
    const allowedCounts: any = {
      ADULT: flightData?.travellerCount?.adult || travelDetail?.travellerCount?.adult,
      CHILD: flightData?.travellerCount?.child || travelDetail?.travellerCount?.child,
      INFANT: flightData?.travellerCount?.infant || travelDetail?.travellerCount?.infant,
    };
    return currentSelectionCounts[traveller?.type] < allowedCounts[traveller?.type];
  };

  const handleUpdateTraveller = async (data: any) => {
    setIsSubmitting(true);
    const result = await updateTraveller(data);
    const travellerObj = result?.data?.data;
    if (travellerObj) {
      const filteredList = travellerList?.filter(
        (traveller: any) => traveller.id !== travellerObj.id
      );

      dispatch(setTravellerList([travellerObj, ...filteredList]));
      const currentTraveller = selectedTravellersIds?.find((t: any) => t === travellerObj?.id);
      if (!currentTraveller && checkTravellerDiff(travellerObj)) {
        dispatch(setSelectedTravellersIds([...selectedTravellersIds, travellerObj?.id]));
      }
      setTimeout(() => {
        dispatch(
          setMessage({
            message: 'Traveller updated successfully.',
          })
        );
        setIsSubmitting(false);
      }, 1000);
      navigate('/review', { replace: true });
    } else {
      setIsSubmitting(true);
      dispatch(
        setMessage({
          message: 'Something went wrong while updating traveller',
          severity: 'error',
        })
      );
    }
  };

  const handleAddTraveller = async (data: any) => {
    setIsSubmitting(true);
    const result = await createTraveller(data);
    const travellerObj = result?.data?.data;
    if (travellerObj) {
      dispatch(setTravellerList([travellerObj, ...(travellerList || [])]));
      const currentTraveller = selectedTravellersIds?.find((t: any) => t === travellerObj?.id);

      if (!currentTraveller && checkTravellerDiff(travellerObj)) {
        dispatch(setSelectedTravellersIds([...selectedTravellersIds, travellerObj?.id]));
      }
      setTimeout(() => {
        dispatch(
          setMessage({
            message: 'Traveller added successfully',
          })
        );
        setIsSubmitting(false);
      }, 1000);
      navigate('/review', { replace: true });
    } else {
      setIsSubmitting(false);
      dispatch(
        setMessage({
          message: 'Something went wrong while adding traveller',
          severity: 'error',
        })
      );
    }
  };

  return {
    travellerList,
    isFetching,
    isLoading,
    isError,
    refetch,
    handleUpdateTraveller,
    handleAddTraveller,
    selectedTravellersIds,
    isSubmitting,
  };
};
