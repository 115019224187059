import { FC } from 'react';
import { Box, Card, Skeleton, Typography } from '@mui/material';
import OverlayIcon from './OverlayIcon';
import { OAImage } from '../../../components';
import { useTheme } from '../../../config/theme/useTheme';
import FlexItemsBetween from '../../../components/FlexItemsBetween';

const CATEGORY = {
  Seats: {
    color: '#13387E',
    icon: 'smb-seat-icon.svg',
    bgColor: '#E6EEFF',
  },
  Meals: {
    color: '#8E3214',
    icon: 'smb-meal-icon.svg',
    bgColor: '#FFEACE',
  },
  Baggage: {
    color: '#6B1299',
    icon: 'smb-baggage-icon.svg',
    bgColor: '#F0E2FF',
  },
};

interface CardItemProps {
  title: 'Seats' | 'Meals' | 'Baggage';
  subTitle: string;
  isSelected: boolean;
  isLoading: boolean;
  itemCount: number;
  totalItemsToBeAdded: number;
  isAvailable: boolean;
}
const CardItem: FC<CardItemProps> = ({
  title,
  subTitle,
  isSelected,
  isLoading,
  itemCount,
  totalItemsToBeAdded,
  isAvailable,
}) => {
  const theme = useTheme();
  return (
    <Box sx={theme.components.pages.itinerary.smbWrapper.smbOptionContainer}>
      {isAvailable && <OverlayIcon isSelected={isSelected} isLoading={isLoading} />}
      <Card sx={theme.components.pages.itinerary.smbWrapper.smbOptionCard.container}>
        <FlexItemsBetween>
          <Box>
            <Typography
              sx={{
                ...theme.components.pages.itinerary.smbWrapper.smbOptionCard.title,
                color: CATEGORY[title].color,
              }}
            >
              {isLoading ? <Skeleton variant="text" width={40} /> : title}
            </Typography>
            <Typography
              sx={theme.components.pages.itinerary.smbWrapper.smbOptionCard.subTitle}
            >
              {isLoading ? <Skeleton variant="text" width={50} /> : isSelected ? `${itemCount} / ${totalItemsToBeAdded} added` : subTitle}
            </Typography>
          </Box>
          <Box>
            {isLoading ? (
              <Skeleton variant="rectangular" width={24} height={24} />
            ) : (
              <OAImage src={CATEGORY[title].icon} alt="seat" />
            )}
          </Box>
        </FlexItemsBetween>
      </Card>
    </Box>
  );
};

export default CardItem;
