/**
 * Utility function to check if any selected traveler has age restrictions
 * Age restrictions apply to:
 * - Infants 6 months or younger
 * - Seniors 70 years or older
 */

import { ITraveller } from '../type/ITraveller';

interface TravellerData {
  data?: ITraveller[];
}

/**
 * Checks if any of the selected travelers has age restrictions
 * @param selectedTravelers - Array of selected traveler IDs
 * @param displayTravellers - Object containing traveler data
 * @returns boolean - true if any traveler has age restrictions, false otherwise
 */
export const hasTravellerAgeRestriction = (
  selectedTravelers: string[],
  displayTravellers: TravellerData | undefined
): boolean => {
  if (!displayTravellers?.data) return false;

  return selectedTravelers.some((id) => {
    const traveller = displayTravellers?.data?.find((t) => t?.id === id);
    if (!traveller?.age && !traveller?.dateOfBirth) return false;

    // for travelers less than 1 year old (age = 0), calculating months from DOB
    if (traveller.age === 0 && traveller.dateOfBirth) {
      const birthDate = new Date(traveller.dateOfBirth);
      const today = new Date();
      const monthsDiff = (today.getFullYear() - birthDate.getFullYear()) * 12 +
        (today.getMonth() - birthDate.getMonth());
      return monthsDiff <= 6;
    }

    return traveller.age >= 70;
  });
}; 