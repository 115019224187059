import React, { createContext, useEffect, useState } from 'react';
import { ThemeConfig } from './types';
import { getThemeByName } from './utils';
import type { ThemeName } from './index';

export const ThemeContext = createContext<ThemeConfig>(getThemeByName('default'));

interface ThemeProviderProps {
  children: React.ReactNode;
  initialTheme?: ThemeName;
}

export const ThemeProvider: React.FC<ThemeProviderProps> = ({ 
  children, 
  initialTheme = 'default' 
}) => {
  const [currentTheme, setCurrentTheme] = useState<ThemeConfig>(
    getThemeByName(initialTheme)
  );

  useEffect(() => {
    // You can add logic here to load theme from localStorage, API, or other sources
    const themeFromStorage = localStorage.getItem('app-theme') as ThemeName | null;
    if (themeFromStorage) {
      setCurrentTheme(getThemeByName(themeFromStorage));
    }
  }, []);

  return (
    <ThemeContext.Provider value={currentTheme}>
      {children}
    </ThemeContext.Provider>
  );
};
