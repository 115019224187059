import { Box, Skeleton, Typography } from '@mui/material';
import { OAImage } from '.';
import { useTheme } from '../config/theme/useTheme';

export const OAHeaderTitle = ({ travelDetail, params }: { travelDetail: any; params?: any }) => {
  const theme = useTheme();
  const from = params?.from?.city ?? travelDetail?.from?.city;
  const to = params?.to?.city ?? travelDetail?.to?.city;

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body1" sx={{ letterSpacing: '0.15px' }}>
        {from ?? <Skeleton sx={{ fontSize: '1rem', width: '60px' }} />}
      </Typography>
      <OAImage
        src={
          travelDetail?.tripType === 'R' || travelDetail?.tripType === 'ROUND_TRIP'
            ? theme.assets.images.icons.twoWayArrow.path
            : theme.assets.images.icons.oneWayArrow.path
        }
        folder={theme.assets.images.icons.oneWayArrow.folder}
        alt="One way"
        sx={{ mx: '4px' }}
      />
      <Typography variant="body1" sx={{ letterSpacing: '0.15px' }}>
        {to ?? <Skeleton sx={{ fontSize: '1rem', width: '60px' }} />}
      </Typography>
    </Box>
  );
};
