import { useMemo } from 'react';
import { Offer, PageType, TripType, TopBarBanner, ApplicablePageOffer } from '../pages/Flight/types';

interface UseOffersParams {
  currentPage: PageType[];
  isInternationalRoute?: boolean;
  tripType?: TripType;
  offers: Offer[];
  skipRouteChecks?: boolean;
}

interface OfferWithDetails extends Offer {
  pageSpecificDetails: ApplicablePageOffer[];
}

export const useOffers = ({
  currentPage,
  isInternationalRoute,
  tripType,
  offers,
  skipRouteChecks = false,
}: UseOffersParams) => {
  const applicableOffers = useMemo(() => {
    return offers
      ?.filter((offer) => {
        const hasApplicablePage = currentPage?.includes('HOME') ||
          offer.applicablePages?.some(page => currentPage?.includes(page));

        const hasApplicablePageOffer = offer.applicablePageOffers?.some(
          pageOffer => pageOffer.pages?.some(page => currentPage?.includes(page))
        );

        if (!hasApplicablePage && !hasApplicablePageOffer) return false;
        if (skipRouteChecks) return true;

        // Check route applicability
        const routeChecks = offer?.applicableRoutes;
        if (!routeChecks) return true; // If no route restrictions, offer is applicable

        // International route check - if not specified, offer is valid for both international and domestic
        const isInternationalValid = routeChecks?.international === undefined ||
          routeChecks?.international === isInternationalRoute;

        // Trip type check - if no trip types specified, offer is valid for all trip types
        const isTripTypeValid = !routeChecks?.tripTypes?.length ||
          routeChecks?.tripTypes?.includes(tripType as TripType);

        return isInternationalValid && isTripTypeValid;
      })
      ?.sort((a, b) => a.priority - b.priority)
      ?.map(offer => ({
        ...offer,
        pageSpecificDetails: offer?.applicablePageOffers?.filter(pageOffer =>
          pageOffer.pages?.some(page => currentPage?.includes(page))
        ) || []
      })) as OfferWithDetails[];
  }, [currentPage, isInternationalRoute, tripType, offers, skipRouteChecks]);

  return {
    applicableOffers,
    getBannerDetails: (offerId: string, page: PageType) => {
      const offer = applicableOffers?.find(o => o.offerId === offerId);
      const pageDetails = offer?.applicablePageOffers?.find(po => po.pages?.includes(page));
      return pageDetails || {
        imageUrl: offer?.bannerImage,
        title: offer?.title,
        subTitle: offer?.subTitle,
        discountAmount: offer?.discountAmount
      };
    },
    getTopBarBanner: (offerId: string, page: PageType): TopBarBanner | null => {
      const offer = applicableOffers?.find(o => o.offerId === offerId);
      const pageOffer = offer?.applicablePageOffers?.find(po =>
        po.pages?.includes(page) && po?.topBarBanner
      );
      return pageOffer?.topBarBanner || null;
    }
  };
};