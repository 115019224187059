import { Box, Chip, Typography } from '@mui/material';
import { IFlight } from '../../../type';
import { getAirlineName, getDiscountPrice, getUniqueAirlines } from '../../../utils';
import OAFlightAvatar from '../../../components/OAFlightAvatar';
import { useSelector } from '../../../store';
import { getUserInputState } from '../../../store/slices/userInputSlice';
import { getUserInfo } from '../../../store/slices/userInfoSlice';
import { OAImage } from '../../../components';
import FlexItemsBetween from '../../../components/FlexItemsBetween';
import { useTheme } from '../../../config/theme/useTheme';
import FlexItems from '../../../components/FlexItems';

const AirlineDetail = ({
  data,
  tripType,
  isInternational,
}: {
  data: IFlight;
  tripType: 'R' | 'O';
  isInternational?: boolean;
}) => {
  const { isCoinsApplied } = useSelector(getUserInputState);
  const { coins } = useSelector(getUserInfo);
  const theme = useTheme();
  // filtering out segments to only include unique airlines
  const uniqueAirlines = getUniqueAirlines(data?.segments);
  const airline = data?.segments?.[0]?.airline;
  const discountPrice = getDiscountPrice(data?.price?.value, coins);
  const isDiscountedPrice = discountPrice < data?.price?.value;
  const isDomesticReturn = tripType === 'R' && !isInternational;

  return (
    <FlexItemsBetween
      sx={() => ({
        ...theme.components.pages.searchResults.flightsList.flightCard.airlineDetails.container,
        alignItems: tripType === 'O' ? 'center' : 'start',
        ...(isDomesticReturn && {
          px: '10px',
          pt: '10px',
        }),
      })}
    >
      <FlexItems
        sx={
          theme.components.pages.searchResults.flightsList.flightCard.airlineDetails
            .airlineNameContainer
        }
      >
        <OAFlightAvatar
          data={uniqueAirlines}
          sx={
            theme.components.pages.searchResults.flightsList.flightCard.airlineDetails.flightAvatar
          }
        />
        <Typography
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          overflow="hidden"
          variant={isDomesticReturn ? 'body4' : 'body3'}
          sx={{ ml: '16px', color: 'grey.800' }}
        >
          {data?.stops?.value > 0 && uniqueAirlines?.length > 1
            ? 'Multiple Airlines'
            : tripType !== 'O'
              ? `${airline?.code}-${airline?.flightNumber}`
              : `${getAirlineName(airline)} • ${airline?.code}-${airline?.flightNumber}`}
        </Typography>
      </FlexItems>
      {isDomesticReturn && (
        <FlexItems
          sx={
            theme.components.pages.searchResults.flightsList.flightCard.airlineDetails
              .domesticReturn
          }
        >
          <Typography
            variant="body1"
            sx={{
              color: isCoinsApplied
                ? discountPrice > 0
                  ? 'secondary.main'
                  : 'success.main'
                : 'secondary.main',
              fontWeight: 600,
            }}
          >
            {data?.perAdultPricing?.display ?? data?.price?.display}
          </Typography>
          {data?.perAdultPricing?.display && <Typography variant="body4">per adult</Typography>}
        </FlexItems>
      )}
      {!isDomesticReturn && data?.price?.exclusiveFare && (
        <Typography variant="body4">
          <OAImage
            src={theme.assets.images.icons.companyExclusiveBanner.path}
            folder={theme.assets.images.icons.companyExclusiveBanner.folder}
            alt="no-convenience-fee"
            width="100%"
          />
        </Typography>
      )}
    </FlexItemsBetween>
  );
};

export default AirlineDetail;
