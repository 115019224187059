import React, { FC, useMemo, useState, useEffect } from 'react';
import { useUpdateSMBMutation } from '../../../services/createOrderApi';
import { Container, Grid } from '@mui/material';
import { OASwipeableDrawer } from '../../../components';
import OASMB from '../../../components/OASMB';
import { useDispatch, useSelector } from '../../../store';
import {
  getBaggageCount,
  getFlightOrderState,
  getMealCount,
  getSeatCount,
  getTotalBaggageCount,
  getTotalMealCount,
  getTotalSeatCount,
  setIsReviewAddOns,
} from '../../../store/slices/flightOrderSlice';
import CardItem from './CardItem';
import {
  getLowestPrice,
  getSortedUniquePrices,
} from '../../../components/OASMB/helpers/getAveragePrices';
import { getUserInfo } from '../../../store/slices/userInfoSlice';
import { useTheme } from '../../../config/theme/useTheme';

interface SMBProps {
  data: any;
  isLoading: boolean;
  ancillaryId: string;
  totalTravellers: number;
  isDiscountApplied: boolean;
  totalSelectedFlightPrice: number;
  discountPrice: number;
  // isReviewAddOnDrawerOpen: boolean;
  // toggleReviewAddOnDrawer: () => void;
  // setIsReviewAddOnDrawerOpen: any;
  onClickHandler: () => void;
  selectedTravelers: any;
  totalSegments?: number;
  isReturn: boolean;
  infantCount: number;
  scrollToTravellers?: any;
  toggleSmbDrawer: (currentTab?: number) => void;
  tabValue: number;
  setTabValue: (value: number) => void;
  isSmbDrawerOpen: boolean;
  setIsSmbDrawerOpen: (value: boolean) => void;
}
const SMB: FC<SMBProps> = ({
  data,
  isLoading,
  ancillaryId,
  totalTravellers,
  isDiscountApplied,
  totalSelectedFlightPrice,
  discountPrice,
  // isReviewAddOnDrawerOpen,
  // toggleReviewAddOnDrawer,
  // setIsReviewAddOnDrawerOpen,
  onClickHandler,
  selectedTravelers,
  totalSegments,
  isReturn,
  infantCount,
  scrollToTravellers,
  toggleSmbDrawer,
  tabValue,
  setTabValue,
  isSmbDrawerOpen,
  setIsSmbDrawerOpen,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { tags } = useSelector(getUserInfo);
  const { smbData: selectedSmbData } = useSelector(getFlightOrderState);
  const [updateSMB] = useUpdateSMBMutation();
  const [displaySmb, setDisplaySmb] = useState<boolean>(false);
  const [startTime, setStartTime] = useState(Date.now());

  const isPremiumUser =
    Array.isArray(tags) &&
    (tags?.includes('NIYO_GOLD_PREMIUM_USER') ||
      tags?.includes('NIYO_PREMIUM_USER') ||
      tags?.includes('NIYO_SILVER_PREMIUM_USER'));

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  useEffect(() => {
    if(data?.data?.length > 0) {
      setDisplaySmb(true);
    }
  }, [data])

  const segmentBasedTabs = useMemo(() => {
    if (tabValue === 3) {
      //for baggage
      return (
        data?.data?.[0]?.journeyAncillaries?.map((item: any) => item?.originDestinationInfo) || []
      );
    } else {
      return (
        data?.data?.[0]?.journeyAncillaries?.flatMap((data: any) =>
          data?.segmentAncillaries?.map((segment: any) => {
            return {
              from: segment?.originDestinationInfo?.from,
              to: segment?.originDestinationInfo?.to,
              airline: segment?.flight?.airlineCode,
              flight: segment?.flight,
              segmentsInfo: segment?.segmentsInfo,
            };
          })
        ) || []
      );
    }
  }, [data?.data, tabValue]);

  const [selectedSegment, setSelectedSegment] = useState<{
    from: string;
    to: string;
  }>({
    from: segmentBasedTabs?.[0]?.from || '',
    to: segmentBasedTabs?.[0]?.to || '',
  });

  useEffect(() => {
    if (segmentBasedTabs.length > 0) {
      setSelectedSegment({
        from: segmentBasedTabs[0].from,
        to: segmentBasedTabs[0].to,
      });
    }
  }, [segmentBasedTabs]);

  const seatRows =
    data?.data?.[0]?.journeyAncillaries?.[0]?.segmentAncillaries?.[0]?.seatAncillaries
      ?.aircraftLayout?.lowerDeck?.compartments?.[0]?.rows;
  const mealsArray =
    data?.data?.[0]?.journeyAncillaries?.[0]?.segmentAncillaries?.[0]?.mealAncillaries?.mealInfos;
  const baggageArray =
    data?.data?.[0]?.journeyAncillaries?.[0]?.baggageAncillary?.baggageDetails?.[0]?.baggageInfos;

  const isSeatSelected = getSeatCount(selectedSmbData, selectedSegment);
  const isMealSelected = getMealCount(selectedSmbData, selectedSegment);
  const isBaggageSelected = getBaggageCount(selectedSmbData, selectedSegment);

  useEffect(() => {
    const showReviewAddOns = isSeatSelected && isMealSelected && isBaggageSelected;
    dispatch(setIsReviewAddOns(!showReviewAddOns));
  }, [isSeatSelected, isMealSelected, isBaggageSelected]);

  const getSeatPriceArray = getSortedUniquePrices([seatRows]);
  const lowestMealPrice = getLowestPrice(mealsArray);
  const lowestBaggagePrice = getLowestPrice(baggageArray);
  
  const onClickUpdateHandler = () => {
    setIsSmbDrawerOpen(false);
    onClickHandler();
  };
  const seatCount = useSelector(getTotalSeatCount);
  const mealCount = useSelector(getTotalMealCount);
  const baggageCount = useSelector(getTotalBaggageCount);

  return (
    <>
      <Container
        sx={{
          ...theme.components.pages.itinerary.smbWrapper.containerWithSmb,
          ...(displaySmb && theme.components.pages.itinerary.smbWrapper.containerWithSmbEnabled),
          ...(isPremiumUser && theme.components.pages.itinerary.smbWrapper.containerWithPremiumUser)
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={4} onClick={() => toggleSmbDrawer(1)}>
            <CardItem
              title="Seats"
              subTitle={
                getSeatPriceArray?.length < 1
                  ? 'Unavailable'
                  : getSeatPriceArray?.[0]?.price === 0
                  ? 'Get for free'
                  : `From ₹${getSeatPriceArray?.[0]?.price}`
              }
              isSelected={isSeatSelected > 0}
              isLoading={false}
              itemCount={seatCount}
              isAvailable={seatRows?.length > 0}
              totalItemsToBeAdded={(totalTravellers - (infantCount ?? 0)) * (totalSegments ?? 1)}
            />
          </Grid>
          <Grid item xs={4} onClick={() => toggleSmbDrawer(2)}>
            <CardItem
              title="Meals"
              subTitle={
                !lowestMealPrice && lowestMealPrice !== 0
                  ? 'Unavailable'
                  : lowestMealPrice === 0
                  ? 'Get for free'
                  : `From ₹${lowestMealPrice}`
              }
              isSelected={isMealSelected > 0}
              isLoading={false}
              itemCount={mealCount}
              isAvailable={mealsArray?.length > 0}
              totalItemsToBeAdded={(totalTravellers - (infantCount ?? 0)) * (totalSegments ?? 1)}
            />
          </Grid>
          <Grid item xs={4} onClick={() => toggleSmbDrawer(3)}>
            <CardItem
              title="Baggage"
              subTitle={
                !lowestBaggagePrice && lowestBaggagePrice !== 0
                  ? 'Unavailable'
                  : lowestBaggagePrice === 0
                  ? 'Get for free'
                  : `From ₹${lowestBaggagePrice}`
              }
              isSelected={baggageCount > 0}
              isLoading={false}
              itemCount={baggageCount}
              totalItemsToBeAdded={(totalTravellers - (infantCount ?? 0)) * (isReturn ? 2 : 1)}
              isAvailable={baggageArray?.length > 0}
            />
          </Grid>
        </Grid>
      </Container>
      <OASwipeableDrawer
        title="Select add-ons"
        open={isSmbDrawerOpen}
        onOpen={toggleSmbDrawer}
        onClose={toggleSmbDrawer}
        maxHeight={96}
        height={96}
        isContainer={false}
        sx={{ zIndex: 1301 }}
      >
        <OASMB
          isLoading={isLoading}
          selectedTab="Seat"
          smbData={data?.data?.[0]?.journeyAncillaries}
          tabValue={tabValue}
          setTabValue={setTabValue}
          totalTravellers={totalTravellers - (infantCount ?? 0)}
          updateSmbAction={updateSMB}
          isDiscountApplied={isDiscountApplied}
          totalSelectedFlightPrice={totalSelectedFlightPrice}
          discountPrice={discountPrice}
          selectedSmbData={selectedSmbData}
          segmentBasedTabs={segmentBasedTabs}
          selectedSegment={selectedSegment}
          setSelectedSegment={setSelectedSegment}
          onClickHandler={onClickUpdateHandler}
        />
      </OASwipeableDrawer>
      {/* <OASwipeableDrawer
        title="Review add-ons"
        open={isReviewAddOnDrawerOpen}
        onClose={toggleReviewAddOnDrawer}
        onOpen={toggleReviewAddOnDrawer}
        isContainer={false}
        maxHeight={96}
      >
        <AddOnsReview
          segmentBasedTabs={segmentBasedTabs}
          totalTravellers={totalTravellers - (infantCount ?? 0)}
          toggleSmbDrawer={toggleSmbDrawer}
          onClickHandler={onClickHandler}
        />
      </OASwipeableDrawer> */}
    </>
  );
};

export default SMB;
