
export interface EventTracking {
  flowName: string;
  screenName: string;
  ctaAction: string;
  screenDuration: number;
  otherData?: Record<string, any>;
}

export const HANDLER_NAMES = {
  GET_NIYO_COINS: "eventCoins",
  OPEN_PAYMENT: "eventPaymentGateway",
  USER_LOGIN: "eventLogin",
  LOCATION: "eventLocation",
  FRESH_CHAT: "eventFreshChat",
  REWARD_SCREEN: 'eventOpenRewardScreen',
  PAYMENT_CALLBACK: 'eventPaymentCallback',
  OPEN_URL: 'eventOpenUrl',
  CT_EVENT_TRACKING: 'eventCtEventTracking',
  REFRESH_TOKEN:'eventRefreshToken',
  CANCEL_CURRENT_TRANSACTION: 'eventCancelCurrentTransaction',
  EVENT_LOG_ERROR: 'eventLogError',
  DOWNLOAD_FILE: 'eventDownload',
  INITIALISE_REDIRECT : 'eventInitialRedirect',
  DEVICE_INFO: 'eventDeviceInfo',
  OPEN_NATIVE_ROUTES: 'eventOpenNativeRoutes'
};

function createFlutterHandler(eventName: string) {
  return (data: any = {}) => { // Default parameter as an empty object if no data is provided
    if (window.flutter_inappwebview) {
      return window.flutter_inappwebview
          .callHandler(eventName, data) // Pass 'data' to the Flutter handler
          .then((response: any) => {
            console.log(`Received from Dart for ${eventName}: `, JSON.stringify(response));
            return response;
          })
          .catch((error: any) => {
            console.error(`Error calling ${eventName} handler:`, error);
          });
    } else {
      console.error("flutter_inappwebview is not available.");
      return Promise.reject("flutter_inappwebview is not available.");
    }
  };
}

declare global {
  interface Window {
    flutter_inappwebview: {
      callHandler: (handlerName: string, ...args: any[]) => Promise<any>;
    };
    oaBack: () => void;
    // all these events are handled in flutter
    niyo_coins: () => Promise<any>;
    niyo_user_login: () => Promise<any>;
    back: () => Promise<any>;
    niyo_paymentGateway: (data: any) => Promise<any>;
    niyo_location: () => Promise<any>;
    niyo_fresh_chat: (data: any) => void;
    niyo_reward_screen: () => void;
    oa_payment_callback: (data: any) => void;
    oa_client_redirect_to: (data: any) => void;
    niyo_open_url: (url: string) => void;
    niyo_ct_event_tracking: (data: any) => void;
    niyo_refresh_token: () => Promise<any>;
    niyo_cancel_current_transaction: () => void;
    niyo_event_log_error: (data: any) => void;
    niyo_download: (url: string) => void;
    niyo_initial_redirect: () => void;
    niyo_deviceInfo: () => Promise<any>;
    niyo_open_native_routes: (data: any) => void;
  }
}

const windowFunctions = {
  //implement this function in flutter
  niyo_coins: createFlutterHandler(HANDLER_NAMES.GET_NIYO_COINS),
  niyo_paymentGateway: createFlutterHandler(HANDLER_NAMES.OPEN_PAYMENT),
  niyo_user_login: createFlutterHandler(HANDLER_NAMES.USER_LOGIN),
  niyo_location: createFlutterHandler(HANDLER_NAMES.LOCATION),
  niyo_fresh_chat: createFlutterHandler(HANDLER_NAMES.FRESH_CHAT),
  niyo_reward_screen: createFlutterHandler(HANDLER_NAMES.REWARD_SCREEN),
  niyo_open_url: createFlutterHandler(HANDLER_NAMES.OPEN_URL),
  niyo_ct_event_tracking: createFlutterHandler(HANDLER_NAMES.CT_EVENT_TRACKING),
  niyo_refresh_token: createFlutterHandler(HANDLER_NAMES.REFRESH_TOKEN),
  niyo_cancel_current_transaction: createFlutterHandler(HANDLER_NAMES.CANCEL_CURRENT_TRANSACTION),
  niyo_event_log_error: createFlutterHandler(HANDLER_NAMES.EVENT_LOG_ERROR),
  niyo_download: createFlutterHandler(HANDLER_NAMES.DOWNLOAD_FILE),
  niyo_initial_redirect: createFlutterHandler(HANDLER_NAMES.INITIALISE_REDIRECT),
  niyo_deviceInfo: createFlutterHandler(HANDLER_NAMES.DEVICE_INFO),
  back: createFlutterHandler("back"),
  niyo_open_native_routes: createFlutterHandler(HANDLER_NAMES.OPEN_NATIVE_ROUTES),
};

export const initializeWindowFunctions = (): void => {
  Object.assign(window, windowFunctions);
};

export const cleanupWindowFunctions = (): void => {
  Object.keys(windowFunctions).forEach((func) => {
    delete window[func as keyof Window];
  });
};