import { Chip } from '@mui/material';
import { FC, ReactNode } from 'react';
import { useTheme } from '../../../config/theme/useTheme';

interface OAChipProps {
  label: ReactNode;
  selected?: boolean;
  onClick?: () => void;
  sx?: any;
  key?: string;
}
const OAChip: FC<OAChipProps> = ({ key, label, selected, onClick, sx }) => {
  const theme = useTheme();
  return (
    <Chip
      key={key}
      label={label}
      variant="outlined"
      sx={{
        ...theme.components.pages.itinerary.smbWrapper.smbContent.tabsChip.container,
        ...(selected && theme.components.pages.itinerary.smbWrapper.smbContent.tabsChip.selectedContainer),
        ...sx
      }}
      onClick={onClick}
    />
  );
};

export default OAChip;
