import { defaultTheme } from "../../themes/default";
import { ThemeConfig } from "../../types";

export const unicardsTheme: ThemeConfig = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    pages: {
      ...defaultTheme.components.pages,
      flight: {
        ...defaultTheme.components.pages.flight,
        container: {
          ...defaultTheme.components.pages.flight.container,
          backgroundColor: '#ECF1F1'
        }
      }
    }
  }
};
