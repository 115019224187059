import { Box, Typography } from "@mui/material";
import { OAImage } from "../../../components";
import { formatToINR } from "../../../utils";
import { useTheme } from "../../../config/theme/useTheme";

interface DiscountBreakup {
  type: string;
  display: string;
  totalDiscountAmount: number;
}

const DiscountOfferBanner = ({
  discountedAmount,
  discountBreakUps,
  orderAmount,
}: {
  discountedAmount: number;
  discountBreakUps: any;
  orderAmount: any;
}) => {
  const theme = useTheme();
  const getDiscountMessage = (discountBreakUps?: DiscountBreakup[]) => {
    if (!discountBreakUps?.length) return 'Festive flash sale discount applied';

    const flatDiscount = discountBreakUps.find(
      (discount) => discount.type === 'FLAT_DISCOUNT'
    );

    return `${flatDiscount?.display} discount applied` || 'Festive flash sale discount applied';
  };
  return (
    <Box
     sx={theme.components.pages.bookingStatus.paymentAndInvoice.paymentDetails.discountOfferBanner.container}
    >
      <OAImage src="discount_bg.svg" folder="offer" alt="thumb" width='100%' />
      <Box
        sx={theme.components.pages.bookingStatus.paymentAndInvoice.paymentDetails.discountOfferBanner.subContainer}
      >
        <Box display='flex'>
          <Box>
            <OAImage src="delight.svg" width='40px' height='40px' folder="offer" alt="thumb" />
          </Box>
          <Box ml='10px'>
            <Typography variant='body2' fontWeight='600' color='#13387E'>
              You saved extra {formatToINR(discountedAmount)} on this flight booking
            </Typography>
            <Box mt='5px'>
              <Typography variant='body3' color='#13387E'>
                {getDiscountMessage(orderAmount?.discountTotalAmount?.discountBreakUps)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
};

export default DiscountOfferBanner;