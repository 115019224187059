import { FC, useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { OASwipeableDrawer } from '../basic/OASwipeableDrawer';
import { OAButton } from '../basic/OAButton';
import { OACountdown } from '../OACountdown';
import { useCancelTransactionApiMutation } from '../../services/bookingsApi';
import { eventsTracker } from '../../utils/ctEventsTracking';
import posthog from 'posthog-js';
import { OAImage } from '../OAImage';
import PaymentInfo from './PaymentInfo';
import { useTheme } from '../../config/theme/useTheme';

interface OALoadingPaymentStatusProps {
  setIsPaymentLoading: any;
  setIsRetryPayment: any;
  transactionId: string;
  timeOutInSeconds: number;
  flightData?: any;
  paymentId: string;
}
export const OALoadingPaymentStatus: FC<OALoadingPaymentStatusProps> = ({
  setIsPaymentLoading,
  setIsRetryPayment,
  transactionId,
  timeOutInSeconds,
  flightData,
  paymentId,
}) => {
  const theme = useTheme();
  const [cancelTransactionApi] = useCancelTransactionApiMutation();
  const [cancelTransaction, setCancelTransaction] = useState<boolean>(false);
  const [isTimeout, setIsTimeout] = useState<boolean>(false);
  const [startTime, setStartTime] = useState(Date.now());
  const [isCancellable, setIsCancellable] = useState<boolean>(true);

  const toggleDrawer = () => setCancelTransaction((prev) => !prev);

  useEffect(() => {
    setStartTime(Date.now());
  }, []);

  const onComplete = () => {
    setIsTimeout(true);
  };

  const cancelTransactionHandle = () => {
    const totalScreenDuration = Math.floor((Date.now() - startTime) / 1000);
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Loader',
        ctaAction: 'ConfirmCancel',
        screenDuration: totalScreenDuration?.toString(),
        UserAction: {
          userAction: 'User has cancelled the transaction',
        },
      },
      posthog
    );
    cancelTransactionApi(transactionId).then((res: any) => {
      setCancelTransaction(false);
      setIsPaymentLoading(false);
      setIsRetryPayment(true);
      if (
        window.flutter_inappwebview &&
        window.niyo_cancel_current_transaction &&
        typeof window.niyo_cancel_current_transaction === 'function'
      ) {
        window.niyo_cancel_current_transaction();
      }
    });
  };

  const handleCancelTransaction = () => {
    eventsTracker(
      {
        flowName: 'Flight',
        screenName: 'Loader',
        ctaAction: 'Cancel',
        screenDuration: '1',
        UserAction: {
          userAction: 'User has clicked to close button',
        },
      },
      posthog
    );
    setCancelTransaction(true);
  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          bgcolor: '#FFFFFF',
          zIndex: 9999,
        }}
      >
        {isCancellable && (
          <Box
            sx={{
              position: 'absolute',
              top: 40,
              left: 20,
            }}
            onClick={isTimeout ? cancelTransactionHandle : handleCancelTransaction}
          >
            <OAImage
              src={theme.assets.images.icons.close.path}
              folder={theme.assets.images.icons.close.folder}
              alt="close"
            />
          </Box>
        )}

        <PaymentInfo
          isTimeout={isTimeout}
          paymentId={paymentId}
          flightData={flightData}
          setIsCancellable={setIsCancellable}
        />

        {!isTimeout && (
          <>
            <Box
              sx={{
                p: '20px 40px',
                position: 'fixed',
                bottom: 0,
                left: 0,
                textAlign: 'center',
              }}
            >
              {isCancellable && (
                <Typography variant="body2" sx={{ fontWeight: 600, mb: '8px', color: '#C01100' }}>
                  <OACountdown duration={timeOutInSeconds} onComplete={onComplete} />
                </Typography>
              )}

              <Typography
                variant="body3"
                sx={{
                  color: 'text.secondary',
                }}
              >
                Please do not click refresh or go back until the itinerary page is displayed.
              </Typography>
            </Box>
          </>
        )}

        <OASwipeableDrawer
          sx={{ zIndex: 999999 }}
          title={
            <OAImage
              src={theme.assets.images.icons.error.path}
              folder={theme.assets.images.icons.close.folder}
              alt="error"
            />
          }
          open={cancelTransaction}
          onOpen={toggleDrawer}
          onClose={toggleDrawer}
          isContainer={false}
        >
          <Container sx={{ pb: '24px' }}>
            <>
              <Typography variant="h2" mb="10px">
                Cancel transaction?
              </Typography>
              <Typography variant="body2">We are processing your current transaction.</Typography>
              <Typography variant="body2" mt="10px">
                <strong>If any amount has been debited,</strong> please wait for bank confirmation
                before taking any action
              </Typography>
              <Grid container mt="8px" spacing={2}>
                <Grid item xs={6}>
                  <OAButton
                    fullWidth
                    disableRipple
                    variant="outlined"
                    color="error"
                    onClick={cancelTransactionHandle}
                  >
                    Cancel anyway
                  </OAButton>
                </Grid>
                <Grid item xs={6}>
                  <OAButton onClick={toggleDrawer} fullWidth variant="contained" color="secondary">
                    Continue waiting
                  </OAButton>
                </Grid>
              </Grid>
            </>
          </Container>
        </OASwipeableDrawer>
      </Box>
    </>
  );
};
