import { FC, useCallback, useMemo } from 'react';
import Deck from '../Seats/Deck';
import { Box, Typography } from '@mui/material';
import { getSortedUniquePrices } from '../../helpers/getAveragePrices';
import FlexItems from '../../../FlexItems';
import { useTheme } from '../../../../config/theme/useTheme';
import FlexItemsBetween from '../../../FlexItemsBetween';

interface SeatPanelProps {
  seatData: any;
  selectedSegment: { from: string, to: string };
  totalTravellers: number;
  updateSmbAction: any;
  selectedSmbData: any;
}

const SeatPanel: FC<SeatPanelProps> = ({
  seatData, 
  selectedSegment, 
  totalTravellers,
  updateSmbAction,
  selectedSmbData,
}) => {
  const theme = useTheme();
  const getSeatMapBasedOnSegment = useCallback((smbData: any[], selectedSegment: { from: string, to: string }) => {
    const segmentAncillariesArray = smbData
      ?.filter((item: any) => item?.segmentAncillaries)
      ?.map((item: any) => item?.segmentAncillaries)
      ?.flat();

    const seatAncillaries = segmentAncillariesArray?.find((segmentAncillary: any) =>
      segmentAncillary?.originDestinationInfo?.from === selectedSegment?.from &&
      segmentAncillary?.originDestinationInfo?.to === selectedSegment?.to
    );

    return seatAncillaries?.seatAncillaries?.aircraftLayout;
  }, []);

  const seatMapBasedOnSegment = useMemo(() => getSeatMapBasedOnSegment(seatData, selectedSegment), [selectedSegment]);
  
  const compartments = seatMapBasedOnSegment?.lowerDeck?.compartments || {};
  const rows: any = Object.values(compartments)?.flatMap((compartment: any) => compartment?.rows || []);
  const getPriceArray = getSortedUniquePrices([rows]);
  
  return (
    <Box>
      <FlexItems sx={theme.components.pages.itinerary.smbWrapper.smbContent.seatPanel.container}>
        {getPriceArray?.map((price: any) => (
          <FlexItemsBetween key={price?.price} sx={theme.components.pages.itinerary.smbWrapper.smbContent.seatPanel.subContainer}>
            <Box bgcolor={price?.primaryColor} sx={theme.components.pages.itinerary.smbWrapper.smbContent.seatPanel.containerItem} />
            <Typography ml='8px' variant='body2'>{price?.displayPrice}</Typography>
          </FlexItemsBetween>
        ))}
      </FlexItems>
      <Box px='16px'>
      <Deck 
        seatMap={seatData} 
        selectedSegment={selectedSegment} 
        totalTravellers={totalTravellers}  
        updateSmbAction={updateSmbAction}
        selectedSmbData={selectedSmbData}
        priceColorArray={getPriceArray}
      />
      </Box>
    </Box>
  );
};

export default SeatPanel;