export const getCurrentTravellerSelectionCounts = (
  selectedTravellersIds: any,
  travellerList: any
) => {
  return selectedTravellersIds?.reduce(
    (acc: any, id: string) => {
      const found = travellerList?.find((t: any) => t?.id === id);
      if (found) {
        acc[found?.type] = (acc[found?.type] || 0) + 1;
      }
      return acc;
    },
    { ADULT: 0, CHILD: 0, INFANT: 0 }
  );
};
