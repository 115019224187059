import React from "react";
import { Tabs, Tab } from "@mui/material";
import { styled } from "@mui/material/styles";

const CustomTabs = styled(Tabs)(({ theme }) =>  ({
  //custom styles
}));

const CustomTab = styled(Tab)({
  //custom styles
});

const OASwitchComponent = React.memo(({ value, onChange, labels = ["Option 1", "Option 2"], sx, ...props }: any) => {
  const handleTabChange = React.useCallback((event: React.ChangeEvent<{}>, newValue: number) => {
    onChange(newValue);
  }, [onChange]); // useCallback to memoize callback
  
  return (
    <CustomTabs
      value={value}
      onChange={handleTabChange}
      aria-label="custom switch"
      variant="fullWidth"
      sx={sx?.customTabs}
      {...props}
    >
      {labels.map((label: string, index:number) => (
        <CustomTab key={index} label={label} sx={sx?.customTab} />
      ))}
    </CustomTabs>
  );
});

export default OASwitchComponent;
