import { Box, Typography } from '@mui/material';
import { OAImage } from './OAImage';
import { useTheme } from '../config/theme/useTheme';

const OACoinBannerSecondary = ({
  isEarnCoins,
  isToggleOn,
  color,
  title,
  sx,
}: {
  isEarnCoins: boolean;
  isToggleOn: boolean;
  color: string;
  title: any;
  sx?: any;
}) => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="start"
      sx={{
        bgcolor: `${color}.light`,
        p: '5px 15px',
        borderRadius: '5px',
        ...sx,
      }}
    >
      {!isToggleOn && isEarnCoins && (
        <OAImage
          src={theme.assets.images.icons.coin.path}
          alt="coins"
          sx={{ mr: '5px', height: '18px', width: '18px' }}
        />
      )}
      <Typography variant="body3" sx={{ color: 'text.primary' }}>
        {title}
      </Typography>
    </Box>
  );
};

export default OACoinBannerSecondary;
