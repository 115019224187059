import { Typography } from '@mui/material';
import { FC } from 'react';

const MAX_CHARACTER_LIMIT = 32;

interface MaxCharacterCountProps {
  charLength: number;
}
const MaxCharacterCount: FC<MaxCharacterCountProps> = ({ charLength }) => {
  return (
    <Typography
      variant="body4"
      sx={{ position: 'absolute', top: '8px', right: '8px', color: 'text.secondary' }}
    >
      {charLength} / {MAX_CHARACTER_LIMIT}
    </Typography>
  );
};

export default MaxCharacterCount;
