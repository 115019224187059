import { defaultTheme } from "../../themes/default";
import { ThemeConfig } from "../../types";

export const zolveTheme: ThemeConfig = {
  ...defaultTheme,
  name: 'zolve',
  colors: {
    ...defaultTheme.colors,
    primary: '#FF6633',
    secondary: '#FFF5F2',
    
  }
}